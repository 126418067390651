import React from 'react'


function Button({text,id, className, onClick, type}){

    return(
        <button 
            id={id}
            className={className}
            onClick = {onClick}
            type = {type}
        >{text}
        </button>
    );
}

export default Button