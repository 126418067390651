import React from 'react'

import { BiEdit } from "react-icons/bi";
import { MdDelete } from 'react-icons/md';
//import {AiFillEye} from 'react-icons/ai';
import Checkbox from '../../../../components/Form/Checkbox/Checkbox';

import { header } from '../../../../config/header';

import Api from "../../../../Api";

const url = process.env.REACT_APP_APP_URL

let estadoTrue = "S"
let estadovaga = "N"

async function deleteAnuncio(id) {

    await Api.get(`vagasapagar/${id}`, header).then((response) => {
        alert("Anúncio eliminado com sucesso!")
        window.location.reload();
    })
        .catch((erro) => {
            console.log("Houve um erro: " + erro)
        });
}


async function validarVaga1(id) {


    await Api.post(`/dashboard/validar/${id}`, { estadoTrue }, header).then(() => {
        alert("Vaga ativada!")
        window.location.reload();
    })
        .catch((erro) => {
            alert("Houve um erro!")
            console.log("Houve um erro: " + erro)
        });

}

async function desativarVaga(id) {

    await Api.post(`dashboard/desativar/${id}`, { estadovaga }, header).then(() => {
        alert("Vaga desativada!")
        window.location.reload();
    })
        .catch((erro) => {
            alert("Erro ao desativar a avaga!")
            console.log("Houve um erro: " + erro)
        });

}



export const columns = [
    {
        name: 'Imagem',
        selector: (row) => (
            row.utilizador.foto ? (<img width={40} height={40} src={row.utilizador.foto} alt="user-img" />)
                :
                (<img width={40} height={35} src={`${url}/files/user.png`} alt="userimg-icon-alt" />)
        )

    },
    {
        name: 'Título da vaga',
        selector: (row) => [row.titulovaga],
        sortable: true

    },
    {
        name: 'Utilizador',
        selector: (row) => [row.utilizador.nomeutilizador],
        sortable: true

    },
    {
        name: 'Tipo',
        selector: (row) => [row.tipovaga.nometipovaga],
        sortable: true

    },
    {
        name: 'Estado',
        selector: (row) => [row.estadovaga],
        sortable: true

    },
    {
        name: 'Ação',
        cell: (row) => <div className="d-flex">
            {row.estadovaga === "N"
                ?

                <button
                    className="btn-table mx-1"
                    onClick={() => validarVaga1(row.id)
                    }> <Checkbox />
                </button>
                :

                <button
                    className="btn-table mx-1"
                    onClick={() => desativarVaga(row.id)
                    }
                >
                    <Checkbox estado="true" />

                </button>
            }

            <button className="btn btn-table" onClick={() => alert("Editar")}><BiEdit className='text-primary' /></button>
            <button className="btn btn-table" onClick={() => deleteAnuncio(row.id)}><MdDelete className='text-danger' /></button>
        </div>

    },

];

