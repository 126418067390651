import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


import { FaUserPlus } from "react-icons/fa";

import "./style.css";
import IMG from "../../../../assets/candidato-registo.jpg";

//Componentes de Formulário
import Input from "../../../../components/Form/Input/input.jsx";
//import Button from "../../Form/Button/Button";

import Api from "../../../../Api.js";
import { header } from "../../../../config/header";


import Nav from "../../../../components/Layout/Navbar/Nav";
import Footer from "../../../../components/Layout/Footer/Footer";

//Importando o AOS, lib para realizar O Efeito Aparecer
import Aos from 'aos'
import 'aos/dist/aos.css'

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


function Registro() {


  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  let navigate = useNavigate();

  const [registo, setRegisto] = useState({
    id_nivelacesso: 2,
    nome: "",
    nomeutilizador: "",
    email: "",
    senha: "",
    senha2: "",
    id_tipoutilizador: 2,
  }
  );

  const [btnSubmit, setBtnSubmit] = useState({
    className: '',
    text: 'Registar',
    spin: ''
  })

  const [loginError, setLoginError] = useState([]);


  const notifyError = (msg) => {
    toast.error(msg);
  }

  async function getNivelAcesso() {
    await Api.get("nivelacessos", header).then(response => {
      console.log(response.data)
      if (response.data.length === 0) {
        Api.post("nivelacessos", {
          "nomenivelacesso": "Admin"
        }, header).then(response => {
          console.log("Admin ok")
        }).catch(err => {
          console.log(err)
        })

        Api.post("nivelacessos", {
          "nomenivelacesso": "Normal"
        }, header).then(response => {
          console.log("Normal ok")
        }).catch(err => {
          console.log(err)
        })
      }
    }).catch(err => {

      console.log(err)

    })
  }

  useEffect(() => {
    getNivelAcesso()
  }, [])



  /* async function getQuantUtilizadores() {
     await Api.get("dashboard/utilizadores").then((response) => {
 
         return " "
 
       }).catch((err) => {
        if(err = "Falha ao encontrar os utilizadores"){
         setTipoUtilizador("Empresa").then(res=>{
 
           setTipoUtilizador("Individual")
         }).catch(err=>{
           console.log(err)
         })
         
        }
         console.log("Houve um erro: ", err);
       });
   }
 
   useEffect(()=>{
    getQuantUtilizadores();
   },[])
 */


  function getTipoUtilizador() {
    Api.get("tiposutilizadores", header).then(response => {

      console.log(response);

      if (response.data.length === 0) {

        Api.post("tiposutilizadores", {
          "nometipoutilizador": "Empresa"
        }, header).then(response => {
          console.log("Empresa ok")
        }).catch(err => {
          console.log("Empresa erro", err)
        })


        Api.post("tiposutilizadores", {
          "nometipoutilizador": "Individual"
        }, header).then(response => {
          console.log("Individual ok")
        }).catch(err => {
          console.log("Individual erro", err)
        })
      }

    }).catch(erro => {
      console.log("Houve um erro", erro);
    })
  }


  useEffect(() => {
    getTipoUtilizador()
  }, [])



  function handleSubmit() {

    setBtnSubmit({
      className: 'disabled',
      text: ' Aguarde',
      spin: 'spinner-border spinner-border-sm'
    })
    Api.get("utilizadores", header).then(response => {

      if (response.data.length === 0) {
        setRegisto({ id_nivelacesso: 1 })
        Api
          .post("utilizador/registro", registo, header)
          .then((response) => {
            console.log(response.data);
            setBtnSubmit({
              className: '',
              text: 'Registar',
              spin: ''
            })
            alert("Utilizador Registado com sucesso!")
            navigate("/login");
          })
          .catch((erro) => {
            alert("Houve um erro!")
            setBtnSubmit({
              className: '',
              text: 'Registar',
              spin: ''
            })
            console.log("Houve o seguinte erro: ", erro);
          });
      } else {
        Api
          .post("utilizador/registro", registo, header)
          .then((response) => {
            console.log(response.data);
            setBtnSubmit({
              className: '',
              text: 'Registar',
              spin: ''
            })
            navigate("/login");
          })
          .catch((erro) => {
            alert("Houve um erro!")
            setBtnSubmit({
              className: '',
              text: 'Registar',
              spin: ''
            })
            console.log("Houve o seguinte erro: ", erro);
          });
      }



    }
    )


  }

  const submit = (e) => {
    e.preventDefault();
    setLoginError([]);
    //console.log(registo);

    if (registo.email === "" || registo.email === undefined || registo.email === null) {
      loginError.push("Informe o email");
    }
    if (registo.nomeutilizador === "" || registo.nomeutilizador === undefined || registo.nomeutilizador === null) {
      loginError.push("Informe o nome de utilizador");
    }

    if (registo.senha === "" || registo.senha === undefined || registo.senha === null) {
      loginError.push("Informe a senha");
    }

    if (registo.senha2 === "" || registo.senha2 === undefined || registo.senha2 === null) {
      loginError.push("Repita a senha");
    } else if (registo.senha !== registo.senha2) {
      loginError.push("As senhas são diferentes");
    }


    if (loginError.length > 0) {
      loginError.map((error) => (
        notifyError(error)
      ))
      setLoginError([]);
      return
    }
    handleSubmit(registo);
  };


  function handleChange(e) {
    setRegisto({ ...registo, [e.target.name]: e.target.value });
  }

  return (
    <>
      < ToastContainer />
      <Nav navClassName={"nav-home"} />

      <div className="container container-height d-flex justify-content-center align-items-center mt-5 ">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-12">
            <div className="card col-12 o-hidden border-0 shadow-lg my-4">
              <div className="card-body p-0">
                <div className="row">
                  <div className=" col-lg-6 d-none d-lg-block" id="regist-image">
                    <img
                      className="rounded-start"
                      src={IMG}
                      alt="profisiional-imagem"
                    />
                  </div>
                  <div className="col-lg-6" id="regist">
                    <div className="ps-3 pe-4 ">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-5 mt-5 sessao-text">
                          {" "}
                          <FaUserPlus /> Registo de Candidato
                        </h1>
                      </div>
                      <form onSubmit={(value) => submit(value)} className="user">
                        <div className="form-group col-12">
                          <Input
                            type="text"
                            className="form-control form-control-user input-regist"
                            id="nomeutilizador"
                            name="nomeutilizador"
                            placeholder="Insira o Nome de Utilizador"
                            handleOnChange={handleChange}
                            value={
                              registo.nomeutilizador ? registo.nomeutilizador : ""
                            }

                          /*handleOnChange={(value)=>handlechangeValues(value)}*/
                          />
                        </div>

                        <div className="form-group col-12 mt-4">
                          <Input
                            type="email"
                            className="form-control form-control-user input-regist"
                            id="email"
                            name="email"
                            placeholder="Insira o Email"
                            value={registo.email ? registo.email : ""}
                            handleOnChange={handleChange}
                          />
                        </div>

                        <div className="col-12 mt-4">
                          <div className="row">
                            <div className="col-6 mb-4">
                              <Input
                                className="form-control form-control-user input-regist"
                                type="password"
                                id="senha"
                                name="senha"
                                placeholder="Criar senha"
                                value={registo.senha ? registo.senha : ""}
                                required
                                handleOnChange={handleChange}
                              />
                            </div>
                            <div className="col mb-4">
                              <Input
                                className="form-control form-control-user input-regist"
                                type="password"
                                id="senha2"
                                value={registo.senha2 ? registo.senha2 : ""}
                                name="senha2"
                                placeholder="Repetir Senha"
                                required
                                handleOnChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <button
                          className={`btn btn-primary form-control ${btnSubmit.className}`}
                          type="submit"
                          id="button-regist"
                        >
                          <span
                            className={`${btnSubmit.spin}`}
                            role="status"
                            aria-hidden="true" />
                          {btnSubmit.text}
                        </button>
                      </form>
                      <hr />
                      <div className="text-center mb-3">
                        <a
                          className="small"
                          href="/login"
                          id="link-bottom-regist"
                        >
                          Iniciar Sessão!
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Registro;
