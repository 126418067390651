import React, { useState, useEffect } from "react";

import Dashsidebar from "../../../Dashsidebar/Dashsidebar";
import Api from "../../../../Api";

import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "./dashutilizadores.css";

import { columns } from "./dados";
import Load from '../../../../components/Layout/Load/Load'

import DashNav from "../../../DashNav/DashNav";
import DashFooter from "../../../DashFooter/DashFooter";
//import { FaHourglassEnd } from "react-icons/fa";

import { header } from '../../../../config/header';

//Função Utilizador
function Utilizador() {
  

  const [loadEstado, setLoadEstado] = useState(true)
  const [data, setData] = useState([]);

  async function getDados() {
    await Api.get("utilizadores", header).then((response) => {

      console.log(response);
      setData(response.data);
        setLoadEstado(false)
      

    }).catch((erro)=>{
      console.log("Houve um erro!", erro)
      setLoadEstado(false)
    });
  }

  useEffect(() => {
    getDados();
  }, []);

  //Estilo
  createTheme(
    "utilizadoresTheme",
    {
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "red",
      },

    },
  );

  //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

  const tableData = {
    columns,
    data,
  };
  return (
    <>
    <div className="d-flex dash-altura" id="wrapper">
      <DashNav />
      <Dashsidebar />

      <div className="" id="page-content-wrapper">

          <h2 align="center" className="mb-2">
            Lista dos Utilizadores
          </h2>

          {loadEstado === true ? <div className="w-100 load-height d-flex justify-content-center align-items-center"><Load/></div> : (
          <DataTableExtensions  {...tableData}  className="mb-2">
            <DataTable
              columns={columns}
              data={data}
              defaultSortField="id"
              defaultSortAsc={false}
              striped
              responsive
              pagination
              entriesOptions={[5, 20, 25]}
              highlightOnHover
              fixedHeader
              fixedHeaderScrollHeight="265px"
              
            />
          </DataTableExtensions>)}
          
          <div className = 'utilizadores-footer-margin'>
              <DashFooter />
          </div>
        
      </div>
      
      
    </div>
      </>
  );
}

export default Utilizador;
