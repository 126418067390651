import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'

import StoreProvider from './config/Store/Provider';


//Importando os componentes
//import Nav from './components/Navbar/Nav'
//import Footer from './components/Footer/Footer'
import RolarTopo from './components/RolarTopo/RolarTop';



const App = () => {

  return (

    <BrowserRouter>
      <StoreProvider>
        <Routes />
        <RolarTopo />
      </StoreProvider>
    </BrowserRouter>

  );
}

export default App;
