import React from "react";
import "./notfound.css";

function NotFountPage() {
  return (
    <>
      <div className="container notfound mt-5">
        <div className=" card-notfound d-flex flex-column justify-content-center align-items-center mt-5">
          <span className="mt-2 emoj">☹</span>
          <h1>Ups!</h1>
          <h1 className="">Página não encontrada.....</h1>
        </div>
      </div>
    </>
  );
}

export default NotFountPage;
