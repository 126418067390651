import React from 'react'
import Card from '../../components/Layout/PriceCard/Card';

import './tarifarios.css'



function Tarifario(){
    return(
    
    <div className="container-tarifario mt-5 col-12 text-center d-flex flex-column justify-content-center align-items-center p-5">

        <h2 className='text-light tarifario-title'>Nossos Planos</h2>

        <div className="cards-list col-12 mt-5 d-flex justify-content-center align-items-center">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 col-lx-4 d-flex justify-content-center align-items-center "><Card tipo="Básico" valor={50}/></div>
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 col-lx-4 d-flex justify-content-center align-items-center"> <Card tipo="Médio" valor={100} /></div>
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 col-lx-4 d-flex justify-content-center align-items-center"> <Card tipo="Premium" valor={150}/></div>
            </div> 

        </div>


    </div>
    );
}


export default Tarifario