import React from 'react'
import './btntipovaga.css'

function BtnTipoVaga({id_tipovaga, nome_tipovaga, pd, height}){

    return(

        <span className={`tipo-vaga-${id_tipovaga} ${pd} text-center ${height}`}>
            {nome_tipovaga}
        </span>
    );
}

export default BtnTipoVaga