import React,{useContext} from "react";

import LOGO from '../../assets/logo.png'

import {FaUserCircle} from "react-icons/fa"
import {ImExit} from 'react-icons/im'
import {FaUserCog} from 'react-icons/fa'

import './dashnav.css'

import StoreContext from "../../config/Store/Context";

function DashNav() {

  //Dados do Utilizador Logado
  const { user } = useContext(StoreContext)

    
  return (
    <nav className="navbar shadow dash-nav col-lg-12 col-12 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a className="navbar-brand brand-logo mr-5" href="/">
          <img src={LOGO} className="mx-3 dash-nav-logo" alt="logo" />
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown" id="dropdown-nav-dash">
            <a
              className="nav-link dropdown-toggle dropdown-toggle-nav-dash text-dark" 
              href="#"
              data-toggle="dropdown"
              id="profileDropdown"
            >
             <FaUserCircle className="user-icon"/> {user.nomeutilizador ? user.nomeutilizador : ""}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown" id="dropdown-menu-nav-dash"
            >
              

              {user.id_tipoutilizador === 1 
                  ? 
                      <a 
                          className="dropdown-item text-dark dropdown-menu-nav-dash-item" 
                              href = {`/empresa/perfil/${user.id}`}>
                          <FaUserCog className="mx-2"/>
                              Perfil do Utilizador
                      </a>
                            
                             :

                             <a 
                          className="dropdown-item text-dark dropdown-menu-nav-dash-item" 
                              href = {`/perfil/passo1/${user.id}`}>
                          <FaUserCog className="mx-2"/>
                              Perfil do Utilizador
                      </a>
                                           
                        }

              <a className="dropdown-item  text-dark dropdown-menu-nav-dash-item" href="/">
                <ImExit className="mx-2"/>
                Sair do Dashboard
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
export default DashNav;
