import React from "react";
import { Link,useParams } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { useState, useEffect } from "react";

import {FaUserAlt} from 'react-icons/fa'
import {HiOutlineUpload} from "react-icons/hi"

import "./perfilcandidato.css";

import Api from "../../../../Api";
import { header } from "../../../../config/header";


import Load from "../../../../components/Layout/Load/Load";

//Componentes de Formulários
import Input from "../../../../components/Form/Input/input";
import Button from "../../../../components/Form/Button/Button";
//import { RiOperaFill } from "react-icons/ri";
//import Select from "../../../../components/Form/Select/Select";

function Form1() {

  const [activeTitle, setActiveNav] = useState("activeTitleButton");

  const id = useParams().id

  const [btn, setBtn] = useState({
    class:'',
    text: '',
    spin: ''
  })

  const [btnSubmit, setBtnSubmit] = useState({
    class:'',
    text: 'Salvar',
    spin: ''
  })



  const [file, setFile] = useState('')
  const [sexo, setSexo] = useState([])
  const [estadoCivil, setEstadoCivil] = useState([])

  const [perfil, setPerfil] = useState({
    nome: "",
    apelido: "",
    bi:"",
    nif: "",
    id_estadocivil:"",
    datanascimento:"",
    id_sexo: "",
    pais:"",
    telemovelmovel: "",
    email:"",
    distrito:"",
    localidade: "",
    cidade: "",
    favorito: "",
    imagem:"",
    id_tipoutilizador:"",
    curiculo:""
  });


  
async function getUser() {
    await Api.get(`utilizador/registro/${id}`, header).then((response) => {
        const data = response.data;
        console.log(data);
        setPerfil(data);
      }).catch((err) => {
        console.log("Houve um erro: ", err);
      });
  }

  async function getSexo(){
    await Api.get(`sexos`, header).then((response) => {
      const data = response.data;
      //console.log(data);
      setSexo(data);
      
    }).catch((err) => {
      console.log("Houve um erro: ", err);
    });
  }

  async function getEstadoCivil(){
    await Api.get(`estadoscivis`, header).then((response) => {
      const data = response.data;
      //console.log(data);
      setEstadoCivil(data);
      
    }).catch((err) => {
      console.log("Houve um erro: ", err);
    });
  }


  useEffect(()=>{
    getUser();
    getSexo();
    getEstadoCivil();
  },[id])


  function handleSubmit() {
    setBtnSubmit({
      class: 'disabled',
      text: 'Aguarde',
      spin: 'spinner-border spinner-border-sm'
    })
    Api
      .post(`utilizadores/${id}`, perfil, header)
      .then((response) => {
        alert("Alteração feita com sucesso!")
        setBtnSubmit({
          class: '',
          text: 'Salvar',
          spin: ''
        })
      })
      .catch((erro) => {
        setBtnSubmit({
          class: '',
          text: 'Salvar',
          spin: ''
        })
        console.log("Houve o seguinte erro: ", erro);
      });
  }
  const submit = (e) => {
    e.preventDefault();
    console.log(perfil);
    handleSubmit(perfil);
  };

  function handleChange(e) {
    setPerfil({ ...perfil, [e.target.name]: e.target.value });
  }



    const handleChangeI = (e) => {
      console.log(e.target.files)
      setFile(e.target.files[0])
      setBtn({
        class: '',
        text: 'Guardar',
        spin: ''
      })

    }

    const handleApi = async () => {
      setBtn({
        class : 'disabled',
        text: '.....',
        spin : 'spinner-border spinner-border-sm mx-1',
      })

      const formData = new FormData()
      formData.append('file', file);
      await Api
      .post(`utilizadores/${id}`, formData, header)
      .then((response) => {
        getUser()
        alert("Foto alterada com sucesso!")
        setFile('')
        console.log(response)

      })
      .catch((erro) => {
        setFile('')
        if(erro.response){
             alert("Houve um erro!");
        console.log("Houve o seguinte erro: ", erro.response)
        }
        
        else{
          //Backend fora do ar
              console.log("Erro, tente mais tarde!")
        };
      });

    }

    function handleChangeSelect (e){

          setPerfil({ ...perfil, [e.target.name]: e.target.value });
          //console.log(e.target.value)  
      }
    

  return (

    <>
     {!perfil.id_tipoutilizador ? (<div className=" load-container d-flex justify-content-center align-items-center mt-5"><Load/></div>):

  ( 
      <div className="container perfil-container col-xl-10 col-lg-10 col-md-10 mb-5 mt-5" id="perfil-formDados">
        <h3 className="perfil-title text-center ">Criar/Editar Perfil</h3>
        <div className="perfil-dados col-xl-10 col-lg-12 col-md-10 shadow borderRadius-boerderTop">
          <div className="cabecalhos mt-2">
            <div className="title-divisor">o---------------o-------</div>
            <Link
              to= {`/perfil/passo1/${id}`}
              title="Identificação"
              className={`title-button ${activeTitle}`}
            >
              <FaUserPlus /> Identificação
            </Link>
            <div className="title-divisor">----------o----------</div>
            <Link className="title-button" to = {`/perfil/passo2/${id}`}>
              <FaUserGraduate />
              Habilitação
            </Link>
            <div className="title-divisor">------o--------------o</div>
          </div>
          <div className="perfil-divisao"></div>

          <h5 className="text-center mt-5 mb-4">Dados Pessoais</h5>

          <div className="perfil-foto-container">
                <label className="">
                  <strong>Fotografia</strong>
                </label>

                <div className="perfil-foto-view mb-3 mt-2">

                {perfil.foto ? <img src={perfil.foto} alt="user-image" /> : <FaUserAlt className="user-img"/>}
                  
                </div>

                <div className="perfil-foto-button">
                 
                  <Button
                    type="button"
                    text="Remover"
                    title="Remova a sua foto de perfil"
                    className="fw-bold btn btn-danger text-center btn-remover-foto w-30"
                  />
                   <div className="input-registo-foto">
                    <label
                      htmlFor="foto"
                      title="Escolha uma foto para o seu perfil"
                      className=" text-center fw-bold  mx-5 fs-2"
                    >
                    <HiOutlineUpload className="text-primary"/>
                    </label>
                    <input type="file" 
                        name="files" 
                        id="foto" 
                        className="d-none" 
                        onChange={handleChangeI}
                        />
                  </div>

                  {
                    file ? <button className={`btn btn-success fw-bold ${btn.class}`} type="button" onClick={handleApi}>
                    <span className={`${btn.spin}`} role="status" aria-hidden="true"/>  {btn.text} 
                     
                </button>:

                         <Button 
                             className="btn btn-success fw-bold disabled" 
                             type="button" 
                             text="Guardar"
                         />    
                    
                  }

                  
                </div>
              </div>

          <form onSubmit={(value)=> submit(value)}>
            <div className="perfil-dados d-flex flex-column justify-content-center align-items-center col-xl-10 col-lg-12 col-md-10 mt-5">
              <div className="col-12 mt-4">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label htmlFor="nome" className="mb-2">
                      Nome<span className="obg">*</span>
                    </label>
                    <Input
                      type="text"
                      title="Informa o seu Nome real"
                      className="form-control form-control-user input-form-data"
                      name="nome"
                      placeholder="Nome"
                      value={perfil.nome ? perfil.nome : ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="apelido" className="mb-2">
                      Apelido<span className="obg">*</span>
                    </label>
                    <Input
                      type="text"
                      title="Informe o seu Apelido"
                      className="form-control form-control-user input-form-data"
                      name="apelido"
                      placeholder="Apelido"
                      value={perfil.apelido ? perfil.apelido : ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label htmlFor="bi" className="mb-2">Número de BI</label>
                    <Input
                      type="text"
                      title="Informe o teu Número de BI"
                      className="form-control form-control-user input-form-data"
                      name="bi"
                      id="bi"
                      placeholder="Seu BI"
                      value={perfil.bi ? perfil.bi : ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="nif" className="mb-2">NIF</label>
                    <Input
                      type="text"
                      title="Informe o seu Número de Identificação Fiscal"
                      className="form-control form-control-user input-form-data"
                      name="nif"
                      id="nif"
                      placeholder="Seu NIF"
                      value={perfil.nif ? perfil.nif : ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="id_estadocivil" className="mb-2">Estado Civil</label>
                    <select
                   
                      title="Informe o seu Estado Civíl"
                      className="form-select input-form-data"
                      name="id_estadocivil"
                      id="id_estadocivil"
                      value={perfil.id_estadocivil ? perfil.id_estadocivil: ""}
                      onChange = {handleChangeSelect}
                    >
                      {perfil.id_estadocivil
                          ?
                              ("")
                          :
                              <option value="" selected>
                                  (Seu Estado Civil)
                              </option>
                          }
                      
                      {estadoCivil.map((estado) =>(
                            <option value={estado.id} key={estado.id}>
                            {estado.nomeestadocivil}
                           </option>
                           ))
                        } 
                    </select>
                  </div>
                  <div className="col-6">
                    <label htmlFor="datanascimento" className="mb-2">
                      Data de Nascimento<span className="obg">*</span>
                    </label>
                    <Input
                      type="date"
                      title="Insira a sua Data de Nascimento"
                      className="form-control input-form-data"
                      name="datanascimento"
                      id="datanascimento"
                      value={perfil.datanascimento ? perfil.datanascimento : ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label htmlFor="id_sexo" className="mb-2">
                      Sexo<span className="obg">*</span>
                    </label>

                    {/*<select
             
                      title="Informe o seu Sexo"
                      className="form-select"
                      name="sexo"
                      id="sexo"
                    >
                      <option value="0" selected>
                        (Seu Sexo)
                      </option>
                      <option value="1">Maculino</option>
                      <option value="2">Feminino</option>
                    </select> */}
                    <select
                      name="id_sexo"
                      onChange = {handleChangeSelect}
                      className="form-select input-form-data"
                      value={perfil
                        ?
                        perfil.id_sexo
                        : 
                        0
                      }
                      >
                        {perfil.id_sexo 
                            ?
                            ("")
                               
                            : 
                                (<option value="" selected>Selecione o seu Sexo</option>)
                            }

                        
                        {sexo.map((sexo) =>(
                            <option value={sexo.id} key={sexo.id}>
                            {sexo.nomesexo}
                           </option>
                           ))
                        } 


                      {/*
                           (function ver(){
                            for(let item of sexo){
                              <option value={item.id} key={item.id}>
                                {item.nomesexo}
                               </option>
                            }
                           }())

                         
                          */}

                      
                      </select>
                    
                  </div>
                  <div className="col">
                    <label htmlFor="pais" className="mb-2">
                      País<span className="obg">*</span>
                    </label>
                    <Input
                      type="text"
                      title="Informe o seu País atual"
                      className="form-control form-control-user input-form-data"
                      name="pais"
                      placeholder="Seu País"
                      value={perfil.pais ? perfil.pais: ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                </div>
              </div>

              <h5 className="text-center mt-5 mb-5">Contactos e Endereço</h5>
              <div className="col-12">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label htmlFor="telemovel" className="mb-2">
                      Telemóvel<span className="obg">*</span>
                    </label>
                    <Input
                      title="Introduz o teu Número de Telemóvel junto com o IDD do seu país"
                      type="text"
                      className="form-control form-control-user input-form-data"
                      name="telemovelmovel"
                      placeholder="Telemóvel"
                      value={perfil.telemovelmovel ? perfil.telemovelmovel : ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="email" className="mb-2">
                      Email<span className="obg">*</span>
                    </label>
                    <Input
                      title="Insira o teu melhor Email"
                      type="email"
                      className="form-control form-control-user input-form-data"
                      name="email"
                      placeholder="Email"
                      id="email"
                      value={perfil.email ? perfil.email : ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label htmlFor="distrito" className="mb-2">Distrito</label>
                    <select
                      title="Informe o seu Distrito"
                      className="form-select input-form-data"
                      name="distrito"
                      id="distrito"
                      value={perfil.distrito ? perfil.distrito : ""}
                      onChange = {handleChangeSelect}
                    >
                      {perfil.distrito 
                            ?
                            ("")
                               
                            : 
                                (<option value="" selected>(Selecione o Seu Distrito)</option>)
                            }
                      <option value="Água Grande">Água Grande</option>
                      <option value="Mé-Zochi">Mé-Zochi</option>
                      <option value="Cantagalo">Cantagalo</option>
                      <option value="Lobata">Lobata</option>
                      <option value="Caué">Caué</option>
                      <option value="Lembá">Lembá</option>
                      <option value="Região Autónoma do Príncipe">Região Autónoma do Príncipe</option>
                    </select>
                  </div>
                  <div className="col">
                    <label htmlFor="localidade" className="mb-2">Localidade</label>
                    <Input
                      title="Informe a Zona ou Bairro"
                      type="text"
                      className="form-control form-control-user input-form-data"
                      name="localidade"
                      placeholder="Sua Localiade"
                      value={perfil.localidade ? perfil.localidade : ""}
                      handleOnChange = {handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
 
            <button 
                className={`btn mt-5 mb-5 perfil-button ${btnSubmit.class}`} 
                type="submit" 
                >
                <span 
                    className={`${btnSubmit.spin}`} 
                    role="status" 
                    aria-hidden="true"/> {btnSubmit.text}         
            </button>

          </form>
        </div>
      </div>

     )}
    </>
  );
}

export default Form1;
