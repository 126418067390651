import React, {useState,useEffect} from 'react'
import './dashcandidatos.css'
import Dashsidebar from '../../../Dashsidebar/Dashsidebar';
import Api from '../../../../Api'
import DashNav from '../../../DashNav/DashNav'
import DashFooter from '../../../DashFooter/DashFooter'
import Load from '../../../../components/Layout/Load/Load'

//==========Data Table==========
import DataTable, { createTheme } from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns, data} from "./dados";


//Função DashCandidato
function DashCandidato() {

    const header = process.env.REACT_APP_ACCESS_KEY

        const [loadEstado, setLoadEstado] = useState(true)
    
        const [data, setData] = useState([])
       
            async function getDados(){
       
                   await Api.get("utilizadores/individual", {
                    headers: {
                      'authorization': header,
                      'Content-Type': 'application/json',
                    }
                  }).then((response)=>{
                       console.log(response);
                       setData(response.data); 
                    
                        setLoadEstado(false)
                      
                   }).catch((erro) =>{
                    console.log("Houve um eroo", erro)
                    setLoadEstado(false)
                   })
                                       
               }
       
           useEffect(() => {
       
               getDados()
             
             }, [])
       
    //Estilos 

    createTheme('solarized', {
        text: {
            primary: 'black',
            secondary: 'white',
        },
        background: {
            default: '',
        },
        context: {
            background: 'black',
            text: '#FFFFFF',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'red',
        },


    }, 'dark');


    const tableData = {
        columns,
        data
    };

    return(

        <div className="d-flex dash-altura" id="wrapper">
            <DashNav/>

            <Dashsidebar />

            <div id="page-content-wrapper">

                <h2 align="center" className='mb-2'>Lista dos Candidatos</h2>
                {loadEstado === true ? <div className="w-100 load-height d-flex justify-content-center align-items-center"><Load/></div> : (

                    <DataTableExtensions className='mt-5' {...tableData}>
                    <DataTable
                        columns={columns}
                        data={data}
                        defaultSortField="id"
                        defaultSortAsc={false}
                        striped
                        pagination
                        highlightOnHover
                        fixedHeader
                        fixedHeaderScrollHeight="265px"
                    />
                </DataTableExtensions>
                )}

                    <div className = 'candidatos-footer-margin'>
                        <DashFooter />
                    </div>
    
            </div>

        </div>
    );
}

export default DashCandidato