import React from "react";
import './dashfooter.css'

function DashFooter() {
  return (
    <footer className=" dash-footer">
      <p className="text-center mt-3">
        © 2022 Copyright | +Oportunidades
      </p>
    </footer>
  );
}

export default DashFooter;
