import React, { useState, useEffect } from 'react'
import { useParams} from 'react-router-dom'

import './vagascandidatadas.css'

import Api from '../../../../Api';
import { header } from '../../../../config/header';

//Componentes
import Nav from '../../../../components/Layout/Navbar/Nav';
import Footer from '../../../../components/Layout/Footer/Footer';
import Load from '../../../../components/Layout/Load/Load';

import { ImLocation } from "react-icons/im";
import { FaHardHat } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { TiDelete } from 'react-icons/ti'
import { ImEye } from "react-icons/im";


function VagasCandidatas() {

  const userId = useParams().id

  const [loadState, steLoadState] = useState(true)

  const [minhasVagas, SetMinhasVagas] = useState([])

  async function vagasCandidatadas() {

    await Api
      .get(`utilizador/listagemvagas/candidatadas/${userId}`, header)
      .then((response) => {
        //alert("Sucesso!")
        SetMinhasVagas(response.data);
        console.log(response.data)
        steLoadState(false)

      })
      .catch((erro) => {
        if (erro.response) {
          alert("Houve um erro!");
          console.log("Houve o seguinte erro: ", erro.response)
          steLoadState(false)
        }

        else {
          //Backend fora do ar
          console.log("Erro, tente mais tarde!")
          steLoadState(false)
        };
      });

  }


  async function revoverVaga(idU, idV) {
    steLoadState(true)
    await Api
      .get(`utilizador/cancelarcandidatura/${idU}/${idV}`, header)
      .then((response) => {
        alert("Vaga removida com sucesso!")
        console.log(response)
        vagasCandidatadas();
        steLoadState(false)
      })
      .catch((erro) => {
        if (erro.response) {
          alert("Houve um erro!");
          console.log("Houve o seguinte erro: ", erro.response)
          steLoadState(false)
        }

        else {
          //Backend fora do ar
          console.log("Erro, tente mais tarde!")
          steLoadState(false)
        };
      });

  }

  useEffect(() => {
    vagasCandidatadas()
  }, [userId])

  return (
    <>

      <Nav navClassName={"nav-home"}/>
      {loadState === true
        ?
        (<div className=" load-container d-flex justify-content-center align-items-center mt-5"><Load /></div>)
        :
        (

          {
            ...minhasVagas.length === 0
              ?
              <div className="container vagas-candidatadas-container d-flex flex-column align-items-center col-xl-10 col-lg-10 col-md-10 mb-5">
                <h3 className='text-center mt-3 mb-5'>Vagas Candidatadas</h3>
                <p>Nenhuma anúncio encontrado</p>
              </div>
              :
              (
                <div className="container vagas-candidatadas-container d-flex flex-column align-items-center col-xl-10 col-lg-10 col-md-10 mb-5">

                  <h3 className='text-center mt-3 mb-5'>Vagas Candidatadas</h3>

                  {minhasVagas.map((dados) => (

                    <div key={dados.id_vaga} className="col-xl-10 col-lg-12 col-md-10 shadow mb-4">
                      <div className="anuncio-part1-empresa-info d-flex flex-row align-items-center justify-content-between p-4">
                        <label className="anuncio-tipo-vaga ">
                          <FaHardHat className="mb-1 text-info" /> {dados.vagas.titulovaga}
                        </label>

                        <label>
                          <ImLocation className='text-warning me-1 mb-1' />
                          <strong>
                            {dados.vagas.zona}
                          </strong>
                        </label>
                        <div className="anuncio-local">
                          <strong className=''>
                            <FaCalendarAlt className="me-1 mb-1 text-success " />
                            {dados.vagas.createdAt.substring(0, 10)}
                          </strong>
                        </div>
                        <div className='d-flex flex-row align-items-center justify-content-between'>
                          <a href={`/anuncio/detalhes/${dados.id_vaga}`}>
                            <ImEye
                              title='Visuzalizar Vaga Candidatada!'
                              className='text-primary btn_CandVaga marginB'
                            />
                          </a>

                          <TiDelete
                            title='Remover Vaga Candidatada!'
                            className='text-danger btn_CandVaga'
                            onClick={() => revoverVaga(dados.id_utilizador, dados.id_vaga)}
                          />

                        </div>
                      </div>
                    </div>

                  ))}

                </div>
              )
          }

        )
      }



      <Footer />
    </>
  );
}

export default VagasCandidatas