import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'


import './editarregisto.css'

//Importando configurações
import Api from '../../../../Api'
import authService from '../../../../config/auth'
import { header } from '../../../../config/header'

import Load from '../../../../components/Layout/Load/Load'
import Nav from '../../../../components/Layout/Navbar/Nav'
import Footer from '../../../../components/Layout/Footer/Footer'

//Componentes de Formulário
import Input from '../../../../components/Form/Input/input'


function EditarRegisto(){


    const [registo, setRegisto] = useState([]);
    const id = useParams().id

async function getUser() {
    await Api.get(`utilizador/registro/${id}`, header).then((response) => {
        const data = response.data;
        console.log(data);
        setRegisto(data);
        authService.setLoggedUser(response.data);
      }).catch((err) => {
        console.log("Houve um erro: ", err);
      });
  }

  const [btnSubmit, setBtnSubmit] = useState({
    class:'',
    text: 'Salvar',
    spin: ''
  })



    useEffect(()=>{
        getUser()
    },[id])

    async function handleSubmit(dados) {
        setBtnSubmit({
            class: 'disabled',
            text: 'Aguarde',
            spin: 'spinner-border spinner-border-sm'
          })

       await Api
          .post(`utilizadores/${id}`, dados, header)
          .then((response) => {
            window.location.reload()
            alert("Registo editado com sucesso!")
            setBtnSubmit({
                class: '',
                text: 'Salvar',
                spin: ''
              }) 

          })
          .catch((erro) => {
            setBtnSubmit({
                class: '',
                text: 'Salvar',
                spin: ''
              })
            console.log("Houve o seguinte erro: ", erro);
          });
      }
      const submit = (e) => {
        e.preventDefault();
        console.log(registo);
        handleSubmit(registo);
      };
    
      function handleChange(e) {
        setRegisto({ ...registo, [e.target.name]: e.target.value });
      }

    return(
     
<>
<Nav navClassName={"nav-home"}/>
{registo.length === 0 ? (<div className=" load-container d-flex justify-content-center align-items-center"><Load/></div>): (<div className="container registo-container col-xl-10 col-lg-10 col-md-10 mb-5" >
    
    <h3 className=" text-center registo-title">Editar Registo</h3>
    
    <div className='borderRadius-boerderTop col-xl-10 col-lg-10 col-md-12 col-12 shadow mb-5'>
    
        <form onSubmit={(value)=> submit(value)} >
            <div className="registo-dados p-5 d-flex flex-column justify-content-center align-items-center col-xl-12 col-lg-12 col-md-12 col-12 mt-2">
                <div className="col-12 ">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                            <label htmlFor="nome" className="mb-2" >Nome de Utilizador</label>
                            <Input
                                type="text"
                                title='Informa o seu Nome'
                                className="form-control form-control-user input-form-data"
                                name="nomeutilizador"
                                placeholder="Nome"
                                value={registo.nomeutilizador ? registo.nomeutilizador : ""}
                                handleOnChange = {handleChange}
                            />
                        </div>
                        <div className="col-12 cols-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label htmlFor="email"className="mb-2" >Email</label>
                            <Input
                                title='Insira o teu melhor Email'
                                type="email"
                                className="form-control form-control-user input-form-data"
                                name="email"
                                placeholder="Email" 
                                value = {registo.email ? registo.email : ""}
                                handleOnChange={handleChange}
                                />
                        </div>
                     </div>
                 </div>
                 <div className=" mb-3"></div>
                 <div className="col-12 ">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
    
                        <label htmlFor="senha" className="mb-2" >Senha</label>
                        <Input
                            title='Informe a sua nova Senha'
                            type="text"
                            className="form-control form-control-user mb-3 input-form-data"
                            handleOnChange={handleChange}
                            name="senha"
                            placeholder="Sua nova senha"/>
                          
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label htmlFor="senha2" className="mb-2" >Repetir Senha</label>
                            <Input
                                title='Repita a sua nova Senha'
                                type="text"
                                className="form-control form-control-user input-form-data"
                                handleOnChange={handleChange}
                                name="senha2"
                                placeholder="Repita a nova senha"/>
                        </div>
                     </div>
                 </div>
            </div>
            <button 
                className={`btn mb-5 registo-button  ${btnSubmit.class}`} 
                type="submit" 
                >
                <span 
                    className={`${btnSubmit.spin}`} 
                    role="status" 
                    aria-hidden="true"/> {btnSubmit.text}         
            </button>
        </form>
    </div>
       
    </div>)}
    
         <Footer/>
         </>

    );
}

export default EditarRegisto