import React, { useState } from "react"
import Context from "./Context"

import useStorage from "../../utils/useStorage"

const StoreProvider = ({children}) =>{

    const [user, setUser] = useStorage("user");
    const [loginStatus, setLoginStatus] = useStorage("loginStatus");

    return(
        <Context.Provider
        value={{
            user,
            setUser,
            loginStatus,
            setLoginStatus
        }}>
            {children}
        </Context.Provider>
    )
}

export default StoreProvider