import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import './visualizarperfil.css'

import Api from '../../../../Api'
import { header } from "../../../../config/header"


//Ícones
import { FaUserAlt } from 'react-icons/fa'
import Nav from '../../../../components/Layout/Navbar/Nav';
import Footer from '../../../../components/Layout/Footer/Footer';

import Load from '../../../../components/Layout/Load/Load';


function VisualizarPerfil() {

    const id = useParams().id

    const [user, setUser] = useState('');


    async function getUser() {

        await Api.get(`utilizador/registro/${id}`, header).then((response) => {
            console.log(response.data)
            setUser(response.data)
        }).catch((erro) => {
            console.log("Houve um erro: ", erro)
        })
    }


    useEffect(() => {
        getUser()
    }, [id])

    //<img alt='user-img' src={user.imagem}/> <p className='text-center text-dark mt-5'>{user.nomeutilizador}</p>

    return (
        <>
            <Nav navClassName={"nav-home"} />
            <>

                {user.length === 0 ? (<div className=" load-container d-flex justify-content-center align-items-center mt-5"><Load /></div>)

                    :


                    (
                        
                        <div className='container-visualizar-user-candidato col-12'>
                            
                            <div className="visualizar-user-details-imagem">
                                    {user.foto ? (
                                        <img alt='user-img' src={user.foto} />
                                    ) :
                                        <FaUserAlt className="visualizar-user-details-imgAlt" />
                                    }
                                </div>   
                    
                            <div className="visualizar-user-details d-flex flex-column align-items-center justify-content-center col-12">
                                <div className='visualizar-user-details-info mt-5 d-flex flex-column align-items-center justify-content-center'>
                                    <span className='text-center text-dark visualizar-user-details-info-name mt-5'>{user.nome} {user.apelido}</span>
                                    <span className="text-center visualizar-user-details-info-formacao mt-1"><span>{user.id_grauacademico && user.grauacademico.nomegrauacademico}</span> / Engenharia Informática{/*user.formacao*/}</span>
                                </div>

                                <div className="container visualizar-user-details-body mt-5">
                                    <div className="row">
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mb-4">
                                            <h2 className=" mb-4">Sobre o Candidato</h2>
                                            <p>
                                                {user.descricao}
                                            </p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-4">
                                            <div className="card mb-4 d-flex flex column align-items-center justify-content-center visualizar-user-details-body-buttons ">

                                                {user.curriculo
                                                    ?
                                                    <a href="livro/html_manual.pdf"
                                                        download="livro/html_manual.pdf"
                                                        type="application/pdf"
                                                        className='btn btn-success mt-2 mb-2'
                                                    >
                                                        Baixar CV
                                                    </a>
                                                    :
                                                    <button className='btn btn-success mt-2 mb-2 disabled'>Baixar CV</button>
                                                }

                                            </div>
                                            <div className="card visualizar-user-details-body-more mb-4">

                                                <h6 className='mb-3 text-start visualizar-user-details-body-title '>Mais Informações</h6>

                                                <span className="mb-2 visualizar-user-details-body-title-2">País: <span>{user.pais}</span></span>
                                                <span className="mb-2 visualizar-user-details-body-title-2">Morada: <span>{user.localidade}</span></span>
                                                <span className="mb-2 visualizar-user-details-body-title-2">Distrito: <span>{user.distrito}</span></span>
                                                <span className="mb-2 visualizar-user-details-body-title-2">Sexo: <span>{user.sexo ? user.sexo.nomesexo : ""}</span></span>
                                                <span className="mb-2 visualizar-user-details-body-title-2">Email: <span>{user.email}</span></span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                }
            </>

            <Footer />
        </>
    );
}

export default VisualizarPerfil