import React, { useState, useEffect } from "react"

import { RiSearch2Line } from "react-icons/ri"
import { FaRedo } from "react-icons/fa"
//import { Link } from "react-router-dom";


/*
    ====React paginate=====
    URL: https://www.npmjs.com/package/react-paginate
    
*/

import ReactPaginate from "react-paginate";

import "./pesquisarperfil.css"

import Nav from "../../../../components/Layout/Navbar/Nav";
import Footer from "../../../../components/Layout/Footer/Footer";

import Api from "../../../../Api";
import { header } from "../../../../config/header";

import Listagem from "../../../../components/Listagem/Geral/Listagem";
import Input from "../../../../components/Form/Input/input";
//import Radio from "../../../../components/Form/Radio/Radio"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


import Load from "../../../../components/Layout/Load/Load";
import { setLocale } from "yup";


function PesquisarPerfil() {

    const [grauAcademico, setGrauAcademico] = useState([])
    const [areaProfissional, setAreaProfissional] = useState([])
    const [candidatos, setCandidatos] = useState([])
    const [loadState, steLoadState] = useState(true)
    const [searchName, setSearchName] = useState({
        name: ""
    })
    const [searchD, setSearchD] = useState({
        distrito: ""
    })
    const [searchG, setSearchG] = useState({
        id_grauacademico: ""
    })
    const [searchA, setSearchA] = useState({
        id_areaprofissional: ""
    })
    
    function handleChangeName(e) {
        setSearchName({ ...searchName, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }

    function handleChangeD(e) {
        setSearchD({ ...searchD, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }

    function handleChangeG(e) {
        setSearchG({ ...searchG, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }
    function handleChangeA(e) {
        setSearchA({ ...searchA, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }


    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {

        const endOffset = itemOffset + itemsPerPage;
        if (candidatos.length > 0) {
            setCurrentItems(candidatos.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(candidatos.length / itemsPerPage));
        }


    }, [itemOffset, itemsPerPage, candidatos])



    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % candidatos.length;
        setItemOffset(newOffset);
    };

    //========= Alertas ===========

    const notify = (msg) => {
        toast.success(msg)
    }

    const notifyInfo = (msg) => {
        toast.info(`${msg}.`)
    }

    
    const notifyError = (msg) => {
        toast.error(`${msg}.`)
    }

    //============================

    async function getCandidatos() {
        steLoadState(true);
        Api.get("utilizadores/individual", header).then(response => {
            setCandidatos(response.data)
            console.log(response.data)
            steLoadState(false)
        }).catch(erro => {
            console.log("Houve um erro: ", erro);
            setLocale(false);
        })
    }

    useEffect(() => {
        getCandidatos()
    }, [])
//=============================

async function getAreaProfissional() {

    Api.get("areasprofissionais/getAll", header)
        .then(response => {
            //console.log(response.data)
            setAreaProfissional(response.data)
            console.log(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })
}

useEffect(() => {
    getAreaProfissional();
}, [])




 //============================
    async function getGrauAcademico() {

        await Api.get("grausacademicos", header).then(response => {
            //console.log("Grau: ",response.data)
            setGrauAcademico(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getGrauAcademico();
    }, [])

    /*========================================= */

    async function pesquisarNome() {
        steLoadState(true)
        let dist = "gvgvv"
        let idgrau = 0
        let idarea = 0

        if (searchName.name === undefined || searchName.name === null || searchName.name === "") {
            notifyInfo("Digite um nome");
            steLoadState(false);
        } else {
            await Api.get(`candidatos/searchprofissionals/${searchName.name}/${dist}/${idgrau}/${idarea}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhum Candidato com a especificação desejada!") {
                    notifyInfo("Utilizador não encontrado");
                    setSearchName({
                        name: ""
                    })
                    setSearchG({
                        id_grauacademico: ""
                    })
                    setSearchA({
                        id_areaprofissional: ""
                    })
                    setSearchD({
                        distrito: ""
                    })
                    steLoadState(false)
                } else {
                    setCandidatos(response.data)
                    notify(`Foi encontrado ${response.data.length} candidato(s)`)
                    setSearchName({
                        name: ""
                    })
                    setSearchG({
                        id_grauacademico: ""
                    })
                    setSearchA({
                        id_areaprofissional: ""
                    })
                    setSearchD({
                        distrito: ""
                    })
                    console.log("Candidatos pesquisados pelo nome: ", response.data)
                    steLoadState(false)
                }

            }).catch(error => {
                console.log("Errou ao pesquisar candidato pelo nome: ", error)
                steLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        }

    }
    /*========================================= */



    /*================== Pesqisar p+or Distrito ==================== */

    async function pesquisarPorDistrtrito() {
        steLoadState(true)
        let idgrau = 0
        let idarea = 0
        let nome = "****"

        if (searchD.distrito !== "") {

            await Api.get(`candidatos/searchprofissionals/${nome}/${searchD.distrito}/${idgrau}/${idarea}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhum Candidato com a especificação desejada!") {
                    notifyInfo("Nenhum utilizador encontrado");
                    console.log("Candidatos pesquisados por distrito: ", response.data)
                    //getCanddatos();
                    steLoadState(false)
                    setCandidatos([]);
                    setSearchG({
                        id_grauacademico: ""
                    })
                    setSearchA({
                        id_areaprofissional: ""
                    })
                } else {
                    setCandidatos(response.data)
                    notify(`Foi encontrado ${response.data.length} candidato(s)`)
                    console.log("Candidatos pesquisados por distrito: ", response.data)
                    steLoadState(false)
                    setSearchG({
                        id_grauacademico: ""
                    })
                    setSearchA({
                        id_areaprofissional: ""
                    })
                }

            }).catch(error => {
                console.log("Errou ao pesquisar candidato por distrito: ", error)
                steLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        } else {
            steLoadState(false);
        }

    }

    useEffect(() => {
        pesquisarPorDistrtrito()
    }, [searchD.distrito])

    /*========================================= */


    /*================== Pesqisar por Grau ==================== */

    async function pesquisarPorGrau() {
        steLoadState(true)
        let idarea = 0
        let nome = "*****"
        let distrito = "*****"

        if (searchG.id_grauacademico !== "") {

            await Api.get(`candidatos/searchprofissionals/${nome}/${distrito}/${searchG.id_grauacademico}/${idarea}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhum Candidato com a especificação desejada!") {
                    notifyInfo("Nenhum Utilizador encontrado");
                    setCandidatos([]);
                    steLoadState(false)
                    setSearchA({
                        id_areaprofissional: ""
                    })
                    setSearchD({
                        distrito: ""
                    })
                } else {
                    setCandidatos(response.data)
                    console.log("Candidatos pesquisados por Grau Acadêmico: ", response.data)
                    steLoadState(false)
                    notify(`Foi encontrado ${response.data.length} candidato(s)`)
                    setSearchA({
                        id_areaprofissional: ""
                    })
                    setSearchD({
                        distrito: ""
                    })
                }

            }).catch(error => {
                console.log("Errou ao pesquisar candidato por Grau Acadêmico: ", error)
                steLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        } else {
            steLoadState(false);
        }

    }

    useEffect(() => {
        pesquisarPorGrau()
    }, [searchG.id_grauacademico])


    /*================== Pesqisar por Grau ==================== */

    async function pesquisarPorArea() {
        steLoadState(true)
        let idgrau = 0
        let nome = "*****"
        let distrito = "*****"

        if (searchA.id_areaprofissional !== "") {

            await Api.get(`candidatos/searchprofissionals/${nome}/${distrito}/${idgrau}/${searchA.id_areaprofissional}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhum Candidato com a especificação desejada!") {
                    notifyInfo("Nenhum Utilizador encontrado");
                    setCandidatos([])
                    steLoadState(false)
                    setSearchG({
                        id_grauacademico: ""
                    })
                    setSearchD({
                        distrito: ""
                    })
                } else {
                    setCandidatos(response.data)
                    console.log("Candidatos pesquisados por Grau Acadêmico: ", response.data)
                    steLoadState(false)
                    notify(`Foi encontrado ${response.data.length} candidato(s)`)
                    setSearchG({
                        id_grauacademico: ""
                    })
                    setSearchD({
                        distrito: ""
                    })
                }

            }).catch(error => {
                console.log("Errou ao pesquisar candidato por Grau Acadêmico: ", error)
                steLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        } else {
            steLoadState(false);
        }

    }

    useEffect(() => {
        pesquisarPorArea()
    }, [searchA.id_areaprofissional])


    return (
        <>
            <ToastContainer />
            <Nav navClassName="nav-home" />
            <div className="perquisar-perfil-container col-12">

                <h3 className="text-center text-light mb-5">Pesquisar Candidatos</h3>

                <div className="perquisar-perfil-body col-12 d-flex justify-content-center">
                    <div className="col-10">
                        <div className="row">
                            <div className="col-xl-4 col-12 d-flex align-items-start">
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="row ">
                                            <span className="text-start mb-4 redifinir-pesquisa" title="Redefinir pesquisa"><FaRedo onClick={getCandidatos} className="fs-6 pesquisar-perfil-icon" /> </span>
                                            <div className="col-10">
                                                <label className="mb-2">Pesquisar por Nome</label>
                                                <Input
                                                    type="text"
                                                    id="pesquisarPorNome"
                                                    name="name"
                                                    className="form-control form-control-user pesquisar-perfil-porNome"
                                                    placeholder="Nome"
                                                    value={searchName.name ? searchName.name : ""}
                                                    handleOnChange={handleChangeName}
                                                />
                                            </div>
                                            <div className="col-2 d-flex align-items-center mt-4">
                                                <RiSearch2Line className="pesquisar-perfil-icon" title="Procurar" onClick={pesquisarNome} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <label className="mb-2">Pesquisar por Distrito</label>
                                        <select
                                            title="Pesquisar por Distrito"
                                            className="form-select pesquisar-perfil-porDistrito"
                                            name="distrito"
                                            id="pesquisar-perfil-distrito"
                                            value={searchD.distrito ? searchD.distrito : ""}
                                            onChange={handleChangeD}
                                        >
                                            (<option value="" selected>(Selecionar Distrito)</option>)

                                            <option value="Água Grande">Água Grande</option>
                                            <option value="Mé-Zochi">Mé-Zochi</option>
                                            <option value="Cantagalo">Cantagalo</option>
                                            <option value="Lobata">Lobata</option>
                                            <option value="Caué">Caué</option>
                                            <option value="Lembá">Lembá</option>
                                            <option value="Região Autónoma do Príncipe">Região Autónoma do Príncipe</option>
                                        </select>
                                    </div>

                                    <div className="col-12 mt-4">
                                        <label className="mb-2">Pesquisar por Área Profissional</label>
                                        <select
                                            title="Pesquise pela categoria profissional"
                                            className="form-select pesquisar-perfil-categoria-profissional"
                                            name="id_areaprofissional"
                                            id="pesquisar_perfil_area_profissional"
                                            value={searchA.id_areaprofissional ? searchA.id_areaprofissional : ""}
                                            onChange={handleChangeA}
                                        >

                                            <option value="" selected>(Selecionar Área)</option>

                                            {
                                                areaProfissional.map((area) => (
                                                    <option value={area.id} key={area.id} >
                                                        {area.nomeareaprofissional}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    <div className="col-12 mt-4">
                                        <label className="mb-2">Pesquisar por Grau Acadêmico</label>
                                        <select
                                            title="Pesquise pelo grau acadêmico"
                                            className="form-select pesquisar-perfil-grau-academico"
                                            name="id_grauacademico"
                                            id="pesquisar_perfil_grau_academico"
                                            value={searchG.id_grauacademico ? searchG.id_grauacademico : ""}
                                            onChange={handleChangeG}
                                        >

                                            <option value="" selected>(Selecionar o Grau)</option>
                                            {
                                                grauAcademico.map((grau) => (
                                                    <option value={grau.id} key={grau.id} >
                                                        {grau.nomegrauacademico}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {loadState === true
                                ?
                                (<div className="col-xl-8 col-12 d-flex justify-content-center align-items-center container-load"><Load /></div>)
                                :
                                (
                                    {
                                        ...candidatos === []
                                            ?
                                            (<p>Nenhum resultado</p>)
                                            :
                                            (

                                                <div className="col-xl-8 col-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-6 mt-lx-0 mt-lg-5 mt-4">
                                                                    <span>Total de {candidatos.length} Candidatos</span>
                                                                </div>

                                                                <div className="col-6 text-end">
                                                                    <div className="row">

                                                                        {/* 

                                                            <div className="col-12">
                                                                <span className="text-end">Ondenar por:</span>
                                                            </div>
                                                            <div className="col-3">
                                                                <select
                                                                    title="Informe a quanditade de itens a ser listado"
                                                                    className="form-select pesquisar-perfil-quantview"
                                                                    name="itemsPerPage"
                                                                    id="itemsPerPage"
                                                                    value={itemsPerPage}
                                                                    onChange={handleChangeSelect2}
                                                                >
                                                                    <option selected value={5}>5</option>
                                                                    <option value={7}>7</option>
                                                                    <option value={10}>10</option>
                                                                    <option value={12}>12</option>
                                                                    <option value={15}>15</option>
                                                                </select>
                                                            </div>
                                                            */}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <Listagem
                                                                data={currentItems}
                                                            />
                                                        </div>
                                                    </div>
                                                    <ReactPaginate
                                                        previousLabel={"Anterior"}
                                                        nextLabel={"Próximo"}
                                                        breakLabel={"..."}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination justify-content-center mt-4 mb-5"}
                                                        pageClassName={"page-item"}
                                                        pageLinkClassName={"page-link"}
                                                        previousClassName={"page-item"}
                                                        previousLinkClassName={"page-link"}
                                                        nextClassName={"page-item"}
                                                        nextLinkClassName={"page-link"}
                                                        breakClassName={"page-item"}
                                                        breakLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                </div>
                                            )

                                    }

                                )

                            }


                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default PesquisarPerfil