import React,{ useState, useEffect } from "react";

import { GiFactory } from "react-icons/gi";
import { MdWork } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import {RiUser2Fill} from 'react-icons/ri'
import {FaUserAlt} from 'react-icons/fa'

import "./dashprincipal.css";

import Api from "../../Api";
import { header } from "../../config/header";

//import "../../assets/css/bootstrap.min.css";
//import '../../assets/Js/bootstrap.bundle.min.js'

//import DashFooter from "../DashFooter/DashFooter";
import Load from "../../components/Layout/Load/Load";
import Card from "./Cards/Card";

function DashPrincipal() {

  const [loadEstado, setLoadEstado] = useState(true)


  
  //Utilizadores:
   const [utilizadoresQ, setUtilizadoresQ] = useState([]);

   async function getQuantUtilizadores() {

     await Api.get("dashboard/utilizadores", header).then((response) => {
         console.log("utili: ", response.data);
         setUtilizadoresQ(response.data);

         setLoadEstado(false)
 
       }).catch((err) => {
        if(err = "Falha ao encontrar os utilizadores"){
          setLoadEstado(false)
          setUtilizadoresQ(0);
        }
         console.log("Houve um erro: ", err);
         setLoadEstado(false)
       });
   }

   useEffect(()=>{
    getQuantUtilizadores();
   },[])


  //Empresa:
  const [empresaQ, setEmpresaQ] = useState([]);

  async function getQuantEmpresa() {
    await Api.get("dashboard/empresas", header).then((response) => {
        //console.log(Qempresas);
        setEmpresaQ(response.data);
        setLoadEstado(false)

      }).catch((err) => {
        if(err = "Falha ao encontrar as Empresas"){
          setEmpresaQ(0)
          setLoadEstado(false)
        }
        console.log("Houve um erro: ", err);
        setLoadEstado(false)
      });
  }

  useEffect(() => {
    getQuantEmpresa();
  }, []);


  //Individual:
  const [individuaisQ, setindividuaisQ] = useState([]);

  async function getQuantIndivi() {
    await Api.get("dashboard/individuais", header).then((response) => {
        //console.log(response.data);
        setindividuaisQ(response.data);
        setLoadEstado(false)
      }).catch((err) => {
        if(err = "Falha ao encontrar os utilizadores Individuais"){
          setindividuaisQ(0)
          setLoadEstado(false)
        }
        console.log("Houve um erro: ", err);
        setLoadEstado(false)
      });
  }

  useEffect(() => {
    getQuantIndivi();
  }, []);

  //Vagas:

  const [vagasQ, setvagasQ] = useState([]);

  async function getQuantInformacao() {
    await Api.get("dashboard/vagas", header).then((response) => {
        //console.log(response.data);
        setvagasQ(response.data);
        setLoadEstado(false)
      })
      .catch((err) => {
        if(err = "Falha ao encontrar as vagas"){
          setvagasQ(0)
          setLoadEstado(false)
        }
        console.log("Houve um erro: ", err);
        setLoadEstado(false)
      });
  }

  useEffect(() => {
    getQuantInformacao();
  }, []);

  //Vagas ativas

  const [vagasQA, setvagasQA] = useState([]);

  async function getQuantVA() {
    await Api.get("/vagas/ativas", header).then((response) => {
        //console.log(response.data);
        setvagasQA(response.data.length);
        setLoadEstado(false)
      })
      .catch((err) => {
        if(err = "Falha ao encontrar as vagas"){
          setvagasQA(0)
          setLoadEstado(false)
        }
        console.log("Houve um erro: ", err);
        setLoadEstado(false)
      });
  }

  useEffect(() => {
    getQuantVA();
  }, []);



  return (
    <>
      <div id="page-content-wrapper-dashprincipal">
        <div className="container-fluid px-4 col-lg-10 col-md-10 col-sm-10 col-12 mt-5">
          <div className="row my-2 wrap">
          <Card
              texto="Total de Utilizadores"
              dado={loadEstado === true ? <Load controlador={1}/>:empresaQ + individuaisQ }
              icon={<FaUserAlt className="icone-utilizadores-color" />}
              classe1 = "fs-8 mt-2 card-color-text"
              id1 = "border-utilizadores"
            />
            <Card
              texto="Total de Empresas"
              dado={loadEstado === true ? <Load controlador={1}/>:  empresaQ}
              icon={<FaBuilding className="icone-company-color" />}
              classe1 = "fs-8 mt-2 card-color-text"
              id1 = "border-company"
            />
            <Card
              texto="Total de Individuais"
              dado={loadEstado === true ? <Load controlador={1}/>: individuaisQ}

              icon={<RiUser2Fill className=" icone-individuais-color" />}
              classe1 = "fs-8 mt-2 card-color-text"
              id1 = "border-individuais"
            />
            <Card
              texto="Total de Vagas"
              dado={loadEstado === true ? <Load controlador={1}/>: vagasQ}

              icon={<MdWork className=" icone-vagas-color" />}
              classe1 = "fs-8 mt-2 card-color-text"
              id1 = "border-vagas"
            />
            <Card
              texto="Vagas Ativas"
              dado={loadEstado === true ? <Load controlador={1}/>: vagasQA}
              icon={<MdWork className="icone-company-color" />}
              classe1 = "fs-8 mt-2 card-color-text"
              id1 = "border-vagas"
            />
            <Card
              texto="Vagas desativadas"
              dado={loadEstado === true ? <Load controlador={1}/>: (vagasQ- vagasQA)}
              icon={<MdWork className="icone-vagas-desativadas-color" />}
              classe1 = "fs-8 mt-2 card-color-text"
              id1 = "border-vagas"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashPrincipal;
