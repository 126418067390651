import React from "react";

function Card({ texto, icon, dado,classe1, id1 }) {
  return (
    <div className="col-md-12 col-lg-4 col-sm-6 box-sizing mb-4 mt-5">
      <div className="bg-white shadow d-flex justify-content-around align-items-center rounded box-sizing" id={id1}>
        <div className="mx-1 mt-2 mb-4">
          <h6 className="fs-2 mt-2 mb-3 color-dark">{dado}</h6>

          <span className = {classe1} >{texto}</span>
        </div>
        <div className=" fs-1 primary-text mt-3 mb-4">{icon}</div>
      </div>
    </div>
  );
}
export default Card;
