import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import './style.css'
import IMG from '../../../../assets/empresa-registo.jpg'

import { FaUserPlus } from 'react-icons/fa';

import api from '../../../../Api.js';
import { header } from '../../../../config/header';

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


import Nav from '../../../../components/Layout/Navbar/Nav';
import Footer from '../../../../components/Layout/Footer/Footer';


function Registro() {


    let navigate = useNavigate()

    const [values, setValues] = useState({
        nomeutilizador: "",
        email: "",
        senha: "",
        senha2: "",
        id_tipoutilizador: ""
    });

    const [btnSubmit, setBtnSubmit] = useState({
        className: '',
        text: 'Registar',
        spin: ''
    })
    const [loginError, setLoginError] = useState([]);

    const notifyError = (msg) => {
        toast.error(msg);
    }

    function submit(e) {
        e.preventDefault();
        setLoginError([]);


        if (values.email === "" || values.email === undefined || values.email === null) {
            loginError.push("Informe o email");
        }
        if (values.nomeutilizador === "" || values.nomeutilizador === undefined || values.nomeutilizador === null) {
            loginError.push("Informe o nome de utilizador");
        }

        if (values.senha === "" || values.senha === undefined || values.senha === null) {
            loginError.push("Informe a senha");
        }

        if (values.senha2 === "" || values.senha2 === undefined || values.senha2 === null) {
            
            loginError.push("Repita a senha");

        } else if (values.senha !== values.senha2) {
            loginError.push("As senhas são diferentes");
        }

        if (loginError.length > 0) {
            loginError.map((error) => (
                notifyError(error)
            ))
            setLoginError([]);
            return
        }

        setBtnSubmit({
            className: 'disabled',
            text: ' Aguarde',
            spin: 'spinner-border spinner-border-sm'
        })
        api.post("utilizador/registro", {
            nomeutilizador: values.nomeutilizador,
            email: values.email,
            senha: values.senha,
            senha2: values.senha2,
            id_tipoutilizador: 1
        }, header).then((response) => {
            //console.log(response.data);
            setBtnSubmit({
                className: '',
                text: 'Registar',
                spin: ''
            })
            alert("Empresa cadastrada com sucesso!")
            navigate("/login")
        }).catch((erro) => {
            setBtnSubmit({
                className: '',
                text: 'Registar',
                spin: ''
            })
            console.log("Houve o seguinte erro: ", erro)
        });
    }

    function handlechangeValues(value) {
        const newvalues = { ...values }
        newvalues[value.target.name] = value.target.value
        setValues(newvalues)
        console.log(newvalues)
    };

    return (

        <>
            < ToastContainer />
            <Nav navClassName={"nav-home"} />

            <div class="container container-height d-flex justify-content-center align-items-center mt-5">

                <div class="row justify-content-center">

                    <div class="col-xl-10 col-lg-12 col-md-10">

                        <div class="card col-12 o-hidden border-0 shadow-lg my-5">
                            <div class="card-body p-0">
                                <div class="row">
                                    <div class=" col-lg-6 d-none d-lg-block" id="regist-image-empresa"><img class="rounded-start" src={IMG} alt="empresa-imagem" /></div>
                                    <div class="col-lg-6" id='regist-empresa'>
                                        <div class="ps-3 pe-4">
                                            <div class="text-center">
                                                <h1 class="h4 text-gray-900 mb-5 mt-5 sessao-text"> <FaUserPlus /> Registo de Empresa</h1>
                                            </div>
                                            <form onSubmit={(value) => submit(value)} class="user">
                                                <div class="form-group col-12">

                                                    <input type="text" class="form-control form-control-user input-regist-empresa"
                                                        id="nomeutilizador" name='nomeutilizador' aria-describedby="emailHelp"
                                                        placeholder="Insira o Nome de Utilizador" onChange={(value) => handlechangeValues(value)} />
                                                </div>

                                                <div class="form-group mt-4 col-12">
                                                    <input type="email" class="form-control form-control-user input-regist-empresa"
                                                        id="email" name='email' aria-describedby="emailHelp"
                                                        placeholder="Insira o Email..." onChange={(value) => handlechangeValues(value)} />
                                                </div>

                                                <div class="col-12 mt-4">
                                                    <div className="row">
                                                        <div class="col-6 mb-4">
                                                            <input class="form-control form-control-user input-regist-empresa" type="password" id="senha" name="senha" placeholder="Criar senha" onChange={(value) => handlechangeValues(value)} />
                                                        </div>
                                                        <div class="col mb-4">
                                                            <input class="form-control form-control-user input-regist-empresa" type="password" id="senha2" name="senha2" placeholder="Repetir Senha" onChange={(value) => handlechangeValues(value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button
                                                    className={`btn btn-primary form-control ${btnSubmit.className}`}
                                                    type="submit"
                                                    id="button-regist"
                                                >
                                                    <span
                                                        className={`${btnSubmit.spin}`}
                                                        role="status"
                                                        aria-hidden="true" />
                                                    {btnSubmit.text}
                                                </button>

                                                {/*<button class="btn btn-primary form-control btn-user " id='button-regist-empresa'>
                                                    Registar
    </button>*/}
                                            </form>
                                            <hr />
                                            <div class="text-center mb-3">
                                                <a class="small" href='/login' id='link-bottom-regist-empresa'>Iniciar Sessão!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <Footer />

        </>

    );
}
export default Registro;