import React from 'react'
import './card.css'


function Card({tipo,tempo,valor,dado1,dado2,dado3}){
    return(
        <div className="card-container">

        <div className="card-box">
            <h3 className="card-title text-light">{tipo}</h3>
            {tipo === "Básico" && (<h3 className="card-price text-light">{valor}<span>STN</span><span>/ 1 mês</span></h3>)}
            {tipo === "Médio" && (<h3 className="card-price text-light">{valor}<span>STN</span><span>/ 3 meses</span></h3>)}
            {tipo === "Premium" && (<h3 className="card-price text-light">{valor}<span>STN</span><span>/ 6 meses</span></h3>)}
            <ul>
                <li>5 GB hard disk</li>
                <li>15 email service</li>
                <li>maintainance</li>
                <li>5 sub domains</li>
            </ul>
            <a href="#">Adiquirir</a>
        </div>
    </div>
    );
}

export default Card