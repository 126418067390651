import React from "react";
import "./perfilcandidato.css";

import Form1 from "./formDados1";

import Nav from "../../../../components/Layout/Navbar/Nav";
import Footer from "../../../../components/Layout/Footer/Footer";

function Perfil() {
  return (
    <>
      <Nav navClassName={"nav-home"} />
      <Form1/>
      <Footer />
    </>
  );
}

export default Perfil;
