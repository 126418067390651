import React from "react";
import "./dashsidebar.css";
import { Link } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { MdFeedback } from "react-icons/md";
import { MdCategory } from "react-icons/md";
import {FaUserAlt} from 'react-icons/fa'
import {RiUser2Fill} from 'react-icons/ri'

function Dashsidebar() {
  
  return (

    <div className="dash-sidebar" id="sidebar-wrapper">

      <div className="list-group mt-5">
        <Link
          to="/admin/dashboard"
          className="list-group-item active dash-icon-dashboard"
        >
          
          <AiFillDashboard className="dash-icon  me-2 mb-1" /> Dashboard
        </Link>

        <Link
          to="/admin/dashboard/utilizadores"
          className="list-group-item menu-color active second-text  sidebar-item mt-3"
        >
          <FaUserAlt className="me-3 dash-item-icon "/> Utilizadores
         
         
        </Link>

        <Link
          to="/admin/dashboard/empresas"
          className="list-group-item menu-color active  second-text  sidebar-item"
        >
          <FaBuilding className="me-3 dash-item-icon" /> Empresas
        </Link>

        <Link
          to="/admin/dashboard/candidato"
          className="list-group-item menu-color active  second-text  sidebar-item"
        >
          <RiUser2Fill className="me-3 dash-item-icon"/> Candidatos

        </Link>

        <Link
          to="/admin/dashboard/vagas"
          className="list-group-item active  menu-color second-text  sidebar-item"
        >
          <MdWork className="me-3 dash-item-icon"/> Vagas
        </Link>

        <Link
          to="/admin/dashboard/items/adiconar"
          className="list-group-item active  menu-color second-text sidebar-item"
        >
          <MdCategory className="me-3 dash-item-icon"/> Itens
        </Link>

        <Link
          to=""
          className="list-group-item active  menu-color second-text sidebar-item mb-2"
        >
          <MdFeedback className="me-3 dash-item-icon"/> Feedbeacks
        </Link>
      </div>
    </div>
  );
}

export default Dashsidebar;
