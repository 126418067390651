import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

import './vagascriadas.css'

import Api from '../../../../Api';
import { header } from '../../../../config/header';

//Componentes
import Nav from '../../../../components/Layout/Navbar/Nav';
import Footer from '../../../../components/Layout/Footer/Footer';

import Load from '../../../../components/Layout/Load/Load';


//Ícones
import { ImLocation } from "react-icons/im";
import { FaHardHat } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { TiDelete } from 'react-icons/ti'
import { ImEye } from "react-icons/im";
import { FaUser } from 'react-icons/fa';


function VagasCriadas() {

  const userId = useParams().id

  const [loadState, steLoadState] = useState(true)

  const [minhasVagas, setMinhasVagas] = useState([])

  async function vagasCriadas() {

    await Api
      .get(`vagas/dono/${userId}`, header)
      .then((response) => {
        console.log(response.data)
        setMinhasVagas(response.data)
        steLoadState(false)

      })
      .catch((erro) => {
        alert("Houve um erro!");
        console.log("Houve o seguinte erro: ", erro)
        steLoadState(false)

      });

  }


  async function revoverVaga(idV) {
    steLoadState(true)
    await Api
      .get(`vagasapagar/${idV}`, header)
      .then((response) => {
        alert("Vaga removida com sucesso!")
        console.log(response)
        vagasCriadas();
        steLoadState(false)
      })
      .catch((erro) => {
        if (erro.response) {
          alert("Houve um erro!");
          console.log("Houve o seguinte erro: ", erro.response)
          steLoadState(false)
        }

        else {
          //Backend fora do ar
          console.log("Erro, tente mais tarde!")
          steLoadState(false)
        };
      });

  }

  useEffect(() => {
    vagasCriadas()
  }, [userId])

  return (
    <>

      <Nav navClassName={"nav-home"}/>

      {loadState === true
        ?
        (<div className=" load-container d-flex justify-content-center align-items-center mt-5"><Load /></div>)

        :
        (

          {
            ...minhasVagas.length === 0
              ?

              <div className="container vagas-criadas-container d-flex flex-column align-items-center col-xl-10 col-lg-10 col-md-10 mb-5">

                <h3 className='text-center mt-3 mb-5'>Meus Anúncios Criados</h3>

                <p>Nenhuma anúncio encontrado</p>

              </div>

              :

              (
                <div className=" vagas-criadas-container d-flex flex-column align-items-center col-12 mb-5 p-3">

                  <h3 className='text-center mt-3 mb-5'>Meus Anúncios Criados</h3>

                  {minhasVagas.map((dados) => (

                    <div key={dados.id} className="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 shadow mb-4 p-4">
                      <div className="row anuncio-part1-empresa-info d-flex align-items-center justify-content-between">
                        <label className="mb-2 mb-lg-0 mb-xl-0 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 text-dark fs-5">
                          <FaHardHat className="mb-1 text-info" /> {dados.titulovaga}
                        </label>

                        <p className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-md-end
                                          mb-2 mb-lg-0 mb-xl-0 fs-6'>
                          <ImLocation className='text-warning me-1 mb-1' />
                          <strong className='anuncio-part1-empresa-info-zona'>
                            {dados.zona ? dados.zona : " "}
                          </strong>
                        </p>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2 mb-lg-0 mb-xl-0 ">
                          <strong className='anuncio-part1-empresa-info-data'>
                            <FaCalendarAlt className="me-1 text-success mb-1" />
                            {dados.createdAt.substring(0, 10)}

                          </strong>
                        </div>
                        <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-start justify-content-md-end
                                        justify-content-lg-center justify-content-xl-center'>

                          
                            <div className=' d-flex align-items-center '>
                              <Link
                                to={`/utilizador/vaga/candidatos/${dados.id}`}
                              >
                                <FaUser
                                  className='mb-1 text-success'
                                  title='Listar Candidatos'
                                />
                              </Link>
                            </div>

                            <div className=' d-flex align-items-center '>
                              <Link to={`/anuncio/detalhes/${dados.id}`}>
                                <ImEye
                                  title='Visuzalizar Vaga Candidatada!'
                                  className='text-primary btn_CriarVaga '
                                />
                              </Link>
                            </div>

                            <div className=''>
                              <TiDelete
                                title='Remover Vaga Candidatada!'
                                className='text-danger btn_CriarVaga'
                                onClick={() => revoverVaga(dados.id)}
                              />

                          </div>

                        </div>
                      </div>
                    </div>

                  ))}

                </div>
              )
          }
        )}

      <Footer />
    </>
  );
}

export default VagasCriadas