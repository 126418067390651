import React, { useState, useEffect } from "react"
import CardPopularCategory from "./Card/CardPopularCategory"

import { AiOutlineArrowRight } from "react-icons/ai"

import Api from "../../Api"

import "./category.css"

export default function CategoriasPopulares() {

    const header = process.env.REACT_APP_ACCESS_KEY

    const [categorias, setCategorias] = useState([])

    async function getCategoriaVaga() {

        Api.get("categoriasvagas", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setCategorias(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getCategoriaVaga();
    }, [])
    return (
        <>
            <div className="container col-12 category-container d-flex flex-column align-items-center justify-content-center">
                <h1 className="popularCategory-title text-center ">Categorias Populares</h1>
                <p className="mt-2 fs-6">
                    As categorias com mais anúncios criados
                </p>

                <div className=" category_container_body col-10 mt-4  d-flex align-items-center justify-content-center">
                    <div className="row col-10 d-flex align-items-center justify-content-center">
                        {
                            categorias.map((categoria) => (
                                <div key={categoria.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                                    <CardPopularCategory
                                        className=" p-2 text-center d-flex flex-column align-items-center justify-content-center
                                                   text-light"
                                        name={categoria.nomecategoriavaga}
                                        number={5}
                                        icon="ICON"
                                        category={categoria.nomecategoriavaga.substring(0, 1).toLowerCase()}
                                    />
                                </div>
                            ))
                        }

                    </div>

                </div>
                <div>
                    <a href="/anuncio/pesquisar" className="pesquisarPorCategoria">
                        Pesquisar anúncios por categorias <AiOutlineArrowRight />
                    </a>
                </div>

            </div>
        </>
    )
}