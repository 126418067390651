import React,{ useState, useEffect } from "react";
import {useParams } from "react-router-dom";
import "./vagaanuncioeditar.css";

import authService from "../../../../config/auth";
import Api from "../../../../Api";
import { useNavigate } from "react-router-dom";
import  {header} from "../../../../config/header"

import Nav from "../../../../components/Layout/Navbar/Nav";
import Footer from "../../../../components/Layout/Footer/Footer";

import Input from "../../../../components/Form/Input/input";

function VagaAnuncioEditar() {

  let navigate = useNavigate();
  const id = useParams().id

  //Dados do Utilizador Logado

  const [userDados, setUserDados] = useState('');

  const [btnSubmit, setBtnSubmit] = useState({
    class:'',
    text: 'Salvar',
    spin: ''
  })

  const [anuncioDados, setanuncioDados] = useState({
    titulovaga: "",
    id_tipovaga: "",
    distrito: "",
    salario: "",
    zona: "",
    distrito: "",
    datafinalizacao: "",
    quantidadevaga: "",
    descricao: "",
    id_categoriavaga: "",
    id_tipoutilizador: "",
  });

  function getUser() {
    if (authService.getLoggedUser()) {
      let userData = authService.getLoggedUser();
      setUserDados(userData);
    }
  }


  async function getVaga() {
    await Api.get(`vagas/${id}`, header).then((response) => {
        const data = response.data;
        console.log(data);
        setanuncioDados(data);
  
         /*if(vaga.id_utilizador === userDados.id){
  
         
          setBtnSubmit({
            class: 'disabled',
            text: 'Seu Anúncio',
            spin: ''
          })
  
          setBtnSubmit2({
            class: 'disabled',
            text: 'Seu Anúncio',
            spin: ''
          })
        }*/
      }).catch((err) => {
        console.log("Houve um erro: ", err);
      });
  }


  async function submitVaga(e) {
    e.preventDefault();
    setBtnSubmit({
      class: 'disabled',
      text: 'Aguarde',
      spin: 'spinner-border spinner-border-sm'
    })

    await Api
      .post(
        `utilizadores/${userDados.id}/categoriasvagas/${anuncioDados.id_categoriavaga}/tiposvagas/${anuncioDados.id_tipovaga}/vagas`,anuncioDados, header
        
      )
      .then((response) => {
        setBtnSubmit({
          class: '',
          text: 'Salvar',
          spin: ''
        })
        console.log(response.data);
        alert("Anúncio Criado com sucesso!")
        navigate("/");
      })
      .catch((erro) => {
        setBtnSubmit({
          class: '',
          text: 'Salvar',
          spin: ''
        })
        alert("Houve o seguinte erro!")
        console.log("Houve o seguinte erro: ", erro);
      });
  }

  function handlechangeValues(value) {
    const newvalues = { ...anuncioDados };
    newvalues[value.target.name] = value.target.value;
    setanuncioDados(newvalues);
  }

  useEffect(() => {
    getUser();
    getVaga();
  }, []);

  return (
    <>
      <Nav navClassName={"nav-home"}/>
      <div className="container anuncioContainer-editar col-xl-10 col-lg-10 col-md-10 mb-5">
        <h3 className="anuncioTitle-editar text-center">Editar Anúncio</h3>

        <div className="anuncioDadosContainer-editar borderRadius-boerderTop col-xl-10 col-lg-12 col-md-10 shadow">
          <form onSubmit={(value) => submitVaga(value)}>
            <div className="anuncioFotoContainer-editar">
              <label className="mt-5">
                <strong className="nota-color">Nota: </strong> A foto da empresa
                aparecerá como foto de apresentação do anúncio.
              </label>
            </div>

            <div className="anuncioDadosBody-editar d-flex flex-column justify-content-center align-items-center col-xl-10 col-lg-12 col-md-10 mt-5">
              <div className="col-12 mt-4">
                <div className="col mb-4">
                  <label htmlFor="titulovaga">
                    Título da Oferta<obg className="obg">*</obg>
                  </label>
                  <Input
                    type="text"
                    title="Escreva Título da Oferta"
                    value={anuncioDados.titulovaga ? anuncioDados.titulovaga :"" }
                    handleOnChange={(value) => handlechangeValues(value)}
                    className="form-control form-control-user"
                    name="titulovaga"
                    placeholder="Título da Oferta"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="tipoVaga">
                      Tipo de Vaga<obg className="obg">*</obg>
                    </label>
                    <select
                      value={anuncioDados.id_tipovaga}
                      onChange={(value) => handlechangeValues(value)}
                      title="Selecione o tipo de vaga que pretende publicar"
                      className="form-select"
                      name="id_tipovaga"
                      id="tipoVaga"
                    >
                      <option value="0" selected>
                        (Selecionar Tipo de Vaga)
                      </option>
                      <option value="1">Tempo Inteiro</option>
                      <option value="2">Meio Período</option>
                      <option value="3">Estágio </option>
                      <option value="4">Freelancer</option>
                      <option value="5">Remoto</option>
                    </select>
                  </div>
                  <div className="col-6">
                    <label htmlFor="categoriaVaga">
                      Categotia de Funcionário<obg className="obg">*</obg>
                    </label>
                    <select
                      value={anuncioDados.id_categoriavaga}
                      onChange={(value) => handlechangeValues(value)}
                      title="Selecione uma categoria profissional que se adapte melhor a sua oferta"
                      className="form-select"
                      name="id_categoriavaga"
                      id="categoriaVaga"
                    >
                      <option value="0" selected>
                        {" "}
                        (Selecionar Categoria)
                      </option>
                      <option value="1">Medicina</option>
                      <option value="2">Programação</option>
                      <option value="3">Construção Civil</option>
                      <option value="4">Marketing</option>
                      <option value="5">Resturante/Bar</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label htmlFor="distritoVaga">Dsitrito</label>
                    <select
                      value={anuncioDados.distrito}
                      onChange={(value) => handlechangeValues(value)}
                      title="Iforme o distrito"
                      className="form-select"
                      name="distrito"
                      id="distritoVaga"
                    >
                      <option value="0" selected>
                        (Seleciona o Distrito){" "}
                      </option>
                      <option value="1">Água Grande</option>
                      <option value="2">Mé-Zochi</option>
                      <option value="3">Cantagalo</option>
                      <option value="4">Lobata</option>
                      <option value="2">Lembá</option>
                      <option value="2">Caué</option>
                      <option value="2">Região Autónoma do Príncipe</option>
                    </select>
                  </div>
                  <div className="col">
                    <label htmlFor="zonaVaga">Zona</label>
                    <Input
                      value={anuncioDados.zona ? anuncioDados.zona : ""}
                      handleOnChange={(value) => handlechangeValues(value)}
                      title="Informe a zona "
                      type="text"
                      className="form-control form-control-user"
                      name="zona"
                      placeholder="Zona"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label htmlFor="salarioVaga">Salário</label>
                    <input
                      value={anuncioDados.salario ? anuncioDados.salario : ""}
                      handleOnChange={(value) => handlechangeValues(value)}
                      title="Pode apresentar o salário da referida vaga"
                      type="text"
                      className="form-control form-control-user"
                      name="salario"
                      placeholder="Salário"
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="quantidadevaga">Quantidade de Vagas</label>
                    <Input
                      value={anuncioDados.quantidadevaga ? anuncioDados.quantidadevaga : ""}
                      onChange={(value) => handlechangeValues(value)}
                      title="Inform a quantidade de vagas que pretende anunciar"
                      type="number"
                      className="form-control form-control-user"
                      name="quantidadevaga"
                      placeholder="Quantidade de Vagas"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="row">
                  <h5 className="text-center">Informação Adicional</h5>
                  <div className="col-12 mt-4">
                    <div className="mb-4">
                      <textarea
                        value={anuncioDados.descricao}
                        onChange={(value) => handlechangeValues(value)}
                        className="form-control"
                        id="vaga_descricao"
                        name="descricao"
                        cols="30"
                        rows="10"
                        title="Apresente os detalhes sobre a vaga"
                      ></textarea>
                      <label required htmlFor="vaga_descricao">
                        Apresente uma descrição sobre a vaga
                        <obg className="obg">*</obg>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button 
                className={`btn mt-5 mb-5 anuncioButton-editar ${btnSubmit.class}`} 
                type="submit" 
                >
                <span 
                    className={`${btnSubmit.spin}`} 
                    role="status" 
                    aria-hidden="true"/> {btnSubmit.text}         
            </button>

          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default VagaAnuncioEditar;
