import React, { useState, useEffect } from "react";
import {useParams } from "react-router-dom";

import { FaUserPlus } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import {HiOutlineUpload} from "react-icons/hi"
//import { FaUserGraduate } from "react-icons/fa";

import "./perfilempresa.css";

import Api from "../../../../Api";
import { header } from "../../../../config/header";

import Load from '../../../../components/Layout/Load/Load'

//Componentes de Formulários
import Input from "../../../../components/Form/Input/input";
import Button from "../../../../components/Form/Button/Button";
import Textarea from "../../../../components/Form/Textarea/Textarea";



function Form1() {

  const id = useParams().id;

  const [btn, setBtn] = useState({
    className: "",
    text: "",
    spin: "",
  });

  const [btnSubmit, setBtnSubmit] = useState({
    className: '',
    text: 'Salvar',
    spin: ''
  })


  const [tipoEmpresa, setTipoEmpresa] = useState([])

  const [areaEmpresa, setAreaEmpresa] = useState([])


  const [file, setFile] = useState("");

  const [perfil, setPerfil] = useState({
    nome: "",
    nif: "",
    telemovelmovel: "",
    telemovelfixo: "",
    localidade: "",
    cidade: "",
    favorito: "",
    descricao: "",
    imagem: "",
    email: "",
    localidade: "",
    id_tipoempresa: "",
    id_areaempresa: "",
    id_tipoutilizador: ""
  });

  async function getUser() {
    await Api.get(`utilizador/registro/${id}`, header)
      .then((response) => {
        const data = response.data;
        setPerfil(data);
        console.log(data);

      })
      .catch((err) => {
        console.log("Houve um erro: ", err);
      });
  }

  useEffect(() => {
    getUser();
  }, [id]);


  async function getTipoEmpresa() {

    Api.get("tiposempresas", header).then(response => {
      //console.log(response.data)
      setTipoEmpresa(response.data)
    }).catch(erro => {
      console.log("Erro: ", erro)
    })

  }

  useEffect(() => {
    getTipoEmpresa();
  }, [])


  async function getAreaEmpresa() {

    Api.get("areasempresas", header).then(response => {
      //console.log(response.data)
      setAreaEmpresa(response.data)
    }).catch(erro => {
      console.log("Erro: ", erro)
    })

  }

  useEffect(() => {
    getAreaEmpresa();
  }, [])


  function handleSubmit() {


    if (perfil.id_tipoempresa === 0 || perfil.id_areaempresa === 0 || perfil.distrito === 0) {
      alert("Selcione uma das opções")
    } else {

      setBtnSubmit({
        className: 'disabled',
        text: 'Aguarde',
        spin: 'spinner-border spinner-border-sm'
      })
      Api.post(`utilizadores/${id}`, perfil, header)
        .then((response) => {
          setBtnSubmit({
            className: '',
            text: 'Salvar',
            spin: ''
          })
          alert("Alteração feita com sucesso!");
        })
        .catch((erro) => {
          setBtnSubmit({
            className: '',
            text: 'Salvar',
            spin: ''
          })
          console.log("Houve o seguinte erro: ", erro);
        });

    }


  }
  const submit = (e) => {
    e.preventDefault();
    //console.log(perfil);
    handleSubmit(perfil);
  };

  function handleChange(e) {
    setPerfil({ ...perfil, [e.target.name]: e.target.value });
  }

  function handleChangeSelect(e) {
    setPerfil({ ...perfil, [e.target.name]: e.target.value });
    //console.log(e.target.value)  
  }



  const handleChangeI = (e) => {
    console.log(e.target.file);
    setFile(e.target.files[0]);
    setBtn({
      className: "",
      text: "Guardar",
      spin: "",
    });
  };

  const handleApi = async () => {
    setBtn({
      className: "disabled ",
      text: "Aguarde",
      spin: "spinner-border spinner-border-sm",
    });

    const formData = new FormData();
    formData.append("file", file);
    await Api.post(`utilizadores/${id}`, formData, header)
      .then((response) => {
        getUser()
        alert("Foto alterada com sucesso!");
        setFile("");
        console.log("Resposta: ", response.data);
      })
      .catch((erro) => {
        setFile("");
        if (erro.response)
          console.log("Houve o seguinte erro: ", erro.response);
        else {
          //Backend fora do ar
          console.log("Erro, tente mais tarde!");
        }
      });
  };

  return (
    <>
      {perfil.id_tipoutilizador.length === 0 ? (<div className=" load-container d-flex justify-content-center align-items-center mt-5"><Load /></div>) :

        (
          <div className="container empresa-perfil-container col-xl-10 col-lg-10 col-md-10 mb-5">
            <h3 className="empresa-perfil-title mb-5 text-center">Criar/Editar Perfil</h3>

            <div className="empresa-perfil-dados col-xl-10 col-lg-12 col-md-10 shadow">
              <div className="cabecalhos">
                <div className="title-divisor">
                  o-------------------o-------------------o------
                </div>
                <div className="empresa-title-button" title="Perfil da Empresa">
                  <FaUserPlus className="mx-1" />
                  Perfil
                </div>
                <div className="title-divisor">
                  ------o-------------------o-------------------o
                </div>
              </div>
              <div className="empresa-perfil-divisao"></div>

              <form onSubmit={(value) => submit(value)}>
                <div className="empresa-perfil-foto-container">
                  <label className="mt-3">
                    <strong>Fotografia</strong>
                  </label>

                  <div className="empresa-perfil-foto-view d-flex align-items-center justify-content-center mb-3 mt-2">
                    {perfil.foto ? (
                      <img src={perfil.foto} alt="user-image" />
                    ) : (
                      <FaUserAlt className="user-img" />
                    )}
                  </div>

                  <div className="empresa-perfil-foto-button">
                    <input
                      className="fw-bold btn pd-4"
                      type="button"
                      value="Remover"
                      title="Remova a sua foto de perfil"
                    />

                    <div className="input-foto">
                      <label
                        htmlFor="foto"
                        className="mx-5 d-flex align-items-center justify-content-center fs-1"
                        title="Escolha uma foto para perfil da empresa"
                      >
                        <HiOutlineUpload/>
                      </label>
                      <input
                        type="file"
                        name="files"
                        id="foto"
                        onChange={handleChangeI}
                      />
                    </div>

                    {
                      file ? <button className={`btn btn-success fw-bold ${btn.className}`}
                        type="button"
                        onClick={handleApi}>

                        {btn.text}

                      </button> :

                        <Button
                          className="btn btn-success fw-bold disabled"
                          type="button"
                          text="Guardar"
                        />

                    }
                  </div>
                </div>

                <div className="empresa-perfil-dados d-flex flex-column justify-content-center align-items-center col-xl-10 col-lg-12 col-md-10 mt-5">
                  <h5 className="text-center">Dados Pessoais</h5>
                  <div className="col-12 mt-4">
                    <div className="mb-4">
                      <label htmlFor="nomeEmpresa">
                        Nome<span className="obg">*</span >
                      </label>
                      <Input
                        type="text"
                        title="Informa o Nome da Empresa"
                        className="form-control form-control-user"
                        name="nome"
                        placeholder="Nome da Empresa"
                        value={perfil.nome ? perfil.nome : ""}
                        handleOnChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-4">
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="nifEmpresa">NIF</label>
                        <Input
                          type="text"
                          title="Informe o NIF da Empresa"
                          className="form-control form-control-user"
                          name="nif"
                          placeholder="NIF da Empresa"
                          value={perfil.nif ? perfil.nif : ""}
                          handleOnChange={handleChange}
                        />
                      </div>
                      <div className="col">
                        <label htmlFor="codPostalEmpresa">Código Postal</label>
                        <Input
                          type="text"
                          title="Informe o Código Postal da Empresa"
                          className="form-control form-control-user"
                          name="codigopostal"
                          placeholder="Código Postal"
                          value={perfil.codigopostal ? perfil.codigopostal : ""}
                          handleOnChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <div className="col">
                      <label htmlFor="pais">
                        País<span className="obg">*</span>
                      </label>
                      <Input
                        type="text"
                        title="Informe o seu País atual"
                        className="form-control form-control-user"
                        name="pais"
                        placeholder="Seu País"
                        value={perfil.pais ? perfil.pais : ""}
                        handleOnChange={handleChange}
                      />
                    </div>
                  </div>

                  <h5 className="text-center mt-2">Contactos</h5>
                  <div className="col-12 mt-4">
                    <div className="row">
                      <div className="col-6 mb-4">
                        <label htmlFor="telEmpresa">
                          Telemóvel<span className="obg">*</span>
                        </label>
                        <Input
                          title="Introduz o Telefone da Empresa"
                          type="text"
                          className="form-control form-control-user"
                          name="telemovelmovel"
                          placeholder="Telemóvel da Empresa"
                          value={perfil.telemovelmovel ? perfil.telemovelmovel : ""}
                          handleOnChange={handleChange}
                        />
                      </div>
                      <div className="col">
                        <label htmlFor="telefoneEmpresa">
                          Telefone<span className="obg">*</span>
                        </label>
                        <Input
                          title="Insira o número de Telefone da Empresa"
                          type="text"
                          className="form-control form-control-user"
                          name="telemovelfixo"
                          placeholder="Telefone da Empresa"
                          value={perfil.telemovelfixo ? perfil.telemovelfixo : ""}
                          handleOnChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <label htmlFor="emailEmpresa">
                        Email<span className="obg">*</span>
                      </label>
                      <Input
                        title="Insira o Email da Empresa"
                        type="email"
                        className="form-control form-control-user"
                        name="email"
                        placeholder="Email da Empresa"
                        value={perfil.email ? perfil.email : ""}
                        handleOnChange={handleChange}
                      />
                    </div>
                  </div>

                  <h5 className="text-center mt-4 mb-4">Localização</h5>

                  <div className="col-12 ">
                    <div className="row">
                      <div className="col-6 mb-4">
                        <label htmlFor="distritoEmpresa">
                          Distrito<span className="obg">*</span>
                        </label>
                        <select
                          title="Informe o Distrito"
                          className="form-select"
                          name="distrito"
                          id="distritoEmpresa"
                          onChange={handleChangeSelect}
                          value={perfil.distrito ? perfil.distrito : 0}
                        >
                          {perfil.distrito
                            ?
                            ("")

                            :
                            (<option value={0} selected>(Selecione o Seu Distrito)</option>)
                          }
                          <option value="Água Grande">Água Grande</option>
                          <option value="Mé-Zochi">Mé-Zochi</option>
                          <option value="Cantagalo">Cantagalo</option>
                          <option value="Lobata">Lobata</option>
                          <option value="Caué">Caué</option>
                          <option value="Lembá">Lembá</option>
                          <option value="Região Autónoma do Príncipe">Região Autónoma do Príncipe</option>
                        </select>
                      </div>
                      <div className="col">
                        <label htmlFor="localidadeEmpresa">
                          Localidade<span className="obg">*</span>
                        </label>
                        <Input
                          title="Informe a Zona ou Bairro"
                          type="text"
                          className="form-control form-control-user"
                          name="localidade"
                          placeholder="Localiade da Empresa"
                          value={perfil.localidade ? perfil.localidade : ""}
                          handleOnChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 ">
                    <div className="row">
                      <div className="col-6 mb-4">
                        <label htmlFor="tipoEmpresa">
                          Tipo de Empresa<span className="obg">*</span>
                        </label>
                        <select
                          title="Informe o Tipo da Empresa"
                          className="form-select"
                          name="id_tipoempresa"
                          id="tipoEmpresa"
                          value={perfil.id_tipoempresa ? perfil.id_tipoempresa : 0}
                          onChange={handleChangeSelect}
                        >
                          {perfil.id_tipoempresa
                            ?
                            ("")
                            :
                            <option value={0} selected>
                              (Seleciona o ipo de Empresa)
                            </option>
                          }

                          {tipoEmpresa.map((tipo) => (
                            <option value={tipo.id} key={tipo.id}>
                              {tipo.nometipoempresa}
                            </option>
                          ))
                          }

                        </select>
                      </div>
                      <div className="col-6">
                        <label htmlFor="areaEmpresa">
                          Área da Empresa<span className="obg">*</span>
                        </label>
                        <select
                          title="Selecione área de atuação da Empresa"
                          className="form-select"
                          name="id_areaempresa"
                          id="areaEmpresa"
                          value={perfil.id_areaempresa ? perfil.id_areaempresa : 0}
                          onChange={handleChangeSelect}
                        >

                          {perfil.id_areaempresa
                            ?
                            ("")
                            :
                            <option value={0} selected>
                              (Seleciona a Área da Empresa)
                            </option>
                          }

                          {areaEmpresa.map((area) => (
                            <option value={area.id} key={area.id}>
                              {area.nomeareaempresa}
                            </option>
                          ))
                          }

                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-4">
                    <div className="mb-4">
                      <Textarea
                        className="form-control"
                        id="descricaoEmpresa"
                        name="descricao"
                        cols="30"
                        rows="10"
                        title="Descrição da Empresa"
                        value={perfil.descricao ? perfil.descricao : ""}
                        handleOnChange={handleChange}
                      />
                      <label htmlFor="descricaoEmpresa">
                        Apresente uma breve descrição sobre a Empresa
                        <span className="obg">*</span>
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  className={`btn mt-5 mb-5 perfil-button w-60 ${btnSubmit.className}`}
                  type="submit"
                >
                  <span
                    className={`${btnSubmit.spin}`}
                    role="status"
                    aria-hidden="true" /> {btnSubmit.text}
                </button>
              </form>
            </div>
          </div>
        )}
    </>
  );
}

export default Form1;
