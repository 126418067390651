import React from 'react'
import { Routes, Route } from 'react-router-dom'

/*Páginas */
import Home from './components/Home/Home'
import Dash from './components/Dashboard/Dash'
import NotFountPage from './components/Layout/NotFoundPage/NotFound'
import Contacto from './components/Pages/Contacto/Contacto'

/*Componentes do Administrador*/
import DashUtilizador from './container/Admin/Utilizador/Dashboard/DashUtilizadores'
import AdminEditarPerfil from './container/Admin/Utilizador/Perfil/AdminEditarPerfil'
import DashProempresa from './container/Admin/Empresa/Dashboard/DashEmpresas'
import DashCandidatos from './container/Admin/Candidato/Dashboard/DashCandidatos'
import Vagas from './container/Admin/Vagas/Dashboard/DashVagas'
import Items from './container/Admin/Items/Dahsboard/Items'

/*Componentes dos Utilizadores*/
import Login from './container/Utilizadores/Login/Login'
import EditarRegisto from './container/Utilizadores/Registo/Atualizar/EditarRegisto'
import VagasCandidatas from './container/Utilizadores/Vagas/Candidatadas/VagasCandidatadas'
import VagasCriadas from './container/Utilizadores/Vagas/Criadas/VagasCriadas'
import VagasFavoritas from './container/Utilizadores/Vagas/Favoritas/VagasFavoritas'
import ListarCandidatos from './container/Utilizadores/Vagas/Listar Candidatos/ListarCandidatos'

/*Componentes das Empresas */
import RegistroEmpresa from './container/Empresa/Registo/Criar/Registro'
import PerfilEmpresa from './container/Empresa/Perfil/Atualizar/PerfiEmpresa'
import VisualizarPerfilEmpresa from './container/Empresa/Perfil/Visuzalizar/VisualizarPerfil'
import PesquisarPerfilEmpresa  from './container/Empresa/Perfil/Pesquisar/PesquisarPerfilEmpresa'

/*Componentes dos Candidatos*/
import PerfilCandidato from './container/Candidato/Perfil/Atualizar/PerfiCandidato'
import RegistroCandato from './container/Candidato/Registo/Criar/Registro'
import Form2 from './container/Candidato/Perfil/Atualizar/formDados2'
import VisualizarPerfil from './container/Candidato/Perfil/Visuzalizar/VisualizarPerfil'
import PesquisarPerfil from './container/Candidato/Perfil/Pesquisar/PesquisarPerfil'


/*Componentes de Vagas */
import VagaAnuncio from './container/Vagas/Anuncio/VagaAnuncio'
import VagaAnuncioEditar from './container/Vagas/Anuncio/Editar/VagaAnuncioEditar'
import VisuzalizarAnuncio from './container/Vagas/Anuncio/Vizualisar/VisualizarAnuncio'
import PesquisarVaga from './container/Vagas/Anuncio/Pesquisar/Pesquisar'


//Private router



//import StoreContext from './config/Store/Context'

export default () => {

    return (

        <Routes>

                <Route path='/' element={<Home />} exact/>

                <Route path="*" element={<NotFountPage />} />

                <Route path='/login' element={<Login />} />

                <Route path='/registro' element={<RegistroCandato />} />

                <Route path='/registro/empresa' element={<RegistroEmpresa />} />

                <Route path='/admin/dashboard' element={<Dash />} />

                <Route path='/admin/dashboard/utilizadores' element={<DashUtilizador />} />

                <Route path='/admin/dashboard/candidato' element={<DashCandidatos />} />

                <Route path='/admin/dashboard/utilizador/editar/:id' element={<AdminEditarPerfil />} />

                <Route path='/admin/dashboard/empresas' element={<DashProempresa />} />

                <Route path='/admin/dashboard/vagas' element={<Vagas />} />

                <Route path='/admin/dashboard/items/adiconar' element={<Items />} />

                <Route path='/perfil/vizualizar/:id' element={<VisualizarPerfil />} />

                <Route path='/perfil/passo1/:id' element={<PerfilCandidato />} />

                <Route path="/perfil/passo2/:id" element={<Form2 />} />

                <Route path="/regito/editar/:id" element={<EditarRegisto />} />

                <Route path="/perfil/candidatos/pesquisar" element={<PesquisarPerfil />} />

                <Route path="/empresa/perfil/:id" element={<PerfilEmpresa />} />

                <Route path='/perfil/visualizar/:id' element={<VisualizarPerfil />} />

                <Route path='/perfil/passo1/:id' element={<PerfilCandidato />} />

                <Route path="/perfil/passo2/:id" element={<Form2 />} />

                <Route path="/regito/editar/:id" element={<EditarRegisto />} />

                <Route path="/empresa/perfil/:id" element={<PerfilEmpresa />} />

                <Route path='/perfil/empresa/visualizar/:id' element={<VisualizarPerfilEmpresa />} />

                <Route path='perfil/empresas/pesquiar' element={<PesquisarPerfilEmpresa/>}/>

                <Route path='/utilizador/vaga/anuncio/criar' element={<VagaAnuncio />} />

                <Route path='/utilizador/vaga/anuncio/editar/:id' element={<VagaAnuncioEditar />} />

                <Route path='/utilizador/vagas/candidatadas/:id' element={<VagasCandidatas />} />

                <Route path='/utilizador/vagas/favoritas/:id' element={<VagasFavoritas />} />

                <Route path='/utilizador/vagas/criadas/:id' element={<VagasCriadas />} />

                <Route path='/utilizador/vaga/candidatos/:id' element={<ListarCandidatos/> } />

                <Route path="/anuncio/detalhes/:id" element={<VisuzalizarAnuncio />} />

                <Route path='/anuncio/pesquisar' element={<PesquisarVaga/>}/>

                <Route path='/contactos' element={<Contacto />} />
      
        </Routes>
    );

}