import React, { useState, useEffect } from "react";

import "./contador.css";

import Api from "../../Api";
import { header } from "../../config/header";


function Contador() {

  const [Qempresas, setQEmpresas] = useState(0)
  const [countE, setCountE] = useState(0)

  const [individuaisQ, setindividuaisQ] = useState([]);
  const [countI, setCountI] = useState(0)

  const [vagasQ, setvagasQ] = useState([]);
  const [countV, setCountV] = useState(0)

  async function getQuantEmpresas() {
    await Api.get("dashboard/empresas", header).then((response) => {
      const Qempresas = response.data;
      //console.log(Qempresas);
      setQEmpresas(Qempresas);
    
    }).catch((err) => {
      console.log("Houve um erro: ", err);
    });
  }

  useEffect(() => {
    getQuantEmpresas();
  }, []);


  useEffect(()=>{
    let intervalId;

    if(countE < Qempresas){
      intervalId= setInterval(()=>{
        setCountE(prevCount => prevCount +1);
      }, 500);
    }else{
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);

  }, [countE, Qempresas])

  //====================================//

  async function getQuantIndivi() {
    await Api.get("dashboard/individuais", header).then((response) => {
      const Qindividuais = response.data;
      //console.log(Qindividuais);
      setindividuaisQ(Qindividuais);

    }).catch((err) => {
      console.log("Houve um erro: ", err);
    });
  }

  useEffect(() => {
    getQuantIndivi();
  }, []);


  useEffect(()=>{

    let intervalId;

    if(countI < individuaisQ){
      intervalId= setInterval(()=>{
        setCountI(prevCount => prevCount +1);
      }, 500);
    }else{
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);

  }, [countI, individuaisQ])

  //====================================//

  async function getQuantVagas() {
    await Api.get("dashboard/vagas", header).then((response) => {
      const Qvagas = response.data;
      //console.log(Qvagas);
      setvagasQ(Qvagas);
    })
      .catch((err) => {
        console.log("Houve um erro: ", err);
      });
  }

  useEffect(() => {
   
    getQuantVagas();
  }, []);


  useEffect(()=>{

    let intervalId;

    if(countV < vagasQ){
      intervalId= setInterval(()=>{
        setCountV(prevCount => prevCount +1);
      }, 500);
    }else{
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);

  }, [countV, vagasQ])
  
 

  return (
    <div className=" col-12 d-flex align-items-center justify-content-center contador-container bg-light">
      <div className=" row col-12 contador d-flex align-items-center justify-content-center">

        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12   contador-quantidade-Vagas d-flex flex-column align-items-center justify-content-center">
          <span className="contador-num fs-2 fw-bold">{countV}</span>
          <span className="contador-details fs-4">Vagas Ativas</span>
        </div>

        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12   contador-quantidade-membros d-flex flex-column align-items-center justify-content-center">
          <span className="contador-num fs-2 fw-bold">{countI + countE}</span>
          <span className="contador-details fs-4">Membros</span>
        </div>
      
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12  contador-quantidade-individual d-flex flex-column align-items-center justify-content-center">
          <span className="contador-num fs-2 fw-bold">{countI}</span>
          <span className="contador-details fs-4">Pessoas Individuais</span>
        </div>
      
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 text-light contador-quantidade-empresas d-flex flex-column align-items-center justify-content-center">
          <span className="contador-num fs-2 fw-bold">{countE}</span>
          <span className="contador-details fs-4">Empresas</span>
        </div>
      </div>
    </div>
  );
}

export default Contador;
