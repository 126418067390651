import React, { useState, useEffect, useContext } from 'react'
import { BsChevronDoubleDown } from "react-icons/bs"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import './home.css'


//Importando outros componentes
import Contador from '../../container/Contador/Contador';
import ListaAnuncio from '../../container/Vagas/Anuncio/Listar/ListaAnuncio';
import Tarifario from '../../container/Tarifarios/Tarifarios'
import UserAccoes from '../Layout/UserAccoes/UserAccoes';
import CategoriasPopulares from '../CategoriasPopulares/CategoriasPopulares';


import Nav from '../Layout/Navbar/Nav'
import Footer from '../Layout/Footer/Footer'
import Input from '../Form/Input/input';

//import { set } from 'date-fns';

import Api from '../../Api';

import StoreContext from '../../config/Store/Context';



function Home() {

    const notify = (msg) => {
        toast.success(msg);
    }

    const [categoriaVaga, setCategoriaVaga] = useState([])

    const [vaga, setVaga] = useState({
        distrito_vaga: " ",
        palavraChave_vaga: " ",
        id_categoria_vaga: " ",
    })

    const header = process.env.REACT_APP_ACCESS_KEY

     //Dados do Utilizador Logado
     const { loginStatus, setLoginStatus } = useContext(StoreContext)


    function handleChange(e) {
        setVaga({ ...vaga, [e.target.name]: e.target.value });
    }

    async function getCategoriaVaga() {

        Api.get("categoriasvagas", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setCategoriaVaga(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getCategoriaVaga();
    }, [])

    const scrollBottom = () => {
        window.scrollTo({
            top: 570,
            behavior: "smooth"
        })
    }

    useEffect(()=>{
        if(loginStatus === true){
            notify("Bem-Vindo");
            setLoginStatus(false);  
        }

    })

    return (
        <>
        <ToastContainer />
            <Nav navClassName={"nav-home-home"} />
            <div className='col-12 home-banner d-flex flex-column align-items-center justify-content-end p-4'>
                <h1 className='text-light home-title text-center'>Encontre <span>oportunidades</span> em um clique</h1>
                <p className='home-paragrafo text-center'>O caminho mais curto para encontar oportunidades de trabalho</p>
                <div className='col-xl-8 col-lg-8 col-md-10 col-sm-10 col-12 mt-4 mt-md-5 d-flex flex-column align-items-center justify-content-center home-form'>
                    <div className="row d-flex align-items-center justify-content-center col-12">
                        
                        <Input
                            type="text"
                            className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3 home-inputs'
                            placeholder="Palavra Chave"
                            name="palavraChave_vaga"
                            handleOnChange={handleChange}
                        />
                        <select
                            title="Informe Distrito"
                            className=" col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3 mt-2 mt-lg-0 mt-xl-0 home-inputs pesquisar-vaga-porDistrito"
                            name="distrito_vaga"
                            id="distrito_vaga"
                            value={vaga.distrito_vaga || ''}
                            onChange={handleChange}
                        >
                            <option value="" selected>(Todos Distritos)</option>
                            <option value="Água Grande">Água Grande</option>
                            <option value="Mé-Zochi">Mé-Zochi</option>
                            <option value="Cantagalo">Cantagalo</option>
                            <option value="Lobata">Lobata</option>
                            <option value="Caué">Caué</option>
                            <option value="Lembá">Lembá</option>
                            <option value="Região Autónoma do Príncipe">Região Autónoma do Príncipe</option>
                        </select>
                        <select
                            className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3 mt-2 mt-lg-0 mt-xl-0 home-inputs'
                            name="id_categoria_vaga"
                            id="id_categoria_vaga"
                            onChange={handleChange}
                        >
                            <option value="" selected>
                                (Todas Categorias)
                            </option>
                            {categoriaVaga.map((categoria) => (
                                <option value={categoria.id} key={categoria.id}>
                                    {categoria.nomecategoriavaga}
                                </option>
                            ))
                            }

                        </select>
                        <button className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3 mt-2 mt-lg-0 mt-xl-0 text-light home-inputs'>Procurar</button>
                    </div>

                    <div className=" col-12 banner-btn d-flex flex-column align-items-center justify-content-end" onClick={scrollBottom}>
                        <BsChevronDoubleDown
                            className='banner-btn-incon '
                        />
                    </div>

                </div>

            </div>
            {/* 
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={IMG} className="d-block w-100" alt="hom1-1" />
                        <div className="carousel-caption d-none d-md-block">
                            <h1 className='carousel-title'>First slide label</h1>
                            <p className='fs-3'>Some representative placeholder content for the first slide.</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={IMG2} className="d-block w-100" alt="hom1-2" />
                        <div className="carousel-caption d-none d-md-block">
                            <h1 className='carousel-title'>Second slide label</h1>
                            <p className='fs-3'>Some representative placeholder content for the second slide.</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={IMG3} className="d-block w-100" alt="home-3" />
                        <div className="carousel-caption d-none d-md-block">
                            <h1 className='carousel-title'>Third slide label</h1>
                            <p className='fs-3'>Some representative placeholder content for the third slide.</p>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            
            {/*<div className="containerHome d-flex justify-content-center align-items-center ">

            <div className="home-conteudo d-flex flex-column justify-content-center align-items-center col-10">
                <h1 className="home-conteudo-title mb-3 mt-5">Encontre a Vaga dos Seus Sonhos</h1>
                <h6 className=" home-conteudo-subtitle mb-5">De um jeito simples e prático</h6>

                <div className="home-conteudo-buscar col-10 d-flex flex-row justify-content-center align-items-center">

                    <input className='col-4 buscar-input' placeholder='Plavra-chave' type="text" name="home-buscar" id="home-buscar" />
                    
                    <select title='Selecione a região' className='col-3  buscar-input select1' name="busca-regiao" id="busca-regiao">
                                        <option value="0" selected>Todas Regiões</option>
                                        <option value="1">Distrito de Água Grande</option>
                                        <option value="2">Distrito de Mé-Zochi</option>
                                        <option value="3">Distrito de Cantagalo</option>
                                        <option value="4">Distrito de Lobata</option>
                        </select>
                       
                        <select title='Selecione a categoria' className='col-3  buscar-input select2' name="busca-categoria" id="busca-categoria">
                                        <option value="0" selected>Todas Categorias</option>
                                        <option value="1">Informática</option>
                                        <option value="2">Medicina</option>
                                        <option value="3">Limpeza</option>
                                        <option value="4">Construção Civil</option>
                        </select>

                        <button className='col-2  buscar-input'>Procurar</button>

                </div>

            </div>
            
    </div>*/}
            <CategoriasPopulares />
            <UserAccoes />
            <ListaAnuncio />
            <Tarifario />
            <Contador />
            <Footer />

        </>
    );
}

export default Home