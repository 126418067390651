import React, { useEffect, useState, useContext } from 'react'

import './style.css'
import IMG from '../../../assets/login.jpg'
//import {AiFillFacebook} from 'react-icons/ai'
//import {AiFillGoogleCircle} from 'react-icons/ai'



import { ToastContainer, toast } from "react-toastify"

import Api from '../../../Api';
import { header } from '../../../config/header';
import { useNavigate } from 'react-router-dom';
import StoreContext from '../../../config/Store/Context';


import Navbar from '../../../components/Layout/Navbar/Nav'
import Footer from '../../../components/Layout/Footer/Footer'
import Input from '../../../components/Form/Input/input'


//Importando o AOS, lib para realizar os Scrolls
import Aos from 'aos'
import 'aos/dist/aos.css'

function Login() {

    /*
        const notify = (msg) => {
            toast.success(msg)
        }
    */

    let navigate = useNavigate()
    const { setUser } = useContext(StoreContext);
    const { setLoginStatus } = useContext(StoreContext);

    const [loginError, setLoginError] = useState([]);


    const [values, setValues] = useState({
        email: "",
        senha: "",
    });

    const [btnSubmit, setBtnSubmit] = useState({
        class: '',
        text: 'Entrar',
        spin: ''
    })

    const notifyError = (msg) => {
        toast.error(msg)
    }

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);



    //console.log("AAAAAAAAA: ", data)


    async function submit(e) {
        e.preventDefault()
        setLoginError([]);

        if (values.email === "" || values.email === undefined || values.email === null) {
            loginError.push("Informe o email")
        }
        if (values.senha === "" || values.senha === undefined || values.senha === null) {
            loginError.push("Informe a senha")
        }

        if (loginError.length > 0) {

            loginError.map((error) => (
                notifyError(error)
            ))
            setLoginError([]);

        } else {
            setBtnSubmit({
                class: 'disabled',
                text: 'Aguarde',
                spin: 'spinner-border spinner-border-sm'
            })
            await Api.post("utilizador/login", {
                email: values.email,
                senha: values.senha,
            }, header).then((response) => {
                //authService.setLoggedUser(response.data.data)
                // console.log(response.data);
                if (response.data.resposta === "Logou") {
                    setUser(response.data.data);
                    setLoginStatus(true);
                    navigate("/")
                    //window.location.reload()
                }
                setBtnSubmit({
                    class: '',
                    text: 'Entrar',
                    spin: ''
                })

            }).catch((erro) => {
                setBtnSubmit({
                    class: '',
                    text: 'Entrar',
                    spin: ''
                })
                if (erro.message === "Request failed with status code 401") {
                    notifyError("Email e/ou senha errada!")
                    setBtnSubmit({
                        class: '',
                        text: 'Entrar',
                        spin: ''
                    })
                    console.log("Houve o seguinte erro: ", erro)
                }

            });

        }

    }

    function handlechangeValues(value) {
        const newvalues = { ...values }
        newvalues[value.target.name] = value.target.value
        setValues(newvalues)
        console.log(newvalues)
    };



    return (
        <>
            <ToastContainer />
            <Navbar navClassName={"nav-home"} />

            <div className="container container-height d-flex justify-content-center align-items-center mt-2 mt-lg-4 login-page-form">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="card col-12 o-hidden border-0 shadow-lg my-4 mt-5">
                            <div className="card-body p-0">
                                <div className="row ">
                                    <div className=" col-lg-6 col-0 d-none d-lg-block" id="login-image">
                                        <img
                                            class="rounded-start"
                                            src={IMG}
                                            alt="profisiional-imagem"
                                        />
                                    </div>
                                    <div className="col-lg-6 login-container">
                                        <div className="padding col-12 login-body">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-5 mt-4 sessao-text" >Inicisar Sessão</h1>
                                            </div>
                                            <form className="user" onSubmit={(value) => submit(value)} >
                                                <div class="form-group col-12 mb-4">
                                                    <Input
                                                        type="email"
                                                        className="form-control form-control-user input-login"
                                                        id="login-email"
                                                        name="email"
                                                        placeholder="Email"
                                                        value={values.email} handleOnChange={(value) => handlechangeValues(value)}
                                                    />
                                                </div>
                                                <div className="form-group col-12 mb-4 ">
                                                    <Input
                                                        type="password"
                                                        className="form-control form-control-user input-login"
                                                        id="login-senha"
                                                        name="senha"
                                                        placeholder="Senha"
                                                        value={values.senha} handleOnChange={(value) => handlechangeValues(value)}
                                                    />
                                                </div>
                                                {/*
                                        <div class="form-group mt-3 mb-3 lembreme">
                                            <div class="custom-control custom-checkbox small d-flex justify-content-start align-items-center">
                                            <Input
                                            id="customCheck"
                                            type="checkbox"
                                            className="form-check-input custom-control-input"
                                            name="senha"
                                            />
                                                <label class="input-label custom-control-label" htmlfor="customCheck">Lembra-me</label>
                                            </div>
                                        </div>*/}
                                                <button
                                                    className={`btn btn-primary form-control btn-user btn-block ${btnSubmit.class}`}
                                                    type="submit"
                                                    id="button"
                                                >
                                                    <span
                                                        className={`${btnSubmit.spin}`}
                                                        role="status"
                                                        aria-hidden="true" /> {btnSubmit.text}
                                                </button>

                                                {/*<hr/><Input text="Entrar" type ="submit" className="btn btn-primary form-control btn-user btn-block" id="button"/>
                                        <a class="btn btn-google btn-user btn-block autenticar-google-button">
                                             <AiFillGoogleCircle/> Login com Google
                                        </a>
                                        <a class="btn btn-facebook btn-user btn-block autenticar-facebook-button mt-2">
                                             <AiFillFacebook/> Login com Facebook
                                        </a>*/}
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <a className="small" href="" id='link-bottom'>Esqueceu a palavra passe?</a>
                                            </div>
                                            <div className="text-center mb-1">
                                                <a className="small" href="/registro" id='link-bottom'>Cria uma conta!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {/*<ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {
                toast('🦄 Wow so easy!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }

        <ToastContainer />*/}
            <Footer />
        </>

    );
}
export default Login;