//import EditarButton from "./Buttons/EditarButton";

import './dashutilizadores.css'

import { BiEdit } from "react-icons/bi";
import { MdDelete } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';

//import Checkbox from '../../../components/Form/Checkbox/Checkbox';


import Api from "../../../../Api";

import { header } from '../../../../config/header';

const url = process.env.REACT_APP_APP_URL

async function deleteUser(id) {

    await Api.get(`utilizadores/${id}`, header).then((response) => {
        alert("Utilizador eliminado com sucesso!")
        window.location.reload();
    })
        .catch((erro) => {
            console.log("Houve um erro: " + erro)
        });
}



export const columns = [
    {
        name: 'Imagem',
        selector: (row) => (
            row.foto ? (<img width={40} height={40} src={row.foto} alt="user-img" />)
                :
                (<img width={40} height={35} src={`${url}/files/user.png`} alt="userimg-icon-alt" />)
        )

    },
    {
        name: 'Nome',
        selector: (row) => [row.nomeutilizador],
        sortable: true

    },
    {
        name: 'Email',
        selector: (row) => [row.email],
        sortable: true

    },

    {
        name: 'Tipo',
        selector: (row) => [row.tipoutilizador.nometipoutilizador],
        sortable: true

    },
    {
        name: 'Ação',
        cell: (row) => <div className="d-flex">
            {row.tipoutilizador.nometipoutilizador === "Empresa"
                ?
                <a className="btn btn-table mx-2" title="Vizualizar Utilizador" href={`/perfil/empresa/visualizar/${row.id}`}><AiFillEye className='text-primary' /></a>
                :
                <a className="btn btn-table mx-2" title="Vizualizar Utilizador" href={`/perfil/vizualizar/${row.id}`}><AiFillEye className='text-primary' /></a>
            }
            <a className="btn btn-table" title="Editar Utilizador" href={`/admin/dashboard/utilizador/editar/${row.id}`}><BiEdit className='text-success' /></a>
            <button className="btn btn-table" title="Eliminar Utilizador" onClick={() => deleteUser(row.id)} ><MdDelete className='text-danger' /></button>
        </div>

    },

];
