import React from "react"

import "./contacto.css"

import Nav from "../../Layout/Navbar/Nav";
import Footer from "../../Layout/Footer/Footer";
import Input from "../../Form/Input/input";
import Textarea from "../../Form/Textarea/Textarea";
import Button from "../../Form/Button/Button";

//import ws from "../../../assets/foto-perfil-watson.png"

function Contacto() {

    return (
        <>
            <Nav navClassName={"nav-home"}/>
            <div className="contacto-container col-12">

                <h2 className="contacto-title text-center text-white mb-5">Contacte-nos</h2>

                <div className="contacto-body col-12 d-flex justify-content-center">

                    <div className="col-10 mt-5">

                        <div className="row">

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-5">

                                <form>
                                    <h4 className="mb-5 text-start contacto-form-title">Retornaremos em breve</h4>
                                    <Input
                                        type="text"
                                        placeholder="Nome Completo"
                                        id="contacto-nomecompleto"
                                        name="contactonome"
                                        title="Insira o seu Nome Completo"
                                        className="form-control mb-4"
                                    />

                                    <Input
                                        type="email"
                                        placeholder="Email"
                                        id="contacto-email"
                                        name="contactoemail"
                                        title="Insira o seu Nome Email"
                                        className="form-control mb-4"
                                    />

                                    <Input
                                        type="text"
                                        placeholder="Assunto"
                                        id="contacto-assunto"
                                        name="contactoassunto"
                                        title="Insira o Assunto"
                                        className="form-control mb-4"
                                    />

                                    <Textarea
                                        placeholder="Mensagem"
                                        id="contacto-email"
                                        name="contactoemail"
                                        title="Insira a mensagem"
                                        className="form-control mb-4"
                                        cols="30"
                                        rows="10"
                                    />

                                    <div className="col-4 contacto-btn-div">
                                        <Button
                                            id="contacto-btn"
                                            className="contacto-btn"
                                            text="Enviar"
                                            type="button" />
                                    </div>

                                </form>

                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 contacto-comunication">
                                <h4 className="mb-5">+Oportunidades</h4>

                                <div className="row d-flex justify-content-center align-items-center">

                                    <ul className="mb-4 col-12 justify-content-center align-items-center contacto-comunication-list">
                                        <li className="mb-3"><p className="fs-5">Distrito de Água Grande - São Tomé </p></li>
                                        <li className="mb-3 fs-5"><p className="fs-5 mt-1">Contacte-nos: +239 9804289</p></li>
                                        <li className="mb-3 fs-5"><p className="fs-5">Email: maisoportunidades@gmail.com</p></li>
                                    </ul> 

                                    <div className="mb-4 col-12 justify-content-center align-items-center">
                                        <button className="btn contacto-comunication-btn contacto-comunication-btn1">
                                            Ver no mapa
                                        </button>
                                        <button className="btn contacto-comunication-btn contacto-comunication-btn2">
                                            Indicações
                                        </button>
                                    </div> 

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <Footer />
        </>
    );

}

export default Contacto