import React from "react"
import "./useraccoes.css"

function UserAccoes() {
    return (
        <div className="actions-container col-12 ">
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex align-itens-start  flex-column actions-body actions-body-1">
                    <h1 className="mb-2 text-light">Empresas</h1>
                    <ol className="text-light">
                        <li>
                            Aqui as empresas terão facilidades em anunciar e encontrar novos colaboradores  
                        </li>
                        <li>Criar Anúncios</li>
                        <li>Pesquisar Candidatos</li>
                        <li>Visualizar pessoas candidatadas nas suas vagas</li>
                    </ol>
                    <a href="#" className="btn actions-btn actions-btn-1">
                        Criar Anúncio
                    </a>
                </div>
                <div className="col-12 d-flex col-sm-12 col-md-12 col-lg-6 col-xl-6 flex-column align-items-end actions-body actions-body-2">
                    <h1 className="text-light">Candidatos</h1>
                    <ol accessKey="a" className="text-light">
                        <li>Encontre vagas de empregos com mais facilidades aqui</li>
                        <li>Pesquise novas oportunidades de emprego</li>
                        <li>Candidate à vaga do seu interesse</li>
                        <li>Pesquise por empresas</li>
                    </ol>
                    <a href="#" className="btn actions-btn actions-btn-2">
                        Procurar Vagas
                    </a>
                </div>
            </div>

        </div>
    );
}

export default UserAccoes