import React, { useContext, useEffect, useState } from 'react';

import { FaUserCircle } from "react-icons/fa"
import { ImProfile } from "react-icons/im"
import { ImExit } from "react-icons/im"
import { FaUserAlt } from "react-icons/fa"
import { FaUserPlus } from "react-icons/fa"
import { GoPlus } from 'react-icons/go'
import { FaUsers } from "react-icons/fa"
import { RiBuilding2Fill } from "react-icons/ri"
import { ImSearch } from "react-icons/im"
import { AiFillStar } from "react-icons/ai"
import { MdAdminPanelSettings } from 'react-icons/md';


import IMG from '../../../assets/logo.png'
import './nav.css';

import authService from '../../../config/auth'
import { useNavigate } from 'react-router-dom';

import Api from "../../../Api";
import { header } from '../../../config/header';
import StoreContext from "../../../config/Store/Context"


function Nav({ navClassName }) {

    //const[linkLogin, setLinkLogin] = useState("")


    //Dados do Utilizador Logado
    const { user } = useContext(StoreContext)


    let navigate = useNavigate()

    async function logout() {
        await Api.post("utilizador/logout", header).then(response => {

            if (response.data.resposta === "Deslogou") {
                authService.cleanLoggedUser()
                navigate("/")
                window.location.reload()
            }

        }).catch(err => {
            console.log(err)
        })
    }


    //Animação da Nav com o Scroll
    const [classe, setClasse] = useState("");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 560) {
                setClasse("scroll-hight")
            } else {
                setClasse("")
            }
        })
    }, []);

    return (



        <nav className={`navbar ${navClassName} navbar-expand-lg fixed-top`} id={classe}>
            <div className="container-fluid">
                <a href="/" className="navbar-brand" id="logo">
                    <img src={IMG} alt="Logo-marca" />
                </a>
                <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav">
                        <a href="/" className="nav-item nav-link active item-menu-1 text-light">Home</a>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle item-menu-2 active text-light mr-5" data-bs-toggle="dropdown">Vagas</a>
                            <div className="dropdown-menu dropdown-menu2">
                                {user
                                    ?
                                    (<>
                                        <a href="/anuncio/pesquisar" className="dropdown-item dropdown-item2 text-light"><ImSearch className='fw-bold icon-marginright' />Procurar Vagas</a>
                                        <hr className="dropdown-divider text-secondary" />
                                        <a href={`/utilizador/vagas/favoritas/${user.id}`} className="dropdown-item dropdown-item2 text-light"><AiFillStar className='mb-1 icon-marginright' />Favoritas</a>
                                        <a href={`/utilizador/vagas/candidatadas/${user.id}`} className="dropdown-item dropdown-item2 text-light">Candidatadas</a>
                                        <hr className="dropdown-divider text-secondary" />
                                        <a href="/utilizador/vaga/anuncio/criar" className="dropdown-item dropdown-item2 text-light"><GoPlus className='mb-1 icon-marginright fw-bold' />Criar Anúncio</a>
                                        <a href={`/utilizador/vagas/criadas/${user.id}`} className="dropdown-item dropdown-item2 text-light">Meus Anúncios</a>
                                    </>

                                    )
                                    :
                                    <a href="/anuncio/pesquisar" className="dropdown-item dropdown-item2 text-light d-flex align-items-center justify-content-center"><ImSearch className='icon-marginright' />Procurar Vagas</a>
                                }

                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle item-menu-2 active text-light mr-5" data-bs-toggle="dropdown">Candidatos</a>
                            <div className="dropdown-menu dropdown-menu2 ">
                                <a href="/perfil/candidatos/pesquisar" className="dropdown-item dropdown-item2  text-light d-flex align-items-center justify-content-center" tabIndex="-1"><ImSearch className='icon-marginright' />Procurar</a>
                            </div>
                        </div>

                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle item-menu-2 active text-light mr-5" data-bs-toggle="dropdown">Empresas</a>
                            <div className="dropdown-menu dropdown-menu2">
                                <a href="/perfil/empresas/pesquiar" className="dropdown-item dropdown-item2  text-light d-flex align-items-center justify-content-center" tabIndex="-1"><ImSearch className='icon-marginright' />Procurar</a>
                            </div>
                        </div>

                        <a href="/contactos" className="nav-item nav-link item-menu-1 active text-light" tabIndex="-1">Contactos</a>
                        {/*<a href="#" className="nav-item nav-link item-menu-1 active text-light" tabIndex="-1">Sobre</a>*/}
                    </div>
                    <div className="navbar-nav ms-auto">
                        {user
                            ?
                            <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle item-menu-2 active text-light fs-5" data-bs-toggle="dropdown">{user.nomeutilizador}</a>
                                <div className="dropdown-menu right">
                                    <>
                                        {user.id_tipoutilizador === 1
                                            ? (
                                                <>
                                                    {
                                                        user.id_nivelacesso === 2
                                                            ?
                                                            (<>
                                                                < a href={`/empresa/perfil/${user.id}`} className="dropdown-item dropdown-item2 text-light"><ImProfile className='mb-1 icon-marginright' />Perfil</a>
                                                                <a href={`/regito/editar/${user.id}`} className="dropdown-item text-light dropdown-item2"><FaUserCircle className='mb-1 icon-marginright' />Registo</a>
                                                                < a href="/admin/dashboard" className="dropdown-item dropdown-item2 text-light"><MdAdminPanelSettings  className='mb-1 admin-icon icon-marginright' />Painel Admin</a>
                                                            </>
                                                            ) :
                                                            <>
                                                                < a href={`/empresa/perfil/${user.id}`} className="dropdown-item dropdown-item2 text-light"><ImProfile className='mb-1 icon-marginright' />Perfil</a>
                                                                <a href={`/regito/editar/${user.id}`} className="dropdown-item text-light dropdown-item2"><FaUserCircle className='mb-1 icon-marginright' />Registo</a>
                                                            </>
                                                    }
                                                </>
                                            )
                                            :

                                            (
                                                <>
                                                    {
                                                        user.id_nivelacesso === 2
                                                            ?
                                                            (<>
                                                                <a href={`/perfil/passo1/${user.id}`} className="dropdown-item dropdown-item2 text-light"><ImProfile className='mb-1 icon-marginright' />Perfil</a>
                                                                <a href={`/regito/editar/${user.id}`} className="dropdown-item text-light dropdown-item2"><FaUserCircle className='mb-1 icon-marginright' />Registo</a>
                                                                < a href="/admin/dashboard" className="dropdown-item dropdown-item2 text-light"><MdAdminPanelSettings  className='mb-1 admin-icon icon-marginright fs-5' />Painel Admin</a>
                                                            </>
                                                            ) :
                                                            <>
                                                                <a href={`/perfil/passo1/${user.id}`} className="dropdown-item dropdown-item2 text-light"><ImProfile className='mb-1 icon-marginright' />Perfil</a>
                                                                <a href={`/regito/editar/${user.id}`} className="dropdown-item text-light dropdown-item2"><FaUserCircle className='mb-1 icon-marginright' />Registo</a>
                                                            </>
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                    <a className="dropdown-item text-light dropdown-item2 " onClick={logout}><ImExit className='mb-1 icon-marginright' />Sair</a>
                                </div>
                            </div>
                            :
                            <div className=' d-flex registo_login '>
                                <div className="nav-item dropdown ">
                                    <a href="#" className="nav-link dropdown-toggle item-menu-1 active text-light" data-bs-toggle="dropdown"><FaUserPlus className='mb-1 icon-marginright fs-5' />Registo</a>
                                    <div className="dropdown-menu">
                                        <a href="/registro" className="dropdown-item dropdown-item2 text-light"><FaUsers className='mb-1 icon-marginright' />Candidato</a>
                                        <a href="/registro/empresa" className="dropdown-item dropdown-item2 text-light "><RiBuilding2Fill className='mb-1 icon-marginright' />Empresa</a>
                                    </div>
                                </div>
                                <a href="/login" className={`nav-item nav-link active item-menu-1 text-light px-3 login-link d-flex align-items-lg-center justify-content-lg-center`}><FaUserAlt className='mb-1 icon-marginright fs-7 ' />Login</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </nav >

    );
}

export default Nav;