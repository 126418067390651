import React from 'react';
import './dash.css'
import Dashsidebar from '../../container/Dashsidebar/Dashsidebar';
import DashPrincipal from '../../container/DashPrincipal/DashPrincipal';
import DashNav from '../../container/DashNav/DashNav'

import '../../../src/assets/css/bootstrap.min.css'


function Dash() {
    return (
        
            <div className="d-flex" id="wrapper">
                <DashNav/>
                <Dashsidebar />
                <DashPrincipal/>
            </div>
        
    );
}

export default Dash