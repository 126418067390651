import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
/*
import dotenv from "dotenv"
import path from "path"
dotenv.config({path: path.resolve(__dirname, '.env')});
*/


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />
);

