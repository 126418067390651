import React, { useState, useEffect } from "react";
import "./dashempresas.css";

import Dashsidebar from "../../../Dashsidebar/Dashsidebar";
import Api from "../../../../Api";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns } from "./dados";
import Load from '../../../../components/Layout/Load/Load'

import DashNav from "../../../DashNav/DashNav";
import DashFooter from "../../../DashFooter/DashFooter";

function Empresas() {

  const header = process.env.REACT_APP_ACCESS_KEY

  const [loadEstado, setLoadEstado] = useState(true)
  const [data, setData] = useState([]);

  async function getDados() {
    await Api.get("utilizadores/empresas", {
      headers: {
        'authorization': header,
        'Content-Type': 'application/json',
      }
    }).then((response) => {

      console.log(response);
      setData(response.data);
    
        setLoadEstado(false)
      

    }).catch((erro)=>{
      console.log("Houve um erro: ", erro)
      setLoadEstado(false)
    });
  }

  useEffect(() => {
    getDados();
  }, []);

  createTheme(
    "solarized",
    {
      text: {
        primary: "black",
        secondary: "white",
      },
      background: {
        default: "",
      },
      context: {
        background: "black",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "red",
      },
    },
    "dark"
  );

  //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

  const tableData = {
    columns,
    data,
  };
  return (
    <div className="d-flex dash-altura" id="wrapper">
      <DashNav />

      <Dashsidebar />

      <div className="" id="page-content-wrapper">
        <h2 align="center" className="mb-2">
          Lista das Empresas
        </h2>
        {loadEstado === true ? (
          <div className="w-100 load-height d-flex justify-content-center align-items-center">
            <Load />
          </div>
        ) : (
          <DataTableExtensions className="mt-5" {...tableData}>
            <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              defaultSortAsc={false}
              striped
              pagination
              entriesOptions={[5, 20, 25]}
              highlightOnHover
              fixedHeader
              fixedHeaderScrollHeight="265px"
            />
          </DataTableExtensions>
        )}

        <div className="empresa-footer-margin">
          <DashFooter />
        </div>
      </div>
    </div>
  );
}

export default Empresas;
