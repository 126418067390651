import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'

import "./listaanuncio.css";
//import { FaHeart } from "react-icons/fa";
//import { ImLocation } from "react-icons/im";
//import { MdLocalMall } from "react-icons/md";
//import { FaHardHat } from "react-icons/fa";
//import { FaCalendarAlt } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaUserAlt } from 'react-icons/fa'

import BtnTipoVaga from "../Buttons/BtnTipoVaga";


import Api from "../../../../Api.js";
import { header } from "../../../../config/header";

function ListaAnuncio() {

  const [data, setData] = useState([]);

  async function getDados() {
    await Api.get("morelatestfive", header).then((response) => {
      console.log(response);
      setData(response.data);
    });
  }


  useEffect(() => {
    getDados();
  }, []);

  return (
    <div className="lista-anuncio-container bg-light pt-5 p-3 " align="center">
      <div className="text-center lista-anuncio-titulo mb-5">
        <h1 className="lista-anuncio-title">Vagas postadas recentemente</h1>
      </div>
      {data.map((dados) => (
        <Link to={`/anuncio/detalhes/${dados.id}`} key={dados.id}>
          <div
            className="shadow p-3 lista-anuncio-dados mt-3 col-xl-10 col-lg-10 col-md-8 col-sm-10 col-12
                      "
          >
            <div className="row">

              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12  d-flex flex-row align-items-center justify-content-start
                              justify-content-md-between align-items-md-center justify-content-sm-start align-items-sm-start
                              justify-content-lg-center align-items-lg-start">
                <div className="row col-12 d-flex align-items-start justify-content-start
                                justify-content-md-between align-items-md-center 
                                justify-content-lg-between align-items-lg-center
                                justify-content-xl-between align-items-xl-center">

                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-start">
                    {dados.utilizador.foto
                      ?
                      <img
                        className="lista-anuncio-logo-utilizador  align-items-center justify-content-start"
                        src={dados.utilizador.foto}
                      />
                      :
                      <FaUserAlt className="lista-anuncio-logo-utilizador-alt " />
                    }
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 d-flex flex-row align-items-start justify-content-start
                                  justify-content-md-start align-items-md-start justify-content-sm-start align-items-sm-start
                                  justify-content-lg-center align-items-lg-center">
                    <div className="row col-12 d-flex flex-row align-items-center justify-content-start 
                                    justify-content-md-between align-items-md-start ">

                      <p className="col-12 lista-anuncio-titulo-vaga text-start d-flex flex-row align-items-center justify-content-start mb-2 mt-2">
                           {dados.titulovaga}
                      </p>
                      <span className="col-12 mb-xl-0 mb-lg-0 mb-sm-2 mb-2 lista-anuncio-nome-utilizador d-flex flex-row align-items-center justify-content-start ">
                        {dados.utilizador.nomeutilizador}
                      </span>

                    </div>

                  </div>

                </div>

              </div>

              <div className="mb-xl-0 mb-lg-0 mb-sm-4 mb-4 col-xl-5 col-lg-3 col-md-8 col-sm-12 col-12 d-flex align-items-start justify-content-start lista-anuncio-parte-3
                              justify-content-md-start align-items-md-center justify-content-sm-start align-items-sm-start
                              justify-content-lg-center align-items-lg-center">

               { /*< ImLocation
                  title="Localização"
                  className="mx-1 text-warning "
                />*/}
                <label className="mt-1 mr-2  lista-anuncio-parte-distrito">
                  Distrito: {dados.distrito}
                </label>
              </div>


              <div className=" col-xl-3 col-lg-3 col-8 d-flex align-items-start justify-content-start lista-anuncio-part4
                              justify-content-md-start align-items-md-center justify-content-sm-start align-items-sm-start
                              justify-content-lg-center align-items-lg-center">

                <BtnTipoVaga
                  id_tipovaga={dados.id_tipovaga}
                  nome_tipovaga={dados.tipovaga.nometipovaga} 
                  height='heigh_big'/>
                  

              </div>

            </div>

          </div>

        </Link>
      ))}

      <div className="mt-5">
        <a href="/anuncio/pesquisar" className="all-anuncio ">
          Ver todas as vagas <AiOutlineArrowRight />
        </a>
      </div>

    </div>
  );
}
export default ListaAnuncio;
