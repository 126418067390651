import React from 'react'
import load from './load.module.css'
import load2 from './load2.module.css'


function Load({controlador}){

    return (
        <>

        {controlador === 1 ? <span className={load.loading}></span>: <span className={load2.loading}></span>}

        </>
        
        );


}

export default Load