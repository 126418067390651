import React from "react"
import { Link } from "react-router-dom";

import "./listagem.css"


import { ImLocation } from "react-icons/im";
//import { FaCalendarAlt } from "react-icons/fa";

import BtnTipoVaga from "../../../container/Vagas/Anuncio/Buttons/BtnTipoVaga";

function ListagemVaga({ data }) {

    return (
        <>
            {
                data.map((dado) => (

                    (<Link to={`/anuncio/detalhes/${dado.id}`} key={dado.id}>

                        <div key={dado.id} className="shadow mb-4 col- col-md-12 mt-5 ">

                            <div className="row listar-info-vaga d-flex align-items-center justify-content-start py-3">

                                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex flex-row align-items-center justify-content-center
                                                 mb-3 mb-lg-0 mb-xl-0 mx-1 mx-lg-0 mx-xl-0  mt-lg-0 mt-xl-0">

                                    <div className="row col-12 ">
                                        <div className="col-xl-2 col-lg-2 col-12 d-flex align-items-center justify-content-start mb-3 mb-lg-0 mb-xl-0">
                                            <span className="listar-foto-uservaga ">
                                                <img src={dado.utilizador.foto} alt="foto-uservaga" />
                                            </span>
                                        </div>
                                        <div className="col-xl-10 col-lg-10 col-md-8 col-sm-8 col-8 d-flex align-items-center justify-content-start">
                                            <label className="listar-nomeuser-vaga d-flex align-items-center justify-content-start mx-xl-2 md-lg-2">
                                                {dado.utilizador.nome}
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 d-flex align-items-center justify-content-start justify-content-lg-center justify-content-xl-center
                                                mb-sm-3 mb-3 mb-lg-0 mb-xl-0 mb-md-0 justify-content-md-start mx-3 mx-lg-0 mx-xl-0">
                                    <div className="row flex-column d-flex align-items-center justify-content-center justify-content-md-start ">
                                        <div className="col-12 d-flex align-items-center justify-content-start justify-content-lg-center justify-content-xl-center text-center">
                                            <label className="listar-titulo-vaga">
                                                {dado.titulovaga}
                                            </label>
                                        </div>
                                        <div className="col-12 d-flex align-items-center justify-content-start justify-content-lg-center justify-content-xl-center mt-3 mt-lg-0 mt-xl-0">
                                            <label className="listar-distrito-vaga text-center ">
                                                <ImLocation className="mb-1 listar-data-icon text-warning" />
                                                {dado.distrito}
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-6 col-lg-3 col-xl-3 mt-md-4 listar-data-vaga 
                                                d-flex align-items-center justify-content-lg-center justify-content-xl-center justify-content-md-start
                                                 mb-lg-3 mx-3 mx-lg-0 mx-xl-0 mb-1 mb-lg-0 mb-xl-0 mt-1 mt-lg-0 mt-xl-0">
                                            <BtnTipoVaga
                                                id_tipovaga={dado.id_tipovaga}
                                                nome_tipovaga={dado.tipovaga.nometipovaga}
                                                
            
                                            />
                                       {/*
                                        <div className="col-12 mt-2">
                                            <strong>
                                                <FaCalendarAlt className="mb-1 mx-1 listar-data-icon text-success" />
                                                {
                                                    dado.createdAt.substring(0, 10)
                                                }

                                            </strong></div>
                                            */}
                                </div>
                            </div>
                        </div>
                    </Link>)
                ))
            }
        </>

    );
}

export default ListagemVaga