import React from "react"
import { Link } from "react-router-dom";

import "./listagem.css"


import { ImLocation } from "react-icons/im";
import { FaCalendarAlt } from "react-icons/fa";

function Listagem({ data }) {

    const url = process.env.REACT_APP_APP_URL
    return (
        <>
            {
                data.map((dado) => (

                    {
                        ...dado.id_tipoutilizador === 1
                            ?

                            (<Link to={`/perfil/empresa/visualizar/${dado.id}`} key={dado.id}>

                                <div key={dado.id} className="shadow mb-4 col-12 mt-5">

                                    <div className="row listar-info d-flex align-items-center justify-content-center py-3 px-2">

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-row align-items-center justify-content-start mb-sm-3 mb-3 mb-lg-0 mb-xl-0">

                                            <div className="row col-12">
                                                <div className="col-xl-3 col-lg-3 col-12 d-flex align-items-center justify-content-start justify-content-lg-center ">
                                                    <span className="listar-foto mx-2 mx-xl-0 mx-lg-0 mb-3 mb-xl-0">
                                                    {dado.foto ? (
                                                        <img alt="foto-perfil" src={dado.foto} />
                                                    ) :
                                                        <img alt="foto" className="visualizar-user-details-imgAlt" src={`${url}/files/user.png`} />
                                                    }
                                                        
                                                    </span>
                                                </div>
                                                <div className="col-xl-9 col-lg-9 col-12 d-flex align-items-center justify-content-start justify-content-lg-center justify-content-xl-start ">
                                                    <label className="listar-nome mx-2 mx-xl-0 mx-lg-0 ">
                                                        {dado.nome}
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 d-flex align-items-center justify-content-start justify-content-xl-center mb-sm-3 mb-3 mb-lg-0 mb-xl-0 texto_inicio">
                                            <div className="row d-flex flex-column  align-items-center justify-content-start justify-content-xl-center">
                                                <div className="col-12 d-flex align-items-center justify-content-start justify-content-xl-center">
                                                    <label className="listar-formacao text-start mx-2 mx-xl-0 mb-3 mb-xl-0">
                                                        {
                                                            dado.id_areaempresa
                                                            &&
                                                            dado.areaempresa.nomeareaempresa
                                                          
                                                        }
                                                    </label>
                                                </div>
                                                <div className="col-12 d-flex align-items-center justify-content-start justify-content-xl-center">
                                                    <label className="listar-distrito justify-content-start justify-content-xl-center mx-1"> 
                                                        {dado.distrito && (
                                                            <ImLocation className="mb-1 me-1 listar-data-icon text-warning" />   
                                                        )}
                                                        {dado.distrito}
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 listar-data-vaga d-flex align-items-center justify-content-start justify-content-xl-end justify-content-lg-end  mb-1  mb-lg-0 mb-xl-0">
                                            <strong className='d-flex align-items-center justify-content-start justify-content-xl-end justify-content-lg-end mx-2 mx-xl-0 '>
                                                <FaCalendarAlt className="me-1 listar-data-icon text-success d-flex align-items-center justify-content-start justify-content-xl-end justify-content-lg-end " />
                                                {
                                                    dado.createdAt.substring(0, 10)
                                                }
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </Link>)
                            :

                            (
                                <Link to={`/perfil/vizualizar/${dado.id}`} key={dado.id}>

                                    <div key={dado.id} className="shadow mb-4 col-12 mt-5">

                                        <div className="row listar-info d-flex align-items-center justify-content-center py-3">

                                            <div className="col-12 col-lg-4 col-xl-4 d-flex flex-row align-items-center justify-content-start mb-sm-3 mb-3 mb-lg-0 mb-xl-0">

                                                <div className="row col-12 ">
                                                    <div className="col-xl-3 col-lg-3 col-12 d-flex align-items-center justify-content-start">
                                                        <span className="listar-foto d-flex align-items-center justify-content-start mx-3 mx-xl-0 mx-lg-0 mb-3 mb-xl-0 mb-lg-0">
                                                        {dado.foto ? (
                                                            <img alt="foto-perfil" src={dado.foto} />
                                                        ) :
                                                        (<img alt="foto" className="" src={`${url}/files/user.png`} />)
                                                        }
                                                            
                                                        </span>
                                                    </div>
                                                    <div className="col-xl-9 col-lg-9 col-12 d-flex align-items-center justify-content-start">
                                                        <label className="listar-nome mx-3 mx-xl-0 mx-lg-0">
                                                            {dado.nome} {dado.apelido}
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-lg-5 col-xl-5 d-flex align-items-center justify-content-start justify-content-xl-center justify-content-lg-center mb-2 mb-lg-0 mb-xl-0">
                                                <div className="row flex-column d-flex align-items-center justify-content-start justify-content-xl-center justify-content-lg-center">
                                                    <div className="col-12 d-flex align-items-center justify-content-start justify-content-xl-center justify-content-lg-center">
                                                        <label className="listar-formacao mx-3 mx-xl-0 mx-lg-0">
                                                            {dado.formacao}
                                                        </label>
                                                    </div>
                                                    <div className="col-12 d-flex align-items-center justify-content-start justify-content-xl-center justify-content-lg-center mt-3 mt-lg-0 mt-xl-0">
                                                        <label className="listar-distrito text-center mx-3 mx-xl-0 mx-lg-0">
                                                        {dado.distrito && (
                                                            <ImLocation className="mb-1 listar-data-icon text-warning" />   
                                                        )}
                                                            {dado.distrito}
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-lg-2 col-xl-2  listar-data-vaga d-flex align-items-center justify-content-start justify-content-xl-center justify-content-lg-center mb-1 mb-lg-0 mb-xl-0">
                                                <strong className='mx-3 mx-xl-0 mx-lg-0'>
                                                    <FaCalendarAlt className="mb-1 mx-1 listar-data-icon text-success" />
                                                    {
                                                        dado.createdAt.substring(0, 10)
                                                    }

                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                    }


                ))
            }
        </>

    );
}

export default Listagem