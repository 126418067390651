import React, { useState, useEffect } from "react"

import { RiSearch2Line } from "react-icons/ri"
import { FaRedo } from "react-icons/fa"
//import { Link } from "react-router-dom";


/*
    ====React paginate=====
    URL: https://www.npmjs.com/package/react-paginate
    
*/

import ReactPaginate from "react-paginate";

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "./pesquisarperfilempresa.css"

import Nav from "../../../../components/Layout/Navbar/Nav";
import Footer from "../../../../components/Layout/Footer/Footer";

import Api from "../../../../Api";
import { header } from "../../../../config/header";

import Listagem from "../../../../components/Listagem/Geral/Listagem";
import Input from "../../../../components/Form/Input/input";


import Load from "../../../../components/Layout/Load/Load";


function PesquisarPerfilEmpresa() {
    const [loadState, setLoadState] = useState(true)
    const [empresas, setEmpresas] = useState([])
    const [areaEmpresa, setAreaEmpresa] = useState([]);
    const [tipoEmpresa, setTipoEmpresa] = useState([]);


    const [searchName, setSearchName] = useState({
        name: ""
    })
    const [searchD, setSearchD] = useState({
        distrito: ""
    })
    const [searchT, setSearchT] = useState({
        id_tipoempresa: ""
    })
    const [searchA, setSearchA] = useState({
        id_areaempresa: ""
    })
    /*
        function handleChangeSelect(e) {
    
            setPerfil({ ...perfil, [e.target.name]: e.target.value });
            //console.log(e.target.value)  
        }
    */
    function handleChangeName(e) {
        setSearchName({ ...searchName, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }

    function handleChangeD(e) {
        setSearchD({ ...searchD, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }

    function handleChangeA(e) {
        setSearchA({ ...searchA, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }
    function handleChangeT(e) {
        setSearchT({ ...searchT, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }


    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(empresas.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(empresas.length / itemsPerPage));

    }, [itemOffset, itemsPerPage, empresas])


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % empresas.length;
        setItemOffset(newOffset);
    };


    async function getEmpresas() {
        setLoadState(true)
        Api.get("utilizadores/empresas", header).then(response => {
            setEmpresas(response.data)
            setLoadState(false);
            console.log(response.data)
        }).catch(erro => {
            console.log("Houve um erro: ", erro);
            setLoadState(false);
        })
    }

    useEffect(() => {
        getEmpresas()
    }, [])

    async function getAreaEmpresa() {
        setLoadState(true);
        Api.get("areasempresas", header).then(response => {
            setLoadState(false);
            setAreaEmpresa(response.data)
            //.log(response.data)
        }).catch(error => {
            console.log("Houve um erro: ", error);
            setLoadState(false);
        })
    }
    useEffect(() => {
        getAreaEmpresa()
    }, [])

    async function getTipoEmpresa() {
        Api.get("tiposempresas", header).then(response => {
            //console.log(response.data)
            setTipoEmpresa(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })
    }

    useEffect(() => {
        getTipoEmpresa();
    }, [])


    //========= Alertas ===========

    const notify = (msg) => {
        toast.success(msg)
    }

    const notifyInfo = (msg) => {
        toast.info(`${msg}.`)
    }


    const notifyError = (msg) => {
        toast.error(`${msg}.`)
    }

    //============================

    /*========================================= */

    async function pesquisarNome() {
        setLoadState(true)
        let dist = "gvgvv"
        let idtipo = 0
        let idarea = 0

        if (searchName.name === undefined || searchName.name === null || searchName.name === "") {
            notifyInfo("Digite um nome");
            setLoadState(false);
        } else {
            await Api.get(`empresas/seachcompanies/${searchName.name}/${dist}/${idarea}/${idtipo}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhuma Enpresa com a especificação desejada!") {
                    notifyInfo("Empresa não encontrado");
                    setSearchName({
                        name: ""
                    })
                    setSearchA({
                        id_areaempresa: ""
                    })
                    setSearchT({
                        id_tipoempresa: ""
                    })
                    setSearchD({
                        distrito: ""
                    })
                    setLoadState(false)
                } else {
                    setEmpresas(response.data)
                    notify(`Foi encontrado ${response.data.length} empresa(s)`)
                    setSearchName({
                        name: ""
                    })
                    setSearchA({
                        id_areaempresa: ""
                    })
                    setSearchT({
                        id_tipoempresa: ""
                    })
                    setSearchD({
                        distrito: ""
                    })
                    console.log("Empresas pesquisadas pelo nome: ", response.data)
                    setLoadState(false)
                }

            }).catch(error => {
                console.log("Errou ao pesquisar empresa pelo nome: ", error)
                setLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        }

    }

    /*================== Pesqisar p+or Distrito ==================== */

    async function pesquisarPorDistrtrito() {
        setLoadState(true)
        let idtipo = 0
        let idarea = 0
        let name = "****"

        if (searchD.distrito !== "") {

            await Api.get(`empresas/seachcompanies/${name}/${searchD.distrito}/${idarea}/${idtipo}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhuma Empresa com a especificação desejada!") {
                    notifyInfo("Nenhuma empresa encontrada");
                    console.log("Empresas pesquisadas por distrito: ", response.data)
                    //getCanddatos();
                    setLoadState(false)
                    setEmpresas([]);
                    setSearchA({
                        id_areaempresa: ""
                    })
                    setSearchT({
                        id_tipoempresa: ""
                    })
                } else {
                    setEmpresas(response.data)
                    notify(`Foi encontrado ${response.data.length} empresa(s)`)
                    console.log("Empresas pesquisadas por distrito: ", response.data)
                    setLoadState(false)
                    setSearchA({
                        id_areaempresa: ""
                    })
                    setSearchT({
                        id_tipoempresa: ""
                    })
                }

            }).catch(error => {
                console.log("Erro ao pesquisar empresas por distrito: ", error)
                setLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        } else {
            setLoadState(false);
        }

    }

    useEffect(() => {
        pesquisarPorDistrtrito()
    }, [searchD.distrito])

    /*========================================= */


    /*================== Pesqisar por Área ==================== */

    async function pesquisarPorArea() {
        setLoadState(true)
        let idtipo = 0
        let distrito = "*****"
        let name = "****"

        if (searchA.id_areaempresa !== "") {

            await Api.get(`empresas/seachcompanies/${name}/${distrito}/${searchA.id_areaempresa}/${idtipo}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhuma Empresa com a especificação desejada!") {
                    notifyInfo("Nenhuma empresa encontrada");
                    console.log("Empresas pesquisadas por área: ", response.data)
                    setLoadState(false)
                    setEmpresas([]);
                    setSearchD({
                        distrito: ""
                    })
                    setSearchT({
                        id_tipoempresa: ""
                    })
                } else {
                    setEmpresas(response.data)
                    notify(`Foi encontrado ${response.data.length} empresa(s)`)
                    console.log("Empresas pesquisadas por área: ", response.data)
                    setLoadState(false)
                    setSearchD({
                        distrito: ""
                    })
                    setSearchT({
                        id_tipoempresa: ""
                    })
                }

            }).catch(error => {
                console.log("Erro ao pesquisar empresas por tipo: ", error)
                setLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        } else {
            setLoadState(false);
        }

    }

    useEffect(() => {
        pesquisarPorArea()
    }, [searchA.id_areaempresa])

    /*========================================= */

    /*================== Pesqisar por Área ==================== */

    async function pesquisarPorTipo() {
        setLoadState(true)
        let idArea = 0
        let distrito = "****"
        let name = "****"

        if (searchT.id_tipoempresa !== "") {

            await Api.get(`empresas/seachcompanies/${name}/${distrito}/${0}/${1}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhuma Empresa com a especificação desejada!") {
                    notifyInfo("Nenhuma empresa encontrada");
                    console.log("Empresas pesquisadas por tipo: ", response.data)
                    setLoadState(false)
                    setEmpresas([]);
                    setSearchD({
                        distrito: ""
                    })
                    setSearchA({
                        id_tipoempresa: ""
                    })
                } else {
                    setEmpresas(response.data)
                    notify(`Foi encontrado ${response.data.length} empresa(s)`)
                    console.log("Empresas pesquisadas por tipo: ", response.data)
                    setLoadState(false)
                    setSearchD({
                        distrito: ""
                    })
                    setSearchA({
                        id_tipoempresa: ""
                    })
                }

            }).catch(error => {
                console.log("Erro ao pesquisar empresas por tipo: ", error)
                setLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        } else {
            setLoadState(false);
        }

    }

    useEffect(() => {
        pesquisarPorTipo()
    }, [searchT.id_tipoempresa])

    /*========================================= */


    return (
        <>
            <ToastContainer />
            <Nav navClassName={"nav-home"} />
            <div className="perquisar-perfil-empresa-container col-12">

                <h3 className="text-center text-light mb-5">Pesquisar Empresas</h3>

                <div className="perquisar-perfil-empresa-body col-12 d-flex justify-content-center">
                    <div className="col-10">
                        <div className="row">
                            <div className="col-xl-4 col-12 d-flex align-items-start">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <span className="text-start mb-4 redifinir-pesquisa"><FaRedo onClick={getEmpresas} className="fs-6 pesquisar-perfil-icon" /> </span>
                                            <div className="col-10">
                                                <label className="mb-2">Pesquisar por Nome</label>
                                                <Input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="form-control form-control-user pesquisar-perfil-porNome"
                                                    placeholder="Pesquisar por Nome"
                                                    value={searchName.name ? searchName.name : ""}
                                                    handleOnChange={handleChangeName}
                                                />
                                            </div>
                                            <div className="col-2 d-flex align-items-center mt-4">
                                                <RiSearch2Line className="pesquisar-perfil-empresa-icon" title="Procurar" onClick={pesquisarNome} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <label className="mb-2">Pesquisar por Distrito</label>
                                        <select
                                            title="Informe o seu Distrito"
                                            className="form-select pesquisar-perfil-empresa-porDistrito"
                                            name="distrito"
                                            id="pesquisar-perfil-distrito"
                                            value={searchD.distrito ? searchD.distrito : ""}
                                            onChange={handleChangeD}
                                        >
                                            <option value="" selected>(Pesquisar por Distrito)</option>
                                            <option value="Água Grande">Água Grande</option>
                                            <option value="Mé-Zochi">Mé-Zochi</option>
                                            <option value="Cantagalo">Cantagalo</option>
                                            <option value="Lobata">Lobata</option>
                                            <option value="Caué">Caué</option>
                                            <option value="Lembá">Lembá</option>
                                            <option value="Região Autónoma do Príncipe">Região Autónoma do Príncipe</option>
                                        </select>
                                    </div>
                                    {/*====================== */}
                                    <div className="col-12 mt-4">
                                        <label className="mb-2">Pesquisar Área de Empresas</label>
                                        <select
                                            title="Pesquise por área de empresas"
                                            className="form-select pesquisar-perfil-area-empresa"
                                            name="id_areaempresa"
                                            id="pesquisar_perfil_area_empresa"
                                            value={searchA.id_areaempresa ? searchA.id_areaempresa : ""}
                                            onChange={handleChangeA}
                                        >
                                            <option value="" selected>(Selecionar Área)</option>
                                            {
                                                areaEmpresa.map((area) => (
                                                    <option value={area.id} key={area.id} >
                                                        {area.nomeareaempresa}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {/*====================== */}
                                    <div className="col-12 mt-4">
                                        <label className="mb-2">Pesquisar por Tipo de Empresas</label>
                                        <select
                                            title="Pesquise pelo tipo de empresas"
                                            className="form-select pesquisar-perfil-tipo-empresa"
                                            name="id_tipoempresa"
                                            id="pesquisar_perfil_tipo_empres"
                                            value={searchT.id_tipoempresa ? searchT.id_tipoempresa : ""}
                                            onChange={handleChangeT}
                                        >
                                            <option value="" selected>(Selecionar Tipo)</option>
                                            {
                                                tipoEmpresa.map((tipo) => (
                                                    <option value={tipo.id} key={tipo.id} >
                                                        {tipo.nometipoempresa}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {loadState === true
                                ?
                                (<div className="col-xl-8 col-12 d-flex justify-content-center align-items-center container-load"><Load /></div>)
                                :
                                (
                                    {
                                        ...empresas === []
                                            ?
                                            (<p>Nenhum resultado</p>)
                                            :
                                            (

                                                <div className="col-xl-8 col-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-6 mt-xl-0 mtlg-0 mt-4 ">
                                                                    <span>Total de {empresas.length} Empresas</span>
                                                                </div>

                                                                <div className="col-6 text-end">
                                                                    <div className="row">

                                                                        {/* 

                                                            <div className="col-12">
                                                                <span className="text-end">Ondenar por:</span>
                                                            </div>
                                                            <div className="col-3">
                                                                <select
                                                                    title="Informe a quanditade de itens a ser listado"
                                                                    className="form-select pesquisar-perfil-quantview"
                                                                    name="itemsPerPage"
                                                                    id="itemsPerPage"
                                                                    value={itemsPerPage}
                                                                    onChange={handleChangeSelect2}
                                                                >
                                                                    <option selected value={5}>5</option>
                                                                    <option value={7}>7</option>
                                                                    <option value={10}>10</option>
                                                                    <option value={12}>12</option>
                                                                    <option value={15}>15</option>
                                                                </select>
                                                            </div>
                                                            */}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <Listagem
                                                                data={currentItems}
                                                            />
                                                        </div>
                                                    </div>
                                                    <ReactPaginate
                                                        previousLabel={"Anterior"}
                                                        nextLabel={"Próximo"}
                                                        breakLabel={"..."}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination justify-content-center mt-4 mb-5"}
                                                        pageClassName={"page-item"}
                                                        pageLinkClassName={"page-link"}
                                                        previousClassName={"page-item"}
                                                        previousLinkClassName={"page-link"}
                                                        nextClassName={"page-item"}
                                                        nextLinkClassName={"page-link"}
                                                        breakClassName={"page-item"}
                                                        breakLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                </div>
                                            )
                                    }


                                )
                            }

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default PesquisarPerfilEmpresa