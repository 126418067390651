import React, { useState, useEffect,useContext } from 'react'
import { useParams} from 'react-router-dom'
import ReactPaginate from "react-paginate";

import './listarcandidatos.css'

import Api from '../../../../Api';
import { header } from '../../../../config/header';
import StoreContext from '../../../../config/Store/Context';

import Nav from '../../../../components/Layout/Navbar/Nav';
import Footer from '../../../../components/Layout/Footer/Footer';

import Load from '../../../../components/Layout/Load/Load';

import Listagem from '../../../../components/Listagem/Geral/Listagem';

function ListarCandidatos() {
    
  const idVaga = useParams().id

      //Dados do Utilizador Logado
      const { user } = useContext(StoreContext)

    const [candidatos, setCandidatos] = useState([])

    const [loadSate, setLoadSate] = useState(true)

    //Paginação
    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(candidatos.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(candidatos.length / itemsPerPage));

    }, [itemOffset, itemsPerPage, candidatos])


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % candidatos.length;
        setItemOffset(newOffset);
    };

//=========================

    async function getCandidatos() {

        await Api.get(`candidatos/listagemcandidatos/${idVaga}`, header).then(response => {
            if(response.data){
                setCandidatos(response.data)
            }
            console.log(response)
            setLoadSate(false)
        }).catch(err => {
            console.log("Houve um erro ao tentar listar os candidatos:", err)
            setLoadSate(false)
        })
    }
    useEffect(()=>{
        getCandidatos()
    }, [])

    return (
        <>
            <Nav navClassName={"nav-home"}/>

            {loadSate === true 
                                ?
                                (<div className="container listar-candidatos-container col-10 d-flex justify-content-center align-items-center"><Load /></div>)
                                :
                                (
                                    {
                                        ...candidatos.length === 0

                                          ?
                            
                                          <div className="container listar-candidatos-container d-flex flex-column align-items-center col-xl-10 col-lg-10 col-md-10 mb-5">
                            
                                            <h3 className='text-center mt-3 mb-5'>Meus Anúncios Criados</h3>
                            
                                            <p>Nenhuma anúncio encontrado</p>
                            
                                          </div>
                            
                                          :(

                                    <div className=" container listar-candidatos-container col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <span>Total de {candidatos.length} Candidatos</span>
                                                    </div>

                                                    <div className="col-6 text-end">
                                                        <div className="row">

                                                            {/* 

                                                            <div className="col-12">
                                                                <span className="text-end">Ondenar por:</span>
                                                            </div>
                                                            <div className="col-3">
                                                                <select
                                                                    title="Informe a quanditade de itens a ser listado"
                                                                    className="form-select pesquisar-perfil-quantview"
                                                                    name="itemsPerPage"
                                                                    id="itemsPerPage"
                                                                    value={itemsPerPage}
                                                                    onChange={handleChangeSelect2}
                                                                >
                                                                    <option selected value={5}>5</option>
                                                                    <option value={7}>7</option>
                                                                    <option value={10}>10</option>
                                                                    <option value={12}>12</option>
                                                                    <option value={15}>15</option>
                                                                </select>
                                                            </div>
                                                            */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <Listagem
                                                    data={currentItems}
                                                />
                                            </div>
                                        </div>
                                        <ReactPaginate
                                            previousLabel={"Anterior"}
                                            nextLabel={"Próximo"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination justify-content-center mt-4 mb-5"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                )})
                            }
            <Footer />
        </>
    );
}


export default ListarCandidatos