import React from 'react'


function Textarea({placeholder,id,className,name,handleOnChange, value, title, cols,
rows}){

    return(
        <textarea
        placeholder={placeholder} 
        name={name} 
        id={id} 
        title={title}
        className={className} 
        value={value}
        onChange={handleOnChange}
        rows={rows}
        cols={cols}
        />
    );
}

export default Textarea