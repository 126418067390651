import React, { useState, useEffect } from "react"

import Swal from "sweetalert2"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Api from '../../../../Api'
import './items.css'

import DashNav from "../../../DashNav/DashNav";
import Dashsidebar from "../../../Dashsidebar/Dashsidebar";
import DashFooter from "../../../DashFooter/DashFooter";


import Input from "../../../../components/Form/Input/input";
import Button from "../../../../components/Form/Button/Button";

function Items() {

    const header = process.env.REACT_APP_ACCESS_KEY

    const notify = (msg) => {
        toast.success(`Item ${msg} com Sucesso!`)
    }

    const notifyInfo = (msg) => {
        toast.info(`${msg} !`)
    }

    const notifyError = (msg) => {
        toast.error(`${msg} !`)
    }
    /*
        const [tipoUtilizador, setTipoUtilizador] = useState([])
        const [newTipoUtilizador, setNewTipoUilizador] = useState({
            nometipoutilizador: ""
        })
        const [novoTipoUtilizador, setNovoTipoUtilizador] = useState({
            id_tipoutilizador: ""
        })
    */
    const [sexo, setSexo] = useState([])
    const [newSexo, setNewSexo] = useState({
        nomesexo: ""
    })
    const [novoSexo, setNovoSexo] = useState({
        id_sexo: ""
    })


    const [estadoCivil, setEstadoCivil] = useState([])
    const [newEstadoCivil, setNewEstadoCivil] = useState({
        nomeestadocivil: ""
    })
    const [novoEstadoCivil, setNovoEstadoCivil] = useState({
        id_estadocivil: ""
    })


    const [nivelAcesso, setNivelAcesso] = useState([])
    const [newNivelAcesso, setNewNivelAcesso] = useState({
        nomenivelacesso: ""
    })
    const [novoNivelAcesso, setNovoNivelAcesso] = useState({
        id_nivelacesso: ""
    })


    const [grauAcademico, setGrauAcademico] = useState([])
    const [newGrauAcademico, setNewGrauAcademico] = useState({
        nomegrauacademico: ""
    })
    const [novoGrauAcademico, setNovoGrauAcademico] = useState({
        id_grauacademico: ""
    })


    const [tipoEmpresa, setTipoEmpresa] = useState([])
    const [newTipoEmpresa, setNewTipoEmpresa] = useState({
        nometipoempresa: ""
    })
    const [novoTipoEmpresa, setNovoTipoEmpresa] = useState({
        id_tipoempresa: ""
    })


    const [areaEmpresa, setAreaEmpresa] = useState([])
    const [newAreaEmpresa, setNewAreaEmpresa] = useState({
        nomeareaempresa: ""
    })
    const [novoAreaEmpresa, setNovoAreaEmpresa] = useState({
        id_areaempresa: ""
    })

    const [areaprofissional, setAreaProfissional] = useState([])
    const [newAreaProfissional, setNewAreaProfissional] = useState({
        nomeareaprofissional: ""
    })
    const [novoAreaProfissional, setNovoAreaProfissional] = useState({
        id_areaprofissional: ""
    })


    const [tipoVaga, setTipoVaga] = useState([])
    const [newTipoVaga, setNewTipoVaga] = useState({
        nometipovaga: ""
    })
    const [novoTipoVaga, setNovoTipoVaga] = useState({
        id_tipovaga: ""
    })

    const [categoriaVaga, setCategoriaVaga] = useState([])
    const [newCategoriaVaga, setNewCategoriaVaga] = useState({
        nomecategoriavaga: ""
    })
    const [novoCategoriaVaga, setNovoCategoriaVaga] = useState({
        id_categoriavaga: ""
    })

    //=================Funções Get =================
    async function getSexo() {

        Api.get("sexos", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setSexo(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getSexo()
    }, [])

    async function getEstadoCivil() {

        Api.get("estadoscivis", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setEstadoCivil(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getEstadoCivil()
    }, [])


    async function getNivelAcesso() {

        Api.get("nivelacessos", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setNivelAcesso(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getNivelAcesso();
    }, [])

    async function getGrauAcademico() {

        Api.get("grausacademicos", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setGrauAcademico(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getGrauAcademico();
    }, [])


    async function getTipoEmpresa() {

        Api.get("tiposempresas", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setTipoEmpresa(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getTipoEmpresa();
    }, [])

    async function getAreaEmpresa() {

        Api.get("areasempresas", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setAreaEmpresa(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getAreaEmpresa();
    }, [])

    async function getAreaProfissional() {

        Api.get("areasprofissionais/getAll",{
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                //console.log(response.data)
                setAreaProfissional(response.data)
                console.log(response.data)
            }).catch(erro => {
                console.log("Erro: ", erro)
            })

    }

    useEffect(() => {
        getAreaProfissional();
    }, [])

    async function getTipoVaga() {

        Api.get("tiposvagas", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setTipoVaga(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getTipoVaga();
    }, [])


    async function getCategoriaVaga() {

        Api.get("categoriasvagas", {
            headers: {
                'authorization': header,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            //console.log(response.data)
            setCategoriaVaga(response.data)
        }).catch(erro => {
            console.log("Erro: ", erro)
        })

    }

    useEffect(() => {
        getCategoriaVaga();
    }, [])
    /*
        async function getTipoUtilizador() {
    
            Api.get("categoriasvagas").then(response => {
                //console.log(response.data)
                setTipoUtilizador(response.data)
            }).catch(erro => {
                console.log("Erro: ", erro)
            })
    
        }
    
        useEffect(() => {
            //getTipoUtilizador();
        }, [])
    
        */
    //=================FIM Funções Get ==================


    //=============Funções Add============
    async function addSexo() {
        if (newSexo.nomesexo.length > 0) {
            Api.post("sexos", newSexo, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                setNewSexo(" ")
                notify("Adicionado")
            }).catch(erro => {
                notifyError("Erro ao adiconar sexo!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }

    }

    async function addEstadoCivil() {
        if (newEstadoCivil.nomeestadocivil.length > 0) {
            Api.post("estadoscivis", newEstadoCivil, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                setNewEstadoCivil(" ")
                notify("Adicionado")
            }).catch(erro => {
                notifyError("Erro ao adiconar Estado Civil!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }

    }

    async function addNivelAcesso() {
        if (newNivelAcesso.nomenivelacesso.length > 0) {
            Api.post("nivelacessos", newNivelAcesso, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Adicionado")
                setNewNivelAcesso(" ")
            }).catch(erro => {
                notifyError("Erro ao adiconar nível de acesso!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }

    }

    async function addGrauAcademico() {
        if (newGrauAcademico.nomegrauacademico.length > 0) {
            Api.post("grausacademicos", newGrauAcademico, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Adicionado")
                setNewGrauAcademico(" ")
            }).catch(erro => {
                notifyError("Erro ao adiconar grau acadêmico!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }
    }

    async function addTipoEmpresa() {
        if (newTipoEmpresa.nometipoempresa.length > 0) {
            Api.post("tiposempresas", newTipoEmpresa, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Adicionado")
                setNewTipoEmpresa(" ")
            }).catch(erro => {
                notifyError("Erro ao adiconar Tipo de Empresa!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }
    }

    async function addAreaEmpresa() {
        if (newAreaEmpresa.nomeareaempresa.length > 0) {
            Api.post("areasempresas", newAreaEmpresa, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                // Swal.fire('Área de Empresa adicionado com sucesso!')
                //alert("Área de Empresa adicionado com sucesso!")
                notify("Adicionado")
                setNewAreaEmpresa(" ")
            }).catch(erro => {
                notifyError("Erro ao adiconar Área de Empresa!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }
    }

    async function addAreaProfissional() {
        if (newAreaProfissional.nomeareaprofissional.length > 0) {
            Api.post("areasprofissionais/create", newAreaProfissional, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                // Swal.fire('Área de Empresa adicionado com sucesso!')
                //alert("Área de Empresa adicionado com sucesso!")
                notify("Adicionado")
                setNewAreaEmpresa(" ")
            }).catch(erro => {
                notifyError("Erro ao adiconar Área Profissional!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }
    }


    async function addTipoVaga() {
        if (newTipoVaga.nometipovaga.length > 0) {
            Api.post("tiposvagas", newTipoVaga, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Adicionado")
                setNewTipoVaga(" ")
            }).catch(erro => {
                notifyError("Erro ao adiconar Tipo de Vaga!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }
    }

    async function addCategoriaVaga() {
        if (newCategoriaVaga.nomecategoriavaga.length > 0) {
            Api.post("categoriasvagas", newCategoriaVaga, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                setNewCategoriaVaga(" ")
                notify("Adicionado")
            }).catch(erro => {
                notifyError("Erro ao adiconar Categoria de Vaga!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do item que deseja inserir!");
        }
    }
    //===============FIM==============

    //=============Funções Update============
    async function updateSexo() {
        if (novoSexo.id_sexo > 0 && newSexo.nomesexo.length > 0) {

            Api.post(`sexos/${novoSexo.id_sexo}`, newSexo, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar sexo!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do elemento que deseja atualizar!")
        }

    }

    async function updateEsatdoCivil() {
        if (newEstadoCivil.nomeestadocivil.length > 0 && novoEstadoCivil.id_estadocivil > 0) {
            Api.post(`estadoscivis/${novoEstadoCivil.id_estadocivil}`, newEstadoCivil, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar Estado Civil!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nomde do elemento que deseja atualizar!")
        }

    }

    async function updateNivelAcesso() {
        if (newNivelAcesso.nomenivelacesso.length > 0 && novoNivelAcesso.id_nivelacesso > 0) {
            Api.post(`nivelacessos/${novoNivelAcesso.id_nivelacesso}`, newNivelAcesso, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar Nível de acesso")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do elemento que deseja atualizar!")
        }

    }

    async function updateDrauAcademico() {
        if (newGrauAcademico.nomegrauacademico.length > 0 && novoGrauAcademico.id_grauacademico > 0) {
            Api.post(`grausacademicos/${novoGrauAcademico.id_grauacademico}`, newGrauAcademico, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar Grau Académico")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do elemento que deseja atualizar!")
        }

    }

    async function updateTipoEmpresa() {
        if (newTipoEmpresa.nometipoempresa.length > 0 && novoTipoEmpresa.id_tipoempresa > 0) {
            Api.post(`tiposempresas/${novoTipoEmpresa.id_tipoempresa}`, newTipoEmpresa, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar Tipo de Empresa")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nomde do elemento que deseja atualizar!")
        }

    }

    async function updateAreaEmpresa() {
        if (newAreaEmpresa.nomeareaempresa.length > 0 && novoAreaEmpresa.id_areaempresa > 0) {
            Api.post(`areasempresas/${novoAreaEmpresa.id_areaempresa}`, newAreaEmpresa, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar Área de Empresa")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do elemento que deseja atualizar!")
        }

    }

    async function updateAreaProfissional() {
        if (newAreaProfissional.nomeareaprofissional.length > 0 && novoAreaProfissional.id_areaprofissional > 0) {
            Api.post(`areasprofissionais/update/${novoAreaProfissional.id_areaprofissional}`, newAreaProfissional, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar Área de Profissional")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do elemento que deseja atualizar!")
        }

    }
    async function updateTipoVaga() {
        if (newTipoVaga.nometipovaga.length > 0 && novoTipoVaga.id_tipovaga > 0) {
            Api.post(`tiposvagas/${novoTipoVaga.id_tipovaga}`, newTipoVaga, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar Tipo de Vaga!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do elemento que deseja atualizar!")
        }

    }

    async function updateCategoriaVaga() {
        if (newCategoriaVaga.nomecategoriavaga.length > 0 && novoCategoriaVaga.id_categoriavaga > 0) {
            Api.post(`categoriasvagas/${novoCategoriaVaga.id_categoriavaga}`, newCategoriaVaga, {
                headers: {
                    'authorization': header,
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                //console.log(response)
                notify("Atualizado")
            }).catch(erro => {
                notifyError("Erro ao atualizar Categoria de Vaga!")
                console.log("Erro: ", erro)
            })
        } else {
            notifyInfo("Informe o nome do elemento que deseja atualizar!")
        }

    }
    //================FIM===============

    //=============Funções Delete============
    async function deleteSexo() {

        if (novoSexo.id_sexo.length > 0) {

            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apaga isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`sexos/${novoSexo.id_sexo}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Sexo.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })

                }
            })
        } else {
            alert("Informe o elemento que deseja eliminar!")
        }
    }

    async function deleteEstadoCivil() {
        if (novoEstadoCivil.id_estadocivil.length > 0) {

            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apaga isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`estadoscivis/${novoEstadoCivil.id_estadocivil}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Estaso Civil.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })

                }
            })

        } else {
            alert("Informe o elemento que deseja eliminar!")
        }

    }

    async function deleteNivelAcesso() {
        if (novoNivelAcesso.id_nivelacesso.length > 0) {

            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apaga isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`nivelacessos/${novoNivelAcesso.id_nivelacesso}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Nível de Acesso.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })

                }
            })

        } else {
            alert("Informe o elemento que deseja eliminar!")
        }

    }

    async function deleteGrauAcademico() {
        if (novoGrauAcademico.id_grauacademico.length > 0) {

            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apaga isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`grausacademicos/${novoGrauAcademico.id_grauacademico}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Grau Académico.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })

                }
            })

        } else {
            alert("Informe o elemento que deseja eliminar!")
        }

    }

    async function deleteTipoEmpresa() {
        if (novoTipoEmpresa.id_tipoempresa.length > 0) {

            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apaga isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`tiposempresas/${novoTipoEmpresa.id_tipoempresa}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Tipo de Empresa.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })

                }
            })
        } else {
            alert("Informe o elemento que deseja eliminar!")
        }

    }

    async function deleteAreaEmpresa() {
        if (novoAreaEmpresa.id_areaempresa.length > 0) {
            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apaga isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`areasempresas/${novoAreaEmpresa.id_areaempresa}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Área Empresa.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })

                }
            })
        } else {
            notifyInfo("Informe o elemento que deseja eliminar!")
        }

    }
    /*==========================*/
    async function deleteAreaProfissional() {
        if (novoAreaProfissional.id_areaprofissional.length > 0) {
            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apagar isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`areasprofissionais/delete/${novoAreaProfissional.id_areaprofissional}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Área Profissional.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })

                }
            })
        } else {
            notifyInfo("Informe o elemento que deseja eliminar!")
        }

    }
    /*=============================*/

    async function deleteTipoVaga() {
        if (novoTipoVaga.id_tipovaga.length > 0) {

            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apaga isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`tiposvagas/${novoTipoVaga.id_tipovaga}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Tipo de Vaga.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })
                }
            })

        } else {
            alert("Informe o elemento que deseja eliminar!")
        }

    }
    /*==========================*/
    async function deleteCategoriaVaga() {
        if (novoCategoriaVaga.id_categoriavaga.length > 0) {

            Swal.fire({
                title: 'Tens certeza?',
                text: "Não poderás reverter a sua escolha!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'sim, apaga isto!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.get(`categoriasvagas/${novoCategoriaVaga.id_categoriavaga}`, {
                        headers: {
                            'authorization': header,
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        //console.log(response)
                        Swal.fire(
                            'Apagado!',
                            'Seu item foi apagado.',
                            'success'
                        )
                    }).catch(erro => {
                        Swal.fire(
                            'Erro!',
                            'Erro ao eliminar Categoria de Vaga.',
                            'error'
                        )
                        console.log("Erro: ", erro)
                    })
                }
            })

        } else {
            notifyInfo("Informe o elemento que deseja eliminar!")
        }

    }


    //=================FIM===============

    //=============Funções Hndles============

    function handleChangeSexo(e) {
        setNewSexo({ ...newSexo, [e.target.name]: e.target.value });
    }
    function handleChangeEstadoCivil(e) {
        setNewEstadoCivil({ ...newEstadoCivil, [e.target.name]: e.target.value });
    }

    function handleChangeNivelAcesso(e) {
        setNewNivelAcesso({ ...newNivelAcesso, [e.target.name]: e.target.value });
    }

    function handleChangeGrauAcademico(e) {
        setNewGrauAcademico({ ...newGrauAcademico, [e.target.name]: e.target.value });
    }

    function handleChangeTipoEmpresa(e) {
        setNewTipoEmpresa({ ...newTipoEmpresa, [e.target.name]: e.target.value });
    }

    function handleChangeAreaEmpresa(e) {
        setNewAreaEmpresa({ ...newAreaEmpresa, [e.target.name]: e.target.value });
    }
    function handleChangeAreaProfissional(e) {
        setNewAreaProfissional({ ...newAreaProfissional, [e.target.name]: e.target.value });
    }

    function handleChangeTipoVaga(e) {
        setNewTipoVaga({ ...newTipoVaga, [e.target.name]: e.target.value });
    }

    function handleChangeCategoriaVaga(e) {
        setNewCategoriaVaga({ ...newCategoriaVaga, [e.target.name]: e.target.value });
    }


    //=================//


    function handleChangeNovoSexo(e) {
        setNovoSexo({ ...novoSexo, [e.target.name]: e.target.value });
    }
    function handleChangeNovoEstadoCivil(e) {
        setNovoEstadoCivil({ ...novoEstadoCivil, [e.target.name]: e.target.value });
    }
    function handleChangeNovoNivelAcesso(e) {
        setNovoNivelAcesso({ ...novoNivelAcesso, [e.target.name]: e.target.value });
    }
    function handleChangeNovoGrauAcademico(e) {
        setNovoGrauAcademico({ ...novoGrauAcademico, [e.target.name]: e.target.value });
    }
    function handleChangeNovoTipoEmpresa(e) {
        setNovoTipoEmpresa({ ...novoTipoEmpresa, [e.target.name]: e.target.value });
    }
    function handleChangeNovoAreaEmpresa(e) {
        setNovoAreaEmpresa({ ...novoAreaEmpresa, [e.target.name]: e.target.value });
    }
    function handleChangeNovoAreaProfissional(e) {
        setNovoAreaProfissional({ ...novoAreaProfissional, [e.target.name]: e.target.value });
    }
    function handleChangeNovoTipoVaga(e) {
        setNovoTipoVaga({ ...novoTipoVaga, [e.target.name]: e.target.value });
    }
    function handleChangeNovoTipoVaga(e) {
        setNovoTipoVaga({ ...novoTipoVaga, [e.target.name]: e.target.value });
    }
    function handleChangeNovoCategoriaVaga(e) {
        setNovoCategoriaVaga({ ...novoCategoriaVaga, [e.target.name]: e.target.value });
    }


    //===================FIM==================


    return (
        <>
            <ToastContainer />
            <div className="d-flex dash-altura" id="wrapper">
                <DashNav />
                <Dashsidebar />

                <div className="" id="page-content-wrapper">

                    <h3 className="text-center item-title">Adicionar Itens</h3>

                    <div className="items-container mb-5">

                        <div className="col-12 mb-4">

                            <div className="row item-matgin-top">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <label className="text-black">Item</label>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <label className="text-black">Listar</label>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <label className="text-black">Nome</label>
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <label className="text-black">Ação</label>
                                </div>

                            </div>
                        </div>
                        {/*====================================================================== */}

                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Sexo</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_sexo"
                                        id="id_sexo"
                                        className="form-select"
                                        value={novoSexo.id_sexo ? novoSexo.id_sexo : ""}
                                        onChange={handleChangeNovoSexo}
                                    >
                                        <option selected value=" ">
                                            (Sexos)
                                        </option>
                                        {sexo.map((sexo) => (
                                            <option value={sexo.id} key={sexo.id}>
                                                {sexo.nomesexo}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome do Sexo"
                                        id="input-id-sexo"
                                        title="Informe o nome do Sexo"
                                        className="form-control form-control-user"
                                        name="nomesexo"
                                        value={newSexo.nomesexo ? newSexo.nomesexo : ""}
                                        handleOnChange={handleChangeSexo}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addSexo}
                                                />

                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateSexo}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteSexo}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*==================================FIM==================================== */}

                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Estado Civil</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_estadocivil"
                                        id="id_estadocivil"
                                        className="form-select"
                                        value={novoEstadoCivil.id_estadocivil ? novoEstadoCivil.id_estadocivil : ""}
                                        onChange={handleChangeNovoEstadoCivil}
                                    >
                                        <option value="" selected>
                                            (Estado Civil)
                                        </option>
                                        {estadoCivil.map((estado) => (
                                            <option value={estado.id} key={estado.id}>
                                                {estado.nomeestadocivil}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome do Estado Civil"
                                        id="input-id-estadocivil"
                                        title="Informe o nome do EStado Civil"
                                        className="form-control form-control-user"
                                        name="nomeestadocivil"
                                        value={newEstadoCivil.nomeestadocivil ? newEstadoCivil.nomeestadocivil : ""}
                                        handleOnChange={handleChangeEstadoCivil}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addEstadoCivil}
                                                />

                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateEsatdoCivil}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteEstadoCivil}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*==================================FIM==================================== */}


                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Nível de Acesso</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_nivelacesso"
                                        id="id_nivelacesso"
                                        className="form-select"
                                        value={novoNivelAcesso.id_nivelacesso ? novoNivelAcesso.id_nivelacesso : ""}
                                        onChange={handleChangeNovoNivelAcesso}
                                    >
                                        <option value="" selected>
                                            (Nível de acesso)
                                        </option>
                                        {nivelAcesso.map((nivel) => (
                                            <option value={nivel.id} key={nivel.id}>
                                                {nivel.nomenivelacesso}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome do Nível de Acesso"
                                        id="input-nome-nivelacesso"
                                        title="Informe o nome do Tipo de Utilizador"
                                        className="form-control form-control-user"
                                        name="nomenivelacesso"
                                        value={newNivelAcesso.nomenivelacesso ? newNivelAcesso.nomenivelacesso : ""}
                                        handleOnChange={handleChangeNivelAcesso}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addNivelAcesso} />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateNivelAcesso}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteNivelAcesso}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*==================================FIM==================================== */}

                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Grau Acadêmico</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_grauacademico"
                                        id="id_grauacademico"
                                        className="form-select"
                                        value={novoGrauAcademico.id_grauacademico ? novoGrauAcademico.id_grauacademico : ""}
                                        onChange={handleChangeNovoGrauAcademico}
                                    >
                                        <option value="" selected>
                                            (Grau Acadêmico)
                                        </option>

                                        {grauAcademico.map((grau) => (
                                            <option value={grau.id} key={grau.id}>
                                                {grau.nomegrauacademico}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome do Grau Acadêmico"
                                        id="input-id-grauacademico"
                                        title="Informe o nome do Grau Acadêmico"
                                        className="form-control form-control-user"
                                        name="nomegrauacademico"
                                        value={newGrauAcademico.nomegrauacademico ? newGrauAcademico.nomegrauacademico : ""}
                                        handleOnChange={handleChangeGrauAcademico}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addGrauAcademico} />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateDrauAcademico}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteGrauAcademico}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*==================================FIM==================================== */}

                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Tipo de Empresa</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_tipoempresa"
                                        id="id_tipoempresa"
                                        className="form-select"
                                        value={novoTipoEmpresa.id_tipoempresa ? novoTipoEmpresa.id_tipoempresa : ""}
                                        onChange={handleChangeNovoTipoEmpresa}
                                    >
                                        <option value="" selected>
                                            (Tipo de Empresa)
                                        </option>

                                        {tipoEmpresa.map((tipo) => (
                                            <option value={tipo.id} key={tipo.id}>
                                                {tipo.nometipoempresa}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome do Tipo de Empresa"
                                        id="input-nome-tipoempresa"
                                        title="Informe o nome do Tipo de Empresa"
                                        className="form-control form-control-user"
                                        name="nometipoempresa"
                                        value={newTipoEmpresa.nometipoempresa ? newTipoEmpresa.nometipoempresa : ""}
                                        handleOnChange={handleChangeTipoEmpresa}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addTipoEmpresa} />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateTipoEmpresa}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteTipoEmpresa}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*==================================FIM==================================== */}


                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Área de Empresa</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_areaempresa"
                                        id="id_areaempresa"
                                        className="form-select"
                                        value={novoAreaEmpresa.id_areaempresa ? novoAreaEmpresa.id_areaempresa : ""}
                                        onChange={handleChangeNovoAreaEmpresa}
                                    >
                                        <option value="" selected>
                                            (Área de Empresa)
                                        </option>

                                        {areaEmpresa.map((area) => (
                                            <option value={area.id} key={area.id}>
                                                {area.nomeareaempresa}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome da Área de Empresa"
                                        id="input-nome-areaempresa"
                                        title="Informe o nome da Área de Empresa"
                                        className="form-control form-control-user"
                                        name="nomeareaempresa"
                                        value={newAreaEmpresa.nomeareaempresa ? newAreaEmpresa.nomeareaempresa : ""}
                                        handleOnChange={handleChangeAreaEmpresa}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addAreaEmpresa} />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateAreaEmpresa}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteAreaEmpresa}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        {/*==================================FIM==================================== */}


                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Área Profissional</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_areaprofissional"
                                        id="id_areaprofissional"
                                        className="form-select"
                                        value={novoAreaProfissional.id_areaprofissional ? novoAreaProfissional.id_areaprofissional : ""}
                                        onChange={handleChangeNovoAreaProfissional}
                                    >
                                        <option value="" selected>
                                            (Área Profissional)
                                        </option>

                                        {areaprofissional.map((area) => (
                                            <option value={area.id} key={area.id}>
                                                {area.nomeareaprofissional}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome da Área Profissional"
                                        id="input-nome-areaprofissional"
                                        title="Informe o nome da Área de Empresa"
                                        className="form-control form-control-user"
                                        name="nomeareaprofissional"
                                        value={newAreaProfissional.nomeareaprofissional ? newAreaEmpresa.nomeareaprofissional : ""}
                                        handleOnChange={handleChangeAreaProfissional}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addAreaProfissional} />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateAreaProfissional}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteAreaProfissional}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*==================================FIM==================================== */}


                        {/*====================================================================== */}


                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Tipo de Vaga</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_tipovaga"
                                        id="id_tipovaga"
                                        className="form-select"
                                        value={novoTipoVaga.id_tipovaga ? novoTipoVaga.id_tipovaga : ""}
                                        onChange={handleChangeNovoTipoVaga}
                                    >
                                        <option value="" selected>
                                            (Tipo de Vaga)
                                        </option>

                                        {tipoVaga.map((tipo) => (
                                            <option value={tipo.id} key={tipo.id}>
                                                {tipo.nometipovaga}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome do Tipo de Vaga"
                                        id="input-nome-tipovaga"
                                        title="Informe o nome do Tipo de Vaga"
                                        className="form-control form-control-user"
                                        name="nometipovaga"
                                        value={newTipoVaga.nometipovaga ? newTipoVaga.nometipovaga : ""}
                                        handleOnChange={handleChangeTipoVaga}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addTipoVaga} />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateTipoVaga}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteTipoVaga}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*==================================FIM==================================== */}

                        <div className="col-12 mt-3">

                            <div className="row">

                                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                    <span className="">Categoria de Vaga</span>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <select
                                        name="id_categoriavaga"
                                        id="id_categoria"
                                        className="form-select"
                                        value={novoCategoriaVaga.id_categoriavaga ? novoCategoriaVaga.id_categoriavaga : ""}
                                        onChange={handleChangeNovoCategoriaVaga}
                                    >
                                        <option value="" selected>
                                            (Categoria de Vaga)
                                        </option>

                                        {categoriaVaga.map((categoria) => (
                                            <option value={categoria.id} key={categoria.id}>
                                                {categoria.nomecategoriavaga}
                                            </option>
                                        ))
                                        }

                                    </select>
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Nome do Tipo de Vaga"
                                        id="input-nome-categoriavaga"
                                        title="Informe o nome da Categoria de Vaga"
                                        className="form-control form-control-user"
                                        name="nomecategoriavaga"
                                        value={newCategoriaVaga.nomecategoriavaga ? newCategoriaVaga.nomecategoriavaga : ""}
                                        handleOnChange={handleChangeCategoriaVaga}
                                    />
                                </div>

                                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-4">
                                                <Button
                                                    text="Adicionar"
                                                    className="item-button item-button1"
                                                    onClick={addCategoriaVaga} />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Editar"
                                                    className="item-button item-button2"
                                                    onClick={updateCategoriaVaga}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    text="Eliminar"
                                                    className="item-button item-button3"
                                                    onClick={deleteCategoriaVaga}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*==================================FIM==================================== */}
                    </div>

                    <div>
                        <DashFooter />
                    </div>
                </div>

            </div>
        </>
    );

}

export default Items