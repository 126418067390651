import React from 'react'


function Input({placeholder,id,className,name,handleOnChange, value, type, title}){

    return(
        <input type={type} 
        placeholder={placeholder} 
        name={name} 
        id={id} 
        title={title}
        className={className} 
        value={value}
        onChange={handleOnChange}
        />
    );
}

export default Input