import React, { useState, useEffect } from "react"

import { RiSearch2Line } from "react-icons/ri"
import {FaRedo} from "react-icons/fa"
//import { Link } from "react-router-dom";
/*
    ====React paginate=====
    URL: https://www.npmjs.com/package/react-paginate  
*/

import ReactPaginate from "react-paginate";

import "./pesquisar.css"

import Nav from "../../../../components/Layout/Navbar/Nav";
import Footer from "../../../../components/Layout/Footer/Footer";

import Api from "../../../../Api";
import { header } from "../../../../config/header";

import ListagemVaga from "../../../../components/Listagem/Vagas/Listagem";

import Input from "../../../../components/Form/Input/input";

import Load from "../../../../components/Layout/Load/Load";
//import Vagas from "../../../Admin/Vagas/Dashboard/DashVagas";


import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"



function PesquisarVaga() {


    //========= Alertas ===========

    const notify = (msg) => {
        toast.success(msg)
    }

    const notifyInfo = (msg) => {
        toast.info(`${msg}.`)
    }

    
    const notifyError = (msg) => {
        toast.error(`${msg}.`)
    }

    //============================

    const [loadState, setLoadState] = useState(true)

    const [vagas, setVagas] = useState([])

    const [searchKey, setSearchKey] = useState({
        key_word: ""
    })
    const [searchD, setSearchD] = useState({
        distrito: ""
    })
    const [searchC, setSearchC] = useState({
        id_categoriavaga: ""
    })
    const [searchT, setSearchT] = useState({
        id_tipovaga: ""
    })

    function handleChangeName(e) {
        setSearchKey({ ...searchKey, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }

    function handleChangeD(e) {
        setSearchD({ ...searchD, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }

    function handleChangeC(e) {
        setSearchC({ ...searchC, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }
    function handleChangeT(e) {
        setSearchT({ ...searchT, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    }



    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(vagas.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(vagas.length / itemsPerPage));

    }, [itemOffset, itemsPerPage, vagas])


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % vagas.length;
        setItemOffset(newOffset);
    };


    function resetSearches(key_word, distrito, category, type){
        if(key_word){
            setSearchKey({
                key_word: ""
            })
        }
        if(distrito){
            setSearchD({
                distrito: ""
            })
        }
        if(category){
            setSearchC({
                id_categoriavaga: ""
            }) 
        }
        if(type){
            setSearchT({
                id_tipovaga: ""
            }) 
        }
        
    }


    async function getVagas() {
        resetSearches(true,true, true, true);
        setLoadState(true)
        Api.get("vagas", header).then(response => {
            setVagas(response.data)
            console.log(response.data)
            setLoadState(false)
        }).catch(erro => {
            console.log("Houve um erro: ", erro);
            setLoadState(false)
        })
    }

    useEffect(() => {
        getVagas()
    }, [])

        /*========================================= */

        async function pesquisarKeyWord(){
            setLoadState(true)
            let dist = "gvgvv"
            let idTip = 0
            let idCat = 0
    
            if (searchKey.key_word === undefined || searchKey.key_word === null || searchKey.key_word === "") {
                notifyInfo("Digite uma palavra chave");
                setLoadState(false);
            } else {
                await Api.get(`vagas/searchjobs/${searchKey.key_word}/${dist}/${idCat}/${idTip}`, header).then(response => {
    
                    if (response.data.resposta === "Ainda não existe nenhuma vaga com a especificação desejada!") {
                        notifyInfo("Nenhuma vaga encontrada!");
                        resetSearches(false, true, true, true);
                        setLoadState(false);
                    } else {
                        setVagas(response.data)
                        notify(`Foi encontrado ${response.data.length} vaga(s)`)
                        resetSearches(false, true, true, true);
                        console.log("Vagas pesquisadas por keyword: ", response.data);
                        setLoadState(false);
                    }
    
                }).catch(error => {
                    console.log("Errou ao pesquisar vaga por kweyword: ", error)
                    setLoadState(false)
                    notifyError("Houve um erro, tente mais tarde!");
                })
            }
    
        }
        /*========================================= */


    /*================== Pesqisar p+or Distrito ==================== */

    async function pesquisarPorDistrtrito() {
        setLoadState(true)
        let idCat = 0
        let idTip = 0
        let key_word = "****"

        if (searchD.distrito !== "") {

            await Api.get(`vagas/searchjobs/${key_word}/${searchD.distrito}/${idCat}/${idTip}`, header).then(response => {

                if (response.data.resposta === "Ainda não existe nenhuma vaga com a especificação desejada!") {
                    notifyInfo("Nenhuma vaga encontrada");
                    //console.log("vagas pesquisadas por distrito: ", response.data)
                    //getCanddatos();
                    setLoadState(false);
                    setVagas([]);
                    resetSearches(true,false, true, true);
                } else {
                    setVagas(response.data)
                    notify(`Foi encontrado ${response.data.length} vaga(s)`)
                    console.log("vagas pesquisadas por distrito:: ", response.data)
                    setLoadState(false)
                    resetSearches(true,false, true, true);
                }

            }).catch(error => {
                console.log("Errou ao pesquisar vagas por distrito: ", error)
                setLoadState(false)
                notifyError("Houve um erro, tente mais tarde!");
            })
        } else {
            setLoadState(false);
        }
    }

    useEffect(() => {
        pesquisarPorDistrtrito()
    }, [searchD.distrito])

    /*========================================= */



    return (
        <>  
            <ToastContainer />
            <Nav navClassName={"nav-home"} />
            <div className="perquisar-vaga-container col-12">

                <h3 className="text-center text-light mb-5">Pesquisar Vagas</h3>

                <div className="perquisar-vaga-body col-12 d-flex justify-content-center">
                    <div className="col-10">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 d-flex align-items-start">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                        <span className="text-start mb-4 redifinir-pesquisa" title="Redefinir pesquisa"><FaRedo onClick={getVagas} className="fs-6 pesquisar-perfil-icon" /> </span>
                                            <div className="col-10">
                                            <label className="mb-2">Pesquisar por Palavra Chave</label>
                                                <Input
                                                    type="text"
                                                    id="palavra-chave"
                                                    name="key_word"
                                                    className="form-control form-control-user pesquisar-vaga-porpalavraChave"
                                                    placeholder="Pesquisar Palavra Chave"
                                                    handleOnChange={handleChangeName}
                                                    value={searchKey.key_word ? searchKey.key_word: ""}
                                                ></Input>
                                            </div>
                                            <div className="col-2 d-flex  align-items-center">
                                                <RiSearch2Line className="pesquisar-vaga-icon" title="Procurar" onClick={pesquisarKeyWord}/>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-12 mt-4">
                                    <label className="mb-2">Pesquisar por Distrito</label>
                                        <select
                                            title="Informe o seu Distrito"
                                            className="form-select pesquisar-vaga-porDistrito"
                                            name="distrito"
                                            id="pesquisar-vaga-distrito"
                                            value={searchD.distrito ? searchD.distrito : ""}
                                            onChange={handleChangeD}
                                        >
                                            <option value="" selected>(Pesquisar por Distrito)</option>
                                            <option value="Água Grande">Água Grande</option>
                                            <option value="Mé-Zochi">Mé-Zochi</option>
                                            <option value="Cantagalo">Cantagalo</option>
                                            <option value="Lobata">Lobata</option>
                                            <option value="Caué">Caué</option>
                                            <option value="Lembá">Lembá</option>
                                            <option value="Região Autónoma do Príncipe">Região Autónoma do Príncipe</option>
                                        </select>
                                    </div>
                                    {/*===================================================*/}
                                    <div className="col-12 mt-4">
                                    <label className="mb-2">Pesquisar por Categoria de Vaga</label>
                                        <select
                                            title="Informe a categoria"
                                            className="form-select pesquisar-vaga-categoria"
                                            name="id_categoriavaga"
                                            id="pesquisar-vaga-categoria"
                                            value={searchC.id_categoriavaga ? searchC.id_categoriavaga : ""}
                                            onChange={handleChangeC}
                                        >
                                          <option value="" selected>(Pesquisar por Categoria)</option>
                                        </select>
                                    </div>
                                    {/*===================================================*/}
                                    <div className="col-12 mt-4">
                                    <label className="mb-2">Pesquisar por Tipo de Vaga</label>
                                        <select
                                            title="Informe o tipo"
                                            className="form-select pesquisar-vaga-tipo"
                                            name="id_tipovaga"
                                            id="pesquisar-vaga-tipo"
                                            value={searchT.id_tipovaga ? searchT.id_tipovaga: ""}
                                            onChange={handleChangeT}
                                        >
                                        <option value="" selected>(Pesquisar por Tipo)</option>   
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {loadState === true
                                ?
                                (<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center container-load"><Load /></div>)
                                :
                                (

                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-6 mt-xl-0 mt-lg-0 mt-4 ">
                                                        <span>Total de {vagas.length} vagas</span>
                                                    </div>

                                                    <div className="col-6 text-end">
                                                        <div className="row">

                                                            {/* 

                                                            <div className="col-12">
                                                                <span className="text-end">Ondenar por:</span>
                                                            </div>
                                                            <div className="col-3">
                                                                <select
                                                                    title="Informe a quanditade de itens a ser listado"
                                                                    className="form-select pesquisar-perfil-quantview"
                                                                    name="itemsPerPage"
                                                                    id="itemsPerPage"
                                                                    value={itemsPerPage}
                                                                    onChange={handleChangeSelect2}
                                                                >
                                                                    <option selected value={5}>5</option>
                                                                    <option value={7}>7</option>
                                                                    <option value={10}>10</option>
                                                                    <option value={12}>12</option>
                                                                    <option value={15}>15</option>
                                                                </select>
                                                            </div>
                                                            */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 ">
                                                <ListagemVaga
                                                    data={currentItems}
                                                />
                                            </div>
                                        </div>
                                        <ReactPaginate
                                            previousLabel={"Anterior"}
                                            nextLabel={"Próximo"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination justify-content-center mt-4 mb-5"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                )
                            }

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default PesquisarVaga