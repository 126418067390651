import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom'

import "./visualizaranuncio.css";

//Ícones
import { FaCalendarAlt } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { ImEye } from "react-icons/im";
import { FaUserAlt } from 'react-icons/fa'
import { FaFilePdf } from 'react-icons/fa'

import Load from "../../../../components/Layout/Load/Load";

import Api from '../../../../Api'
import StoreContext from "../../../../config/Store/Context"
import { header } from "../../../../config/header";

import Nav from "../../../../components/Layout/Navbar/Nav";


function VisuzalizarAnuncio() {

  const [loadState, steLoadState] = useState(true)

  const [vaga, setVaga] = useState([])


  //const [vagaCandidatada, setVagaCandidatada] = useState('')
  //const [vagaFavorita, setVagaFavorita] = useState('')

  //Dados do Utilizador Logado
  const { user, setUser } = useContext(StoreContext)

  const id = useParams().id

  const [btnSubmit, setBtnSubmit] = useState({
    class: '',
    text: 'Candidatar',
    spin: ''
  })

  const [btnSubmit2, setBtnSubmit2] = useState({
    class: '',
    text: 'Adicionar aos Favoritos',
    spin: ''
  })


  async function getVaga() {
    await Api.get(`vagas/${id}`, header).then((response) => {
      const data = response.data;
      console.log(data);
      setVaga(data);
      steLoadState(false)
    }).catch((err) => {

      console.log("Houve um erro: ", err);
    });
  }
  useEffect(() => {
    getVaga();


  }, [id])


  //Verificando se a vaga existe na lsita das candidatadas
  async function getVagaCandidatada() {
    await Api
      .get(`utilizador/listvaga/candidatada/${user.id}/${id}`, header)
      .then((response) => {
        if (response.data) {
          console.log(response.data)
          setBtnSubmit({
            class: 'disabled',
            text: 'Candidatado',
            spin: ''
          })

        } else {
          console.log("Ainda não candidatou nessa vaga.")
        }
        console.log("data:", response)

      })
      .catch((erro) => {
        alert("Houve um erro!");
        console.log("Houve o seguinte erro: ", erro)

      });
  }
  useEffect(() => {
    getVagaCandidatada()

  }, [user.id])


  async function getVagaFavorita() {
    await Api
      .get(`favoritos/listagembyid/${user.id}/${id}`, header)
      .then((response) => {
        if (response.data) {
          console.log(response.data)
          setBtnSubmit2({
            class: 'disabled',
            text: 'Favoritado',
            spin: ''
          })

        } else {
          console.log("Ainda não favoritou essa vaga.")
        }
        console.log("data:", response)

      })
      .catch((erro) => {
        alert("Houve um erro!");
        console.log("Houve o seguinte erro: ", erro)

      });
  }
  useEffect(() => {
    getVagaFavorita()

  }, [user.id])




  async function Candidatar() {
    setBtnSubmit({
      class: 'disabled',
      text: 'Aguarde',
      spin: 'spinner-border spinner-border-sm'
    })

    await Api.post(`/utilizadores/candidatar/utilizador/${user.id}/vaga/${id}`, header).then((response) => {

      setBtnSubmit({
        class: 'disabled',
        text: 'Candidatado',
        spin: ''
      })

      console.log(response)
      alert("Candidatuta feita com sucesso!")

    }).catch((erro) => {
      setBtnSubmit({
        class: '',
        text: 'Candidatar',
        spin: ''
      })
      alert("Erro ao tentar candidatar!")
      console.log("Erro", erro)
    })

  }



  async function Favoritar() {
    setBtnSubmit2({
      class: 'disabled',
      text: 'Aguarde',
      spin: 'spinner-border spinner-border-sm'
    })

    await Api.post(`/favoritos/additem/utilizador/${user.id}/vaga/${id}`, header).then((response) => {

      setBtnSubmit2({
        class: 'disabled',
        text: 'Favoritado',
        spin: ''
      })

      console.log(response)
      alert("Vaga adicionada aos favoritos!")

    }).catch((erro) => {
      setBtnSubmit2({
        class: '',
        text: 'Favoritar',
        spin: ''
      })
      alert("Erro ao tentar adicionar aos favoritos!")
      console.log("Erro", erro)
    })

  }


  /*useEffect(() => {

    if (minhasVagasF.length > 0) {

      if (vaga.id === minhasVagasF[0].id_vaga) {
        setBtnSubmit2({
          class: 'disabled',
          text: 'Favorito',
        })
      }
    }
    if (minhasVagas.length > 0) {
      if (user.id === minhasVagas[0].id_vaga) {
        setBtnSubmit({
          class: 'disabled',
          text: 'Candidatado',
        })
      }
    }
  }, [user.id])
*/


  return (

    <>
    <Nav navClassName={"nav-home"}/>
      {loadState === true ? (<div className=" load-container d-flex justify-content-center align-items-center mt-5"><Load /></div>)

        :
        (<>

          <div className="visualizar-anuncio">
            <div className="visualizar-anuncio-container">
              <h3 className="visualizar-anuncio-title text-center text-light mb-5 mt-4">
                {vaga.titulovaga}

              </h3>

              <div className="visualizar-anuncio-details d-flex flex-column justify-center">
                <div className="visualizar-anuncio-details-head d-flex justify-center">
                  {vaga.utilizador.foto ? <img
                    src={vaga.utilizador.foto}
                    alt="logo-user"
                    className="visualizar-anuncio-details-head-img me-3"
                  /> :
                    <FaUserAlt className="visualizar-anuncio-details-head-img me-3" />
                  }


                  <div className="visualizar-anuncio-details-head-details">
                    <h2 className="text-black visualizar-anuncio-details-head-details-title me-4">

                      {vaga.utilizador.id === 2 ?
                        (<a href={`/perfil/visualizar/${vaga.utilizador.id}`} className="text-dark">{vaga.utilizador.nomeutilizador}</a>)
                        :
                        (<a href={`/perfil/empresa/visualizar/${vaga.utilizador.id}`} className="text-dark">{vaga.utilizador.nomeutilizador}</a>)}

                    </h2>

                    <div className="visualizar-anuncio-top">
                      <strong className="me-3 visualizar-anuncio-data me-3">
                        <FaCalendarAlt className="visualizar-anuncio-icon text-success" />  Publicado em: {
                          vaga.createdAt.substring(0, 10)

                        }
                      </strong>
                      <strong className="me-3 visualizar-anuncio-local me-3">
                        <ImLocation className="visualizar-anuncio-icon text-warning mx-1" />
                        {vaga.zona}
                      </strong>
                      <strong className="me-3 visualizar-anuncio-views">
                        <ImEye className="visualizar-anuncio-icon text-primary" /> 120
                        Visuzalizações
                      </strong>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="col-12 visualizar-anuncio-details-info mt-5 mb-5">
                  <div className="row">
                    <div className="col-8 mb-4 visualizar-anuncio-details-info1 me-5">
                      <h3 className="visualizar-anuncio-details-info1-title mb-4">
                        Descrição
                      </h3>
                      <p className="text mb-5">
                        {vaga.descricao}
                      </p>
                      <h3 className="visualizar-anuncio-details-info1-title mb-4">
                        Formação + Experiência
                      </h3>
                      <h6>-Formação</h6>
                      {vaga.formacao && (
                        <p className="text mb-4">
                          {vaga.formacao}
                        </p>
                      )}
                      <h6>-Experiência</h6>
                      {vaga.experiencia && (
                        <p className="text mb-5">
                          {vaga.experiencia}
                        </p>
                      )}

                      <h3 className="visualizar-anuncio-details-info1-title mb-4">
                        Outras Habilidades
                      </h3>
                      <p className="text mb-5">
                        {vaga.outras_habilidades}
                      </p>
                    </div>
                    <div className="col">
                      <div className="col-4 visualizar-anuncio-details-info2">
                        <div className="col-12 p-4 visualizar-anuncio-details-info2-1 mb-4 dorder">

                          <div className="col-12 visualizar-anuncio-details-info2-1-favorito">

                            {vaga.utilizador.id === user.id
                              ? (
                                <button
                                  className={`btn btn-danger visualizar-anuncio-button col-12 disabled`}
                                  type="submit"
                                >
                                  <span
                                    className={``}
                                    role="status"
                                    aria-hidden="true" /> {"Seu Anúncio"}
                                </button>
                              )
                              :
                              <button
                                className={`btn btn-danger visualizar-anuncio-button col-12 ${btnSubmit2.class}`}
                                type="submit"
                                onClick={Favoritar}

                              >
                                <span
                                  className={`${btnSubmit2.spin}`}
                                  role="status"
                                  aria-hidden="true" /> {btnSubmit2.text}
                              </button>

                            }


                          </div>

                          <div className="col-12 visualizar-anuncio-details-info2-1-candidatar mt-4">

                            {vaga.utilizador.id === user.id
                              ?
                              <button
                                className={`btn btn-primary visualizar-anuncio-button col-12 disabled`}
                                type="submit"
                              >
                                <span
                                  className={``}
                                  role="status"
                                  aria-hidden="true" /> {"Seu Anúncio"}
                              </button>
                              :

                              <button
                                className={`btn btn-primary visualizar-anuncio-button col-12 ${btnSubmit.class}`}
                                type="submit"
                                onClick={Candidatar}
                              >
                                <span
                                  className={`${btnSubmit.spin}`}
                                  role="status"
                                  aria-hidden="true" /> {btnSubmit.text}
                              </button>


                            }



                          </div>

                        </div>
                        <div className="col-12 p-4 visualizar-anuncio-details-info2-1 mb-4">

                          <h5 className="details-info2-1-title mb-4 mt-2 text-center">Mais Detalhes</h5>
                          <p className="details-info2-1-conteudo"><strong>Tipo de Vaga:</strong>{vaga.length == 0 ? " " : vaga.tipovaga.nometipovaga}</p>
                          <p className="details-info2-1-conteudo"><strong>Área:</strong>{vaga.length == 0 ? "" : vaga.categoriavaga.nomecategoriavaga}</p>
                          <p className="details-info2-1-conteudo"><strong>Publicado em:</strong>{vaga.createdAt.substring(0, 10)}</p>
                          <p className="details-info2-1-conteudo"><strong>Quantidade de Vaga:</strong>{vaga.quantidadevaga}</p>
                          <p className="details-info2-1-conteudo"><strong>Distrito:</strong>{vaga.distrito}</p>
                          <p className="details-info2-1-conteudo"><strong>Zona:</strong>{vaga.zona}</p>
                          <p className="details-info2-1-conteudo"><strong>Salário:</strong>{vaga.salario}</p>
                          <p className="details-info2-1-conteudo"><strong>Data de Início:</strong>{vaga.datainicio}</p>
                          <p className="details-info2-1-conteudo"><strong>Data do Fim:</strong>{vaga.datafinalizacao}</p>


                        </div>
                        <div className="col-12 p-4 visualizar-anuncio-details-info2-2  mb-4">

                          <h5 className="documents-title text-center mb-4">Documentos</h5>

                          <div className="d-flex flex-column align-item-center">
                            <span className="mb-1"><FaFilePdf className="text-danger" /> <a href="#">Edital.pdf</a></span>
                            <span className=""><FaFilePdf className="text-danger" /> <a href="#">Requisitos.pdf</a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        )}
    </>
  );
}

export default VisuzalizarAnuncio;
