import React from "react"
import "./CardPopularCategory.css"

function CardPopularCategory({ icon, name, number, className, category}) {
    return (
        <div className={`${className} card_container ${category}`}>
            <p>{icon}</p>
            <span className="mb-2 fw-bold">{name}</span>
            <span>({number} Anúncios)</span>
        </div>
    )

}

export default CardPopularCategory