import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import './admineditarperfil.css'

import Api from '../../../../Api';

import { header } from '../../../../config/header';


//Ícones

import { FaUserAlt } from 'react-icons/fa'

//Componentes
import Load from '../../../../components/Layout/Load/Load';

import DashNav from '../../../DashNav/DashNav';
import DashFooter from '../../../DashFooter/DashFooter';
/*
import Button from '../../../../components/Form/Button/Button';
import Input from '../../../../components/Form/Input/input';
import Select from '../../../../components/Form/Select/Select';
*/


function AdminEditarPerfil() {


  const id = useParams().id

  const [nivelAcesso, setNivelAcesso] = useState([]);

  const [btnSubmit, setBtnSubmit] = useState({
    className: '',
    text: 'Salvar',
    spin: ''
  })


  const [perfil, setPerfil] = useState({
    nomeutilizador: "",
    id_nivelacesso: ""
  });

  /*
  async function getNivelAcesso(){
  
    await Api.get("nivelacessos").then((response) =>{
      console.log(response.data)
      setNivelAcesso(response.data)
    }).catch((erro) =>{
      console.log("Houve um erro: ", erro)
    })
  }
  */

  async function getUser() {
    await Api.get(`utilizador/registro/${id}`, header).then((response) => {
      const data = response.data;
      console.log(data);
      setPerfil(data);
    }).catch((err) => {
      console.log("Houve um erro: ", err);
    });
  }


  useEffect(() => {
    getUser()
    //getNivelAcesso()
  }, [id])

  async function getNivelAcesso() {

    Api.get("nivelacessos", header).then(response => {
      //console.log(response.data)
      setNivelAcesso(response.data)
    }).catch(erro => {
      console.log("Erro: ", erro)
    })

  }

  useEffect(() => {
    getNivelAcesso();
  }, [])


  function handleSubmit() {

    if (perfil.id_nivelacesso === 0) {
      alert("Selcione uma das opções")
    } else {

      setBtnSubmit({
        className: 'disabled',
        text: 'Aguarde',
        spin: 'spinner-border spinner-border-sm'
      })

      Api
        .post(`utilizadores/${id}`, perfil, header)
        .then((response) => {
          alert("Alteração feita com sucesso!")
          getUser()
          console.log(response)
          setBtnSubmit({
            className: '',
            text: 'Salvar',
            spin: ''
          })
        })
        .catch((erro) => {
          setBtnSubmit({
            className: '',
            text: 'Salvar',
            spin: ''
          })
          console.log("Houve o seguinte erro: ", erro);
        });

    }

  }


  const submit = (e) => {
    e.preventDefault();
    handleSubmit(perfil);
  };

  /*

  function handleNivelAcesso(e) {
    setPerfil({ ...perfil, nivelacess:{
      id: e.target.value,
      nomenivelacesso: e.target.options[e.target.selectedIndex].text,
    } });

  }
  
  const  handleChange = (e) =>{
    //setPerfil({ ...nivelAcesso, [e.target.name]: e.target.value });
    const getValue = e.target.value
    //console.log(getValue)
  
      setNivelAcesso({
        id_nivelacesso: getValue
       
      })
  }*/


  function handleChangeSelect(e) {

    setPerfil({ ...perfil, [e.target.name]: e.target.value });
    //console.log(e.target.value)  
  }




  return (
    <>
      <DashNav />
      <>
        {perfil.nomeutilizador.length === 0 ? (<div className=" load-container d-flex justify-content-center align-items-center mt-5"><Load /></div>) :

          (
            <div className="container perfil-container col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 mb-5 mt-5" id='adminEditar-perfil'>
              <h3 className="perfil-title text-center">Editar Utilizador</h3>

              <div className="perfil-dados col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 shadow borderRadius-boerderTop">

                <div className="perfil-foto-container mt-5">

                  <div className="perfil-foto-view mb-2">

                    {perfil.foto ? <img src={perfil.foto} alt="user-image" /> : <FaUserAlt className="user-img" />}

                  </div>


                </div>

                <form onSubmit={(value) => submit(value)}>
                  <div className="perfil-dados d-flex flex-column justify-content-center align-items-center col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mt-5">
                    <div className="col-12">
                      <div className="row flex-xl-row flex-lg-row flex-md-row flex-sm-row flex-column">
                        <div className="col-6 mb-4">
                          <label htmlFor="nome">
                            Nome:
                          </label><br />
                          <span
                            className='text-center admin-ed-user-name'>
                            <strong>
                              {perfil.nomeutilizador}
                            </strong>
                          </span>
                        </div>
                        <div className="col">
                          <label htmlFor="id_nivelacesso" className="mb-1">
                            Nível de Acesso
                          </label>

                          <select
                            name="id_nivelacesso"
                            id="id_nivelacesso"
                            title="Informe o Nível de Acesso"
                            className="form-select"
                            onChange={handleChangeSelect}
                            value={perfil.id_nivelacesso ? perfil.id_nivelacesso : 0}
                          >
                            {perfil.id_nivelacesso
                              ?
                              ("")
                              :
                              <option value="" selected>
                                (Nível de Acesso)
                              </option>
                            }

                            {nivelAcesso.map((nivel) => (
                              <option value={nivel.id} key={nivel.id}>
                                {nivel.nomenivelacesso}
                              </option>
                            ))
                            }
                          </select>

                        </div>
                      </div>
                    </div>

                  </div>

                  <button
                    className={`btn mt-5 mb-5 perfil-button w-70 ${btnSubmit.className}`}
                    type="submit"
                  >
                    <span
                      className={`${btnSubmit.spin}`}
                      role="status"
                      aria-hidden="true" /> {btnSubmit.text}
                  </button>

                </form>
              </div>
            </div>

          )}
      </>

      <DashFooter />

    </>
  );


}

export default AdminEditarPerfil