//Arquivo de configuração responsável por guadrdar os dados do utilizador no Localhost e questões de proteção das rotas



// Definindo o bjeto do serviço

const authService = {

  

    // Função para salvar o usuário logado no local storage
    setLoggedUser(data){
        let parsedData = JSON.stringify(data)
        localStorage.setItem("utilizador", parsedData)
    },

    // Função responsável por recuperar o usuário logado do local storage
    getLoggedUser(){
        let data = localStorage.getItem("utilizador");
        if(!data) return null;
        try {
            let parsedData = JSON.parse(data)
            return parsedData
        } catch (error) {
            console.log(error)
            return null
        }
    },

    cleanLoggedUser(){
        localStorage.clear();
    }
}

export default authService;