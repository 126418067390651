import React from 'react'
import './checkbox.css'




function Checkbox({id, name, onChange, htmlFor, className, estado}){

    return(

<>
        {estado === "true"

            ? 

            <label htmlFor={htmlFor}>

            <input 
                type="checkbox" 
                id={id } 
                name={name}
                className ={`check-box-component ${className}`}
                defaultChecked
            />
            <span className="check-box-span-component"></span>
    
            </label>

            :

            <label htmlFor={htmlFor}>

            <input 
                type="checkbox" 
                id={id } 
                name={name}
                className ={`check-box-component ${className}`}
            />
            <span className="check-box-span-component"></span>
    
            </label>
    }
</>
       
    );
}

export default Checkbox