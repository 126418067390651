import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./vagaanuncio.css";

import Api from "../../../Api";
import StoreContext from "../../../config/Store/Context";
import { header } from "../../../config/header";


import Nav from "../../../components/Layout/Navbar/Nav";
import Footer from "../../../components/Layout/Footer/Footer";

function FormAnuncio1() {
  let navigate = useNavigate();

  //Dados do Utilizador Logado
  const { user } = useContext(StoreContext)

  const [tipoVaga, setTipoVaga] = useState([]);
  const [categoriaVaga, setCategoriaVaga] = useState([])



  const [btnSubmit, setBtnSubmit] = useState({
    class: '',
    text: 'Salvar',
    spin: ''
  })


  const [values, setValues] = useState({
    titulovaga: "",
    id_tipovaga: "",
    distrito: "",
    salario: "",
    formacao: "",
    experiencia: "",
    zona: "",
    datainicio: "",
    datafinalizacao: "",
    quantidadevaga: "",
    descricao: "",
    id_categoriavaga: "",
    id_tipoutilizador: "",
    outras_habilidades: ""
  });



  async function getTipoVaga() {
    await Api
      .get(`tiposvagas`, header)
      .then((response) => {
        //console.log(response)
        setTipoVaga(response.data);
      })
      .catch((erro) => {
        if (erro.response) {
          alert("Houve um erro!");
          console.log("Houve o seguinte erro: ", erro.response)
        }

        else {
          //Backend fora do ar
          console.log("Erro, tente mais tarde!")
        };
      });

  }


  useEffect(() => {
    getTipoVaga();
  }, [])


  async function getCategoriaVaga() {
    await Api
      .get(`categoriasvagas`, header)
      .then((response) => {
        //console.log(response)
        setCategoriaVaga(response.data);
      })
      .catch((erro) => {
        if (erro.response) {
          alert("Houve um erro!");
          console.log("Houve o seguinte erro: ", erro.response)
        }

        else {
          //Backend fora do ar
          console.log("Erro, tente mais tarde!")
        };
      });

  }


  useEffect(() => {
    getCategoriaVaga();
  }, [])



  async function submitVaga(e) {
    e.preventDefault();
    setBtnSubmit({
      class: 'disabled',
      text: 'Aguarde',
      spin: 'spinner-border spinner-border-sm'
    })

    await Api
      .post(
        `utilizadores/${user.id}/categoriasvagas/${values.id_categoriavaga}/tiposvagas/${values.id_tipovaga}/vagas`,
        {
          titulovaga: values.titulovaga,
          distrito: values.distrito,
          zona: values.zona,
          salario: values.salario,
          distrito: values.distrito,
          datainicio: values.datainicio,
          datafinalizacao: values.datafinalizacao,
          id_tipovaga: values.id_tipovaga,
          id_categoriavaga: values.id_categoriavaga,
          quantidadevaga: values.quantidadevaga,
          descricao: values.descricao,
          formacao: values.formacao,
          experiencia: values.experiencia,
          outras_habilidades: values.outras_habilidades
        }, header
      )
      .then((response) => {
        setBtnSubmit({
          class: '',
          text: 'Salvar',
          spin: ''
        })
        console.log(response.data);
        alert("Anúncio Criado com sucesso!")
        navigate("/");
      })
      .catch((erro) => {
        setBtnSubmit({
          class: '',
          text: 'Salvar',
          spin: ''
        })
        alert("Houve o seguinte erro!")
        console.log("Houve o seguinte erro: ", erro);
      });
  }

  function handlechangeValues(value) {
    const newvalues = { ...values };
    newvalues[value.target.name] = value.target.value;
    setValues(newvalues);
  }

  function handleChangeSelect(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
    //console.log(e.target.value)  
  }


  return (
    <>
      <Nav navClassName={"nav-home"}/>
      <div className="container anuncio-container col-xl-10 col-lg-12 col-md-10 mb-5">
        <h3 className="anuncio-title text-center">Criar Anúncio</h3>

        <div className="anuncio-dados-container borderRadius-boerderTop col-xl-10 col-lg-12 col-md-10 shadow">
          <form onSubmit={(value) => submitVaga(value)}>
            <div className="anuncio-foto-container">
              <label className="mt-5">
                <strong className="nota-color">Nota: </strong> A foto da empresa
                aparecerá como foto de apresentação do anúncio.
              </label>
            </div>

            <div className="anuncio-dados-body d-flex flex-column justify-content-center align-items-center col-xl-10 col-lg-12 col-md-10 mt-5">
              <div className="col-12 mt-4">
                <div className="col mb-4">
                  <label className="mb-1">
                    Título da Oferta<span className="obg">*</span>
                  </label>
                  <input
                    data-title="Escreva Título da Oferta"
                    value={values.titulovaga}
                    onChange={(value) => handlechangeValues(value)}
                    type="text"
                    className="form-control form-control-user"
                    name="titulovaga"
                    placeholder="Título da Oferta"
                  />
                </div>
              </div>

              {/*##############################################*/}

              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-6">
                    <label className="mb-1">
                      Tipo de Vaga<span className="obg">*</span>
                    </label>
                    <select
                      value={values.id_tipovaga ? values.id_tipovaga : ""}
                      title="Selecione o tipo de vaga que pretende publicar"
                      className="form-select"
                      name="id_tipovaga"
                      id="id_tipovaga"
                      onChange={handleChangeSelect}
                    >

                      {values.id_tipovaga
                        ?
                        ("")
                        :
                        <option value="" selected>
                          (Selecionar Tipo de Vaga)
                        </option>
                      }

                      {tipoVaga.map((tipo) => (
                        <option value={tipo.id} key={tipo.id}>
                          {tipo.nometipovaga}
                        </option>
                      ))
                      }
                    </select>
                  </div>
                  <div className="col-6">
                    <label className="mb-1">
                      Categotia da Vaga<span className="obg">*</span>
                    </label>
                    <select
                      value={values.id_categoriavaga ? values.id_categoriavaga : ""}
                      title="Selecione uma categoria profissional que se adapte melhor a sua oferta"
                      className="form-select"
                      name="id_categoriavaga"
                      id="id_categoriavaga"
                      onChange={handleChangeSelect}
                    >

                      {values.id_categoriavaga
                        ?
                        ("")
                        :
                        <option value="" selected>
                          (Selecione a  Categoria a Vaga)
                        </option>
                      }

                      {categoriaVaga.map((categoria) => (
                        <option value={categoria.id} key={categoria.id}>
                          {categoria.nomecategoriavaga}
                        </option>
                      ))
                      }

                    </select>
                  </div>
                </div>
              </div>

              {/*##############################################*/}

              <div className="col-12">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label className="mb-1">Dsitrito</label>
                    <select
                      value={values.distrito ? values.distrito : ""}
                      title="Iforme o distrito"
                      className="form-select"
                      name="distrito"
                      id="distrito"
                      onChange={handleChangeSelect}
                    >
                      {values.distrito
                        ?
                        ("")
                        :
                        <option value="" selected>
                          (Seleciona o Distrito){" "}
                        </option>
                      }
                      <option value="Água Grande">Água Grande</option>
                      <option value="Mé-Zochi">Mé-Zochi</option>
                      <option value="Cantagalo">Cantagalo</option>
                      <option value="Lobata">Lobata</option>
                      <option value="Lembá">Lembá</option>
                      <option value="Caué">Caué</option>
                      <option value="Região Autónoma do Príncipe">Região Autónoma do Príncipe</option>
                    </select>
                  </div>
                  <div className="col">
                    <label htmlFor="zonaVaga">Zona</label>
                    <input
                      value={values.zona}
                      onChange={(value) => handlechangeValues(value)}
                      title="Informe a zona "
                      type="text"
                      className="form-control form-control-user"
                      name="zona"
                      placeholder="Zona"
                    />
                  </div>
                </div>
              </div>

              {/*##############################################*/}

              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label className="mb-1">Potencial Salário</label>
                    <input
                      value={values.salario}
                      onChange={(value) => handlechangeValues(value)}
                      title="Pode apresentar o salário da referida vaga"
                      type="text"
                      className="form-control form-control-user"
                      name="salario"
                      placeholder="Salário"
                    />
                  </div>
                  <div className="col">
                    <label className="mb-1">Quantidade de Vagas</label>
                    <input
                      value={values.quantidadevaga}
                      onChange={(value) => handlechangeValues(value)}
                      title="Inform a quantidade de vagas que pretende anunciar"
                      type="number"
                      className="form-control form-control-user"
                      name="quantidadevaga"
                      placeholder="Quantidade de Vagas"
                    />
                  </div>
                </div>
              </div>

              {/*##############################################*/}

              <div className="col-12 mb-5">
                <div className="row">
                  <div className="col-6 mb-4">
                    <label className="mb-1">Data de Início</label>
                    <input
                      value={values.datainicio}
                      onChange={(value) => handlechangeValues(value)}
                      title="Informe a data do iício da vaga"
                      type="date"
                      className="form-control form-control-user"
                      name="datainicio"
                      id="datainicio"
                    />
                  </div>
                  <div className="col">
                    <label className="mb-1">Data do fim </label>
                    <input
                      value={values.datafinalizacao}
                      onChange={(value) => handlechangeValues(value)}
                      title="Informe a data do fim da Vaga"
                      type="date"
                      className="form-control form-control-user"
                      name="datafinalizacao"
                    />
                  </div>
                </div>
              </div>

              {/*##############################################*/}

              <div className="col-12 mb-4 ">
                <div className="row flex-column">
                  <div className="col-12 mb-4">
                    <label className="mb-1">Formação</label>
                    <input
                      value={values.formacao}
                      onChange={(value) => handlechangeValues(value)}
                      title="Formação requisitada ao candidato"
                      type="text"
                      className="form-control form-control-user"
                      name="formacao"
                      placeholder="Formação"
                    />
                  </div>
                  <div className="col">
                    <label className="mb-1">Experiência</label>
                    <textarea
                      value={values.experiencia}
                      onChange={(value) => handlechangeValues(value)}
                      className="form-control"
                      id="experiencia"
                      name="experiencia"
                      cols="30"
                      rows="6"
                      title="Informe a experiência pedidada ao candidato"
                    ></textarea>
                  </div>
                </div>
              </div>


              {/*##############################################*/}

              <div className="col-12 mt-4 ">
                <div className="row">
                  <h5 className="text-center">Descrição da vaga</h5>
                  <div className="col-12 mt-4">
                    <div className="mb-4">
                      <textarea
                        value={values.descricao}
                        onChange={(value) => handlechangeValues(value)}
                        className="form-control"
                        id="vaga_descricao"
                        name="descricao"
                        cols="30"
                        rows="10"
                        title="Apresente os detalhes sobre a vaga"
                      ></textarea>
                      <label required htmlFor="vaga_descricao" className="mt-2">
                        Apresente uma descrição sobre a vaga
                        <span className="obg">*</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/*##############################################*/}

              <div className="col-12 mt-4 ">
                <div className="mb-2">
                  <textarea
                    value={values.outras_habilidades}
                    onChange={(value) => handlechangeValues(value)}
                    className="form-control"
                    id="outras_habilidades"
                    name="outras_habilidades"
                    cols="30"
                    rows="6"
                    title="Apresente os detalhes sobre a vaga"
                  ></textarea>
                </div>
                <label className="">
                  Outras habilidades
                </label>
              </div>
            </div>

            {/*##############################################*/}

            <button
              className={`btn mt-5 mb-5 anuncio-button ${btnSubmit.class}`}
              type="submit"
            >
              <span
                className={`${btnSubmit.spin}`}
                role="status"
                aria-hidden="true" /> {btnSubmit.text}
            </button>

          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default FormAnuncio1;
