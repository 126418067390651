import React from 'react'
import { BiEdit } from "react-icons/bi";
import { MdDelete } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';


import Api from "../../../../Api"
const url = process.env.REACT_APP_APP_URL

async function deleteUser(id) {

    const header = process.env.REACT_APP_ACCESS_KEY

    await Api.get(`utilizadores/${id}`, {
        headers: {
            'authorization': header,
            'Content-Type': 'application/json',
        }
    }).then((response) => {
        alert("Utilizador eliminado com sucesso!")
        window.location.reload();
    })
        .catch((erro) => {
            console.log("Houve um erro: " + erro)
        });
}



export const columns = [
    {
        name: 'Imagem',
        selector: (row) => (
            row.foto ? (<img width={40} height={40} src={row.foto} alt="user-img" />)
                :
                (<img width={40} height={35} src={`${url}/files/user.png`} alt="userimg-icon-alt" />)
        )

    },
    {
        name: 'Nome',
        selector: (row) => [row.nomeutilizador],
        sortable: true

    },
    {
        name: 'Email',
        selector: (row) => [row.email],
        sortable: true

    },
    {
        name: 'Ação',
        cell: (row) => <div className="d-flex">
            <a className="btn btn-table" title="Vizualizar Candidato" href={`/perfil/vizualizar/${row.id}`}><AiFillEye className='text-primary' /></a>
            <a className="btn btn-table" title="Editar Candidato" href={`/admin/dashboard/utilizador/editar/${row.id}`}><BiEdit className='text-success' /></a>
            <button className="btn btn-table" title="Apagar Candidato" onClick={() => deleteUser(row.id)}><MdDelete className='text-danger' /></button>
        </div>

    },


];



