import React from 'react'
import './footer.css'
import LOGO from '../../../assets/logo.png'

import {AiFillFacebook} from 'react-icons/ai'
import {AiFillYoutube} from 'react-icons/ai'
import {AiFillGoogleCircle} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'

function Footer(){

    return (
       
<footer className="text-center text-lg-start text-muted fixed" id="footer">
  
  <section className="d-flex justify-content-center justify-content-lg-between p-3 border-bottom">
   
    <div>
      <a href="" className="me-4 text-reset">
        <AiFillFacebook className="footer-icon"/>
      </a>
      <a href="" className="me-4 text-reset">
        <AiFillYoutube className="footer-icon" />
      </a>
      <a href="" className="me-4 text-reset">
        <AiFillGoogleCircle className="footer-icon"/>
      </a>
      <a href="" className="me-4 text-reset">
        <AiFillInstagram className="footer-icon"/>
      </a>
    </div>

  </section>


  <section  className="">
    <div  className="container text-center text-md-start mt-5">
      
      <div className="row mt-3">
       
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
      
          
            <img src={LOGO} alt="Logo da +Oportunidade" id="footer-img"/>
          
          <p className="mt-3">
            Here you can use rows and columns to organize your footer content. Lorem ipsum
            dolor sit amet, consectetur adipisicing elit.
          </p>
        </div>
      
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 footer-section">
         
          <h6 className="text-uppercase text-light fw-bold mb-4">
            Empregos
          </h6>
          <p>
            <a href="#!" className=" footer-links">Últimas Ofertas</a>
          </p>
          <p>
            <a href="#!" className=" footer-links">Ofertas por Distrito</a>
          </p>
          <p>
            <a href="#!" className=" footer-links">Oferta por Categoria</a>
          </p>
        </div>
     
        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 footer-section">
         
          <h6 className="text-uppercase text-light fw-bold mb-4">
            Empresas
          </h6>
          <p>
            <a href="/utilizador/vaga/anuncio/criar" className="footer-links">Publicar Oferta</a>
          </p>
          <p>
            <a href="/login" className="footer-links">Login Empresa</a>
          </p>
          <p>
            <a href="/registro/empresa" className="footer-links">Registar Empresa</a>
          </p>
        </div>
        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 footer-section">
     
          <h6 className="text-uppercase text-light fw-bold mb-4">
            Candidato
          </h6>
          <p>
            <a href="/utilizador/vaga/anuncio/criar" className="footer-links">Publicar Oferta</a>
          </p>
          <p><a href="/login" className="footer-links">Login Candidato</a></p>
          <p>
          <a href="/registro" className="footer-links">Registar Candidato</a>
          </p>
        </div>
       
      </div>
   
    </div>
  </section>
 

 
  <div className="text-center p-4" >
    © 2022 Copyright:
    <a className="text-reset fw-bold" href=""> +Oportunidades</a>
  </div>
  
</footer>

    );
}

export default Footer;