import React from "react";
import { Link, useParams } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { useState, useEffect } from "react";
import "./perfilcandidato.css";

import { FaFilePdf } from 'react-icons/fa'
import { HiOutlineUpload } from "react-icons/hi"

import Nav from "../../../../components/Layout/Navbar/Nav";
import Footer from "../../../../components/Layout/Footer/Footer";

import Api from "../../../../Api";
import { header } from "../../../../config/header";

import Load from '../../../../components/Layout/Load/Load'

//Componentes de Formulário
import Input from "../../../../components/Form/Input/input";
import Button from "../../../../components/Form/Button/Button";
import Textarea from "../../../../components/Form/Textarea/Textarea";

function Form2() {

  const id = useParams().id

  const [activeTitle, setActiveNav] = useState("activeTitleButton");

  const [nivelAcademico, setNivelAcademico] = useState([])
  const [areaprofissional, setAreaProfissional] = useState([])
  const [perfil, setPerfil] = useState({
    formacao: " ",
    id_tipoutilizador: "",
    id_grauacademico: "",
    curiculo: "",
    descricao: ""
  });

  const [file, setFile] = useState('')

  const [btn, setBtn] = useState({
    className: '',
    text: '',
    spin: ''
  })

  const [btnSubmit, setBtnSubmit] = useState({
    className: '',
    text: 'Guardar',
    spin: ''
  })

  const [btnRemove, setBtnRemove] = useState({
    className: "",
    text: "Remover",
    spin: ""
  })




  async function getUser() {
    await Api.get(`utilizador/registro/${id}`, header).then((response) => {
      const data = response.data;
      console.log(data);
      setPerfil(data);

    }).catch((err) => {
      console.log("Houve um erro: ", err);
    });
  }


  async function getNivelAcademico() {
    await Api.get(`grausacademicos`, header).then((response) => {
      const data = response.data;
      //console.log(data);
      setNivelAcademico(data);
    }).catch((err) => {
      console.log("Houve um erro: ", err);
    });
  }

  async function getAreaProfissional() {

    Api.get("areasprofissionais/getAll", header)
      .then(response => {
        //console.log(response.data)
        setAreaProfissional(response.data)
        console.log(response.data)
      }).catch(erro => {
        console.log("Erro: ", erro)
      })

  }

  useEffect(() => {
    getAreaProfissional();
  }, [])


  useEffect(() => {
    getUser();
    getNivelAcademico();
  }, [id])



  function handleSubmit() {
    setBtnSubmit({
      className: 'disabled',
      text: 'Aguarde',
      spin: 'spinner-border spinner-border-sm'
    })
    Api
      .post(`utilizadores/${id}`, perfil, header)
      .then((response) => {
        setPerfil(response.data)
        alert("Alteração feita com sucesso!")
        setBtnSubmit({
          className: '',
          text: 'Guardar',
          spin: ''
        })
      })
      .catch((erro) => {
        setBtnSubmit({
          className: '',
          text: 'Guardar',
          spin: ''
        })
        console.log("Houve o seguinte erro: ", erro);
      });
  }
  const submit = (e) => {
    e.preventDefault();
    console.log(perfil);
    handleSubmit(perfil);
  };



  async function RemoverCurriculum() {
    setBtnRemove({
      className: 'disabled',
      text: '.....',
      spin: 'spinner-border spinner-border-sm'
    })

    await Api
      .post(`utilizadores/${id}`, { curiculo: null }, header)
      .then((response) => {
        setBtnRemove({
          className: 'disabled',
          text: 'Remover',
          spin: ''
        })

        getUser()
        alert("Curiculum removido com sucesso!")
        console.log(response)
      })
      .catch((erro) => {
        if (erro.response) {
          setBtnRemove({
            className: '',
            text: 'Remover',
            spin: ''
          })
          alert("Houve um erro!");
          console.log("Houve o seguinte erro: ", erro.response);
        }
        else {
          //Backend fora do ar
          console.log("Erro, tente mais tarde!")
        };
      });
  }




  function handleChange(e) {
    setPerfil({ ...perfil, [e.target.name]: e.target.value });
  }

  function handleChangeSelect(e) {

    setPerfil({ ...perfil, [e.target.name]: e.target.value });
    //console.log(e.target.value)  
  }


  const handleChangeI = (e) => {
    console.log(e.target.file)
    setFile(e.target.files[0])
    setBtn({
      className: '',
      text: 'Guardar',
      spin: ''
    })
  }

  const handleApi = async () => {
    setBtn({
      className: 'disabled',
      text: '.....',
      spin: 'spinner-border spinner-border-sm mx-1',
    })

    const formData = new FormData()
    formData.append('file', file)

    await Api
      .post(`utilizadores/${id}`, formData, header)
      .then((response) => {
        getUser()
        setFile('')
        alert("Curiculum alterada com sucesso!")
        setBtnRemove({
          className: '',
          text: 'Remover',
          spin: ''
        })

        console.log(response)
      })
      .catch((erro) => {
        setFile('')
        if (erro.response) {
          alert("Houve um erro!");
          console.log("Houve o seguinte erro: ", erro.response);
        }
        else {
          //Backend fora do ar
          console.log("Erro, tente mais tarde!")
        };
      });

  }


  return (
    <>

      <Nav navClassName={"nav-home"} />

      {!perfil.id_tipoutilizador ? (<div className=" load-container d-flex justify-content-center align-items-center mt-5"><Load /></div>) :

        (
          <div className="container perfil-container col-xl-10 col-lg-10 col-md-10 mt-5" id="perfil-formDados">
            <h3 className="text-center perfil-title">Criar/Editar Perfil</h3>

            <div className="perfil-dados col-xl-10 col-lg-12 col-md-10 shadow borderRadius-boerderTop">
              <div className="cabecalhos mt-2">
                <div className="title-divisor">o---------------o-------</div>
                <Link
                  className="active title-button"
                  to={`/perfil/passo1/${id}`}
                >
                  <FaUserPlus /> Identificação
                </Link>
                <div className="title-divisor">----------o----------</div>
                <Link
                  className={`title-button ${activeTitle}`}
                  to={`/perfil/passo2/${id}`}

                >
                  <FaUserGraduate /> Habilitação
                </Link>
                <div className="title-divisor">------o--------------o</div>
              </div>
              <div className="perfil-divisao"></div>

              <form onSubmit={(value) => submit(value)}>
                <div className="perfil-dados d-flex flex-column justify-content-center align-items-center col-xl-10 col-lg-12 col-md-10 mt-5">
                  <div className="col-12 mt-4 mb-5 d-flex flex-column justify-content-center align-items-center">
                    <h5 className="mb-5 text-center">
                      Carregue o seu Curiculum:{" "}
                    </h5>
                    <div className="row">

                      <div className=" col-6 col-curri ">
                        <div className="row ">
                          <div className="col-4">
                            <label htmlFor="curriculum" title="Carregar curriclum" className="fs-2">
                              <HiOutlineUpload className="text-primary" />
                            </label>
                            <Input
                              required
                              title="Adicione o seu curiculum"
                              className="curr-input input-form-data"
                              type="file"
                              name="file"
                              id="curriculum"
                              handleOnChange={handleChangeI}
                            />
                          </div>
                          <div className="col-8">
                            {perfil.curiculo ? <span className=""><FaFilePdf className="text-danger" /> <a target="_banck" href={perfil.curiculo}>{perfil.curiculo.substring(61)}</a></span>
                              :
                              "Nenum Curriculum adiconado"

                            }
                          </div>
                        </div>

                      </div>

                      <div className=" col-6 ">
                        <div className="row ">
                          <div className="col-6">

                            {
                              file ? <button className={`btn btn-success fw-bold ${btn.className}`} type="button" onClick={handleApi}>
                                <span className={`${btn.spin}`} role="status" aria-hidden="true" />  {btn.text}

                              </button> :

                                <Button
                                  className="btn btn-success fw-bold px-3 disabled"
                                  type="button"
                                  text="Guardar"
                                />
                            }
                          </div>
                          <div className="col-6">

                            {
                              perfil.curiculo ? <button className={`btn btn-danger fw-bold ${btnRemove.className}`} type="button" onClick={RemoverCurriculum}>
                                <span className={`${btnRemove.spin}`} role="status" aria-hidden="true" />  {btnRemove.text}

                              </button> :

                                <Button
                                  className="btn btn-del-curi fw-bold px-3 disabled"
                                  type="button"
                                  text="Remover"
                                />
                            }

                          </div>
                        </div>

                      </div>

                    </div>

                  </div>
                  <div className="col-12 ">
                    <div className="row">
                      <div className="col-6 mb-4">
                        <label htmlFor="nivelAcademico" className="mb-2">
                          Nível Académico<span className="obg">*</span>
                        </label>
                        <select
                          title="Informe o seu Grau Académivo"
                          className="form-select input-form-data"
                          name="id_grauacademico"
                          id="id_grauacademico"
                          onChange={handleChangeSelect}
                          value={perfil.id_grauacademico ? perfil.id_grauacademico : ""}
                        >
                          {perfil.id_grauacademico
                            ?
                            ("")
                            :

                            <option value="" selected>
                              (Seu Nível Acadêmico)
                            </option>

                          }

                          {nivelAcademico.map((nivel) => (
                            <option value={nivel.id} key={nivel.id}>
                              {nivel.nomegrauacademico}
                            </option>
                          ))
                          }
                        </select>
                      </div>
                      <div className="col-6">
                        <label htmlFor="id_areaprofissional" className="mb-2">
                          Área Profissional<span className="obg">*</span>
                        </label>
                        <select
                          title="Informe o sua Área Profissional"
                          className="form-select input-form-data"
                          name="id_areaprofissional"
                          id="id_areaprofissional"
                          onChange={handleChangeSelect}
                          value={perfil.id_areaprofissional ? perfil.id_areaprofissional : ""}
                        >
                          {perfil.id_areaprofissional
                            ?
                            ("")
                            :

                            <option value="" selected>
                              (Área Profissional)
                            </option>

                          }

                          {areaprofissional.map((area) => (
                            <option value={area.id} key={area.id}>
                              {area.nomeareaprofissional}
                            </option>
                          ))
                          }
                        </select>
                      </div>
                      <div className="col-12">

                        <label htmlFor="formacao" className="mb-2">Formação</label>
                        <Input
                          title="Informe a sua formação"
                          type="text"
                          className="form-control form-control-user input-form-data"
                          name="formacao"
                          placeholder="Sua Formação"
                          value={perfil.formacao ? perfil.formacao : ""}
                          handleOnChange={handleChange}
                        />

                      </div>
                    </div>
                  </div>
                </div>

                <div className="perfil-dados d-flex flex-column justify-content-center align-items-center col-xl-10 col-lg-12 col-md-10 mt-5">
                  <h5 className="text-center">
                    Informação Adicional <span className="obg">*</span>
                  </h5>
                  <div className="col-12 mt-4">
                    <div className="mb-4">
                      <Textarea
                        className="form-control"
                        id="descricao"
                        name="descricao"
                        cols="30"
                        rows="10"
                        title="Exemplo: Meu nome é João, sou recém formado
                     em Administração Pública e estou em busca do meu primeiro emprego..."
                        value={perfil.descricao ? perfil.descricao : ""}
                        handleOnChange={handleChange}
                      />
                      <label required htmlFor="descricao">
                        Apresente uma breve descrição sobre a sua pessoa
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  className={`btn mt-5 mb-5 perfil-button w-60 ${btnSubmit.className}`}
                  type="submit"
                >
                  <span
                    className={`${btnSubmit.spin}`}
                    role="status"
                    aria-hidden="true" /> {btnSubmit.text}
                </button>
              </form>
            </div>
          </div>

        )}
      <Footer />
    </>


  );
}

export default Form2;
