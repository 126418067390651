import React from "react";
import "./perfilempresa.css";

import Form1Empresa from "./formDados1Empresa";

import Nav from "../../../../components/Layout/Navbar/Nav";
import Footer from "../../../../components/Layout/Footer/Footer";

function PerfilEmpresa() {
  return (
    <>
      <Nav navClassName={"nav-home"}/>
      <Form1Empresa />
      <Footer />
    </>
  );
}

export default PerfilEmpresa;
