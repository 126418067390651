import React,{useEffect, useState} from 'react';

import './rolarTopo.css';
import {AiOutlineArrowUp} from 'react-icons/ai';

function RolarTopo(){

    const [classe, setClasse] = useState('');

    useEffect(() => {
       window.addEventListener("scroll",()=>{
           if(window.scrollY > 100){
               setClasse("card-scroll")
           }else{
               setClasse("hidden")
           }
       })
      }, []);

      const scrollUp =()=>{
          window.scrollTo({
              top:0,
              behavior: "smooth"
          })
      }

    return(

        <div className={classe} onClick={scrollUp}>

            
            <AiOutlineArrowUp className='scroll-icon'/>
        </div>
    );
}


export default RolarTopo