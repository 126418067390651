import React, { useState, useEffect } from 'react'

import './dashvagas.css'

import Dashsidebar from '../../../Dashsidebar/Dashsidebar';

import Api from '../../../../Api'
import { header } from '../../../../config/header';

import DataTable, { createTheme } from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { columns} from "./dados";
import Load from '../../../../components/Layout/Load/Load'
import DashNav from '../../../DashNav/DashNav'
import DashFooter from '../../../DashFooter/DashFooter'


function Vagas() {

    

    const [loadEstado, setLoadEstado] = useState(true)
    const [data, setData] = useState([])
    
    async function getDados(){

           await Api.get("vagas", header).then((response)=>{
               console.log(response);
               setData(response.data);
                setLoadEstado(false)
              
           }).catch((erro) =>{
            console.log("Houve um erro: ", erro)
            setLoadEstado(false)
           })
       
       }

   useEffect(() => {

       getDados()
     
     },[])
    

    createTheme('solarized', {
        text: {
            primary: 'black',
            secondary: 'white',
        },
        background: {
            default: '',
        },
        context: {
            background: 'red',
            text: '#FFFFFF',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'red',
        },


    }, 'dark');


    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

    /*const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };*/



    const tableData = {
        columns,
        data
    };
    return (



        <div className="d-flex dash-altura" id="wrapper">
            <DashNav/>

            <Dashsidebar />

            <div className='' id="page-content-wrapper">
                <h2 align="center" className='mb-2'>Lista das Vagas de Emprego</h2>

                {loadEstado === true ? <div className="w-100 load-height d-flex justify-content-center align-items-center"><Load/></div> : (
                    <DataTableExtensions  className='mt-5' {...tableData}>
                    <DataTable
                        columns={columns}
                        data={data}
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        //selectableRows
                        expandableRowsComponent={ExpandedComponent}
                        //customStyles={customStyles}
                        //theme="solarized"
                        striped
                        //dense
                        pagination 
                        entriesOptions={[5, 20, 25]}
                        highlightOnHover
                        fixedHeader
                        fixedHeaderScrollHeight="265px"
                        paginationRowsPerPageOptions = {[5, 20, 25]}
                        
                    />
                </DataTableExtensions>
                )}
                
                <div className = 'vagas-footer-margin'>
                        <DashFooter />
                    </div>
        
            </div>

        </div>

    );
}

export default Vagas